import Rmbx from "./util"
import { getAuthToken, getAuthType } from "./util"

/**
 * Collects the auth token, auth type, and provider (if using SSO) information from RMBX.store
 * @returns List of the auth key values - ex. ["Authorization": "abcd1234", "Authorization-Type", "Token"]
 */
export const getAuthHeaders = (): Array<string> => {
    if (Rmbx.store.get("guest_email")) {
        const formShareId = Rmbx.store.get("guest_form_id")
        // use the signed guest key to authenticate to the websocket so we can download stores properly
        const guestSignedKey = encodeURIComponent(Rmbx.store.get("guest_signed_key"))
        return ["Authorization", guestSignedKey, "Authorization-Type", "Guest", "Form-Share-Id", formShareId]
    } else {
        const socketAuthToken = getAuthToken() as string
        const authType = getAuthType()
        const socketAuthHeaders = ["Authorization", socketAuthToken, "Authorization-Type", authType]
        return socketAuthHeaders
    }
}

/**
 * Given the resource path for a socket, build the remainder of the websocket url.
 * Will handle setting proper ws /wss protocol
 * Expected format is host/ws/socketPath -> ex. wss://platform.rhumbix.com/ws/company_form_stores
 * @param socketPath - the url for your consumer
 * @returns string websocket url
 */
export const getSocketUrl = (socketPath: string): string => {
    // like http(s) - local uses ws, while live envs use wss to encrypt the connection
    const socketPrefix = `ws${
        window.location.host === "localhost:8000" || process.env.BUILD_ENV === "test" ? "" : "s"
    }://`
    return `${socketPrefix}${window.location.host}/ws/${socketPath}/`
}
