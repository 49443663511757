import React, { Component } from "react"
import { connect } from "react-redux"
/** Actions */
import { setFilterValue } from "../actions"
/** Selectors */
import {
    activeSavedFilterSelector,
    pendingFiltersSelector,
    savedFilterSetInvalidSelectionsSelector,
} from "../selectors"
/** Components */
import SelectorWrapper from "../../SelectorWrapper"
import { getValueFormatter } from "../../common/ag-grid-value-formatters"

/** Types */
import { AppDispatch, iCurrentUser } from "../../common/types"
import {
    tFilterState,
    tFilterKey,
    tFilterResourceName,
    tFilterKeyToQueryParam,
    tValueFormatter,
    tSavedFilter,
} from "../types"
import AsyncSelect from "react-select-v1/lib/Async"
/** Styles */
import "../FilterController/FilterController.less"
import "./SelectorFilter.less"
/** Utils */
import rmbx from "../../util"
import { getFlagEnabled } from "../../getFlagValue"
import { getCookie } from "../../common/ts-utils"
import { referenceableDataSelector } from "../../selectors"
import { tCachedResourceName, tEntitySelection } from "../../cached-data/types"
import { tResourceObject } from "../../dashboard-data/types"
import { isEqual } from "lodash"

type tProps = {
    activeSavedFilter?: tSavedFilter
    className?: string
    clearable?: boolean
    clearedBy?: tFilterKey[]
    clearText: string
    currentUser: iCurrentUser
    dispatch: AppDispatch
    filters: tFilterState
    getSelectionFromFilterValue?: { (arg: any): any }
    innerRef: React.RefObject<typeof AsyncSelect>
    invalidSelections: any
    isDesignSystem?: boolean
    isSelectorV3?: boolean
    label: string
    multiselect?: boolean
    options?: Array<any>
    placeholder: string | undefined
    prefixFormatter?: tValueFormatter
    primaryKey: tFilterKey
    primarySubtitleFormatter?: tValueFormatter
    referenceableData: tEntitySelection
    relatedFilters: tFilterKeyToQueryParam
    resourceName: tFilterResourceName
    secondarySubtitleFormatter?: tValueFormatter
    shouldClearCache?: boolean
    titleFormatter?: tValueFormatter
    updateClearCache: (shouldClearCache: boolean) => void
    valueFormatter?: tValueFormatter
    valueKey?: string
}

type tState = {
    filterValue: any
    isLoading: boolean
}

// Wraps the Selector (with one other wrapper in between) for use by filters.
// Options are either passed in by its parent class if it's used inside ReferenceableSelectorFilter,
// or passd in by the filter def creator as static options if it's used in an enum filter.
class SelectorFilter extends Component<tProps, tState> {
    constructor(props: tProps) {
        super(props)
        this.state = {
            filterValue: props.filters[props.primaryKey],
            isLoading: false,
        }
    }

    componentDidUpdate(prevProps: tProps) {
        // If the filter value is updated outside the component (e.g. by a
        // chained filter), we need to update the component state.
        const { filters } = this.props
        const filterVal = filters[this.props.primaryKey]
        const prevFilterVal = prevProps.filters[prevProps.primaryKey]

        if (filterVal !== prevFilterVal) {
            this.setState({
                filterValue: filterVal,
            })
        }
        if (getFlagEnabled("WA-7989-employee-cohorts")) {
            if (
                this.props.clearedBy &&
                this.props.clearedBy.some(filterKey => {
                    // @ts-ignore
                    if (!filters[filterKey] || (Array.isArray(filters[filterKey]) && !filters[filterKey].length))
                        return false
                    return !isEqual(prevProps.filters[filterKey], filters[filterKey])
                })
            )
                this.setState({ filterValue: null }, this.dispatchFilterChange)
        }
    }

    getValueKey = () => this.props.valueKey ?? "name"

    getFilterValueFromSelectionValue = (selectionValue: any) => {
        let filterValue = selectionValue ? selectionValue[this.getValueKey()] : undefined
        if (this.props.multiselect) {
            filterValue = selectionValue.map((item: any) => {
                // We store values internally in the Select component as {name: "Value"}. This
                // ignores the valueKey parameter and what we really want here is the whole thing so
                // we can get the proper filter value...
                const option: any = this.props.options?.find(op => op.data?.name === item.name)
                if (option) return option.data[this.getValueKey()]
                return item[this.getValueKey()] ?? item["name"]
            })
        }
        return filterValue
    }

    persistSelectedOptions = (selectionValue: any) => {
        // If the filter value was set to something (as opposed to cleared),
        // add it to the cache of option values we keep in sessionStorage.
        //
        // This gets consumed by the AsyncPaginate component to set its value
        // after a hard refresh. AsyncPaginate will only render the options
        // that correspond to the current filter selection.
        const selectionValueArray = Array.isArray(selectionValue) ? selectionValue : [selectionValue]
        if (selectionValue && selectionValueArray.length) {
            const optionCache = window.sessionStorage.getItem(this.props.primaryKey) || "{}"
            const newOptions = selectionValueArray.reduce((acc, item: { id: number; name: string }) => {
                if (item.id === undefined && item.name && typeof item.name === "string") {
                    acc[item.name] = item
                } else {
                    acc[item.id] = item
                }
                return acc
            }, {})
            const newOptionCache = { ...JSON.parse(optionCache), ...newOptions }
            window.sessionStorage.setItem(this.props.primaryKey, JSON.stringify(newOptionCache))
        }
    }

    onChange = (selectionValue: any) => {
        const filterValue = this.getFilterValueFromSelectionValue(selectionValue)
        this.setState({ filterValue })
        if (!this.props.multiselect || !filterValue.length)
            this.props.dispatch(setFilterValue(this.props.primaryKey, filterValue))
        this.persistSelectedOptions(selectionValue)
    }

    dispatchFilterChange = () => {
        this.props.dispatch(setFilterValue(this.props.primaryKey, this.state.filterValue))
    }

    getSelectionFromFilterValue = (filterValue: any) => {
        if (this.props.getSelectionFromFilterValue) {
            return this.props.getSelectionFromFilterValue(filterValue)
        }
        return filterValue
    }

    /** Get the initial selected options for React Select V3. */
    getSelectedOptions = () => {
        // filterValue contains the filter's current selection
        const { filterValue } = this.state
        if (!filterValue) return

        // If options were passed to the selector - for example, if this is an enumFilter -
        // match the filterValue to those
        if (this.props.options && this.props.options.length > 0) {
            const selectedOptions: any = []
            if (Array.isArray(filterValue)) {
                this.props.options.forEach(option => {
                    // TODO: The option may be formatted correctly (with a data property) at this point -
                    // but it may not. SelectorWrapper formats the options correctly, but this function
                    // is called before that happens, and this has to match the original format to the
                    // filterValue. For example, if an enum filter receives a filter value from session storage,
                    // it will get matched up here before the options have a chance to be formatted.
                    // This has to be straightened out one way or the other (maybe the enum filters should
                    // use the data property off the bat), but for now, this additional check
                    // solves the issue
                    // NOTE: The check for option?.label === fv will catch enum filters that are populated
                    // from session storage - for example, Project Status. The option?.value === fv check
                    // will catch enum filters that use a defaultGetter, which sets the value instead of the
                    // label - the only one of those is productionCostCodesFilterDef.
                    if (
                        filterValue.find(
                            fv => option?.data?.name === fv || option?.label === fv || option?.value === fv
                        )
                    ) {
                        selectedOptions.push(option)
                    }
                })
            } else {
                selectedOptions.push(
                    this.props.options.find(
                        // TODO: See comment above - this shouldn't have to support both formats of option
                        // here - it would be better if it was cleaned up before we got here
                        option =>
                            option?.data?.name === filterValue ||
                            option?.label === filterValue ||
                            option?.value === filterValue
                    )
                )
            }
            // Format the options to have the name property that the selector expects
            return selectedOptions.map((option: { [key: string]: any }) => {
                return { name: option?.label ?? "" }
            })
        }

        const getSelectedOptions = () => {
            const { isLoading } = this.state
            const { primaryKey, referenceableData } = this.props
            const resourceName = this.props.resourceName as tCachedResourceName
            // If we have a referenceable filter, we use any loaded referenceables as backups
            // in case we don't have session storage (like if we created a new tab).
            if (getFlagEnabled("WA-7926-rr-filter-save")) {
                const cookieFilters = getCookie(`filters_${this.props.currentUser.id}`)
                const cookieFilterDict = cookieFilters && JSON.parse(cookieFilters)
                const selectorFilter = cookieFilterDict?.[primaryKey]
                let backupValue: tResourceObject[] = []
                let valueCount = 0
                if (selectorFilter && referenceableData[resourceName]) {
                    if (Array.isArray(selectorFilter)) {
                        backupValue = selectorFilter
                            .filter((el: number) => referenceableData[resourceName][el])
                            .map((el: number) => referenceableData[resourceName][el])
                        valueCount = backupValue.length
                    } else {
                        backupValue = [referenceableData[resourceName][selectorFilter]]
                        valueCount = 1
                    }
                }

                // The FilterController component handles loading the needed referenceable data,
                // but we might have to wait a minute for that so we try and maintain a loading state
                if (resourceName) {
                    if (selectorFilter && backupValue.length !== valueCount && !isLoading) {
                        this.setState({ isLoading: true })
                    } else if (selectorFilter && backupValue.length === valueCount && isLoading) {
                        this.setState({ isLoading: false })
                    }
                }
                const primaryValue = window.sessionStorage.getItem(primaryKey)
                return primaryValue ? Object.values(JSON.parse(primaryValue)) : backupValue
            } else {
                return Object.values(JSON.parse(window.sessionStorage.getItem(primaryKey) || "{}"))
            }
        }

        // Otherwise, fetch the selected options from the local storage ...
        const selectedOptions = getSelectedOptions()

        // ... and if applicable, add the contents of the saved filter set
        const selectedOptionsFromSavedFilter = Object.values(
            this.props.activeSavedFilter?.filter_selections?.[this.props.primaryKey] ?? {}
        )

        // Combine the option lists, weeding out duplicates
        selectedOptionsFromSavedFilter.forEach((savedOption: any) => {
            if (
                !selectedOptions.find((sessionOption: any) => {
                    return (
                        (sessionOption["id"] && sessionOption["id"] === savedOption["id"]) ||
                        (sessionOption["name"] && sessionOption["name"] === savedOption["name"])
                    )
                })
            ) {
                selectedOptions.push(savedOption)
            }
        })

        if (Array.isArray(filterValue)) {
            const filterSet = new Set(filterValue)
            const firstItem = filterValue[0]
            return selectedOptions.filter((option: any) => {
                const { id, name } = option
                // Check whether the filter in the local storage stores "id" or "name."
                if (id && rmbx.util.isNumber(firstItem)) return filterSet.has(id)
                if (name && typeof firstItem === "string") return filterSet.has(name)
            })
        }

        return selectedOptions.filter((option: any) => {
            const result = option?.id === filterValue || option?.name === filterValue
            return result
        })
    }

    render() {
        const isInactive =
            !this.state.filterValue || (Array.isArray(this.state.filterValue) && !this.state.filterValue.length)
        const baseClassName = this.props.className ? `${this.props.className} selector-filter` : "selector-filter"
        const className = isInactive ? `${baseClassName} selector-filter-inactive` : baseClassName
        const isMulti = this.props.multiselect
        const isClearable = isMulti || this.props.clearable

        // TODO: Should this be in a common location?
        // Selector values that are not tied to a resource may not have a titleFormatter or valueFormatter
        // specified for them. In that case, use a simple default formatter (otherwise, nothing will render)
        // Note that this is protected by a flag because it does not work in the v1 selector
        const defaultValueFormatter = getValueFormatter(["/name"])
        const selectorProps = {
            isClearable,
            clearText: this.props.clearText,
            filters: this.props.relatedFilters,
            onChange: this.onChange,
            prefixFormatter: this.props.prefixFormatter,
            primarySubtitleFormatter: this.props.primarySubtitleFormatter,
            resourceName: this.props.resourceName,
            secondarySubtitleFormatter: this.props.secondarySubtitleFormatter,
            titleFormatter: this.props.titleFormatter ?? defaultValueFormatter,
            valueFormatter: this.props.valueFormatter ?? defaultValueFormatter,
        }

        return (
            <div className={className} id={`filter-${this.props.primaryKey}`}>
                <SelectorWrapper
                    {...selectorProps}
                    innerRef={this.props.innerRef}
                    isLoading={this.state.isLoading}
                    isMulti={isMulti}
                    onMenuCloseCallBack={this.dispatchFilterChange}
                    shouldClearCache={this.props.shouldClearCache}
                    updateClearCache={this.props.updateClearCache}
                    optionList={this.props.options}
                    value={this.getSelectedOptions()}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.current_user,
    activeSavedFilter: activeSavedFilterSelector(state),
    filters: pendingFiltersSelector(state),
    invalidSelections: savedFilterSetInvalidSelectionsSelector(state),
    referenceableData: referenceableDataSelector(state),
})

export default connect(mapStateToProps)(SelectorFilter)
