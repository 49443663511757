import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
/** Components */
import { Selector } from "@rhumbix/rmbx_design_system_web"
// Gary TODO: Remove @types/react-select-v3 after upgrading (once we only use the design system selector)
import Select, { components } from "react-select-v3"
import SelectorOption from "../Selector/Option"
/** Utils */
import { autocompleteSearch, getDebouncedSearch } from "../api/searchV3"
/** Resources */
import { referenceablesToValueFormatters } from "../common/referenceable-value-formatters"
/** Types */
import { tSelectCacheProps, tSelectorOption, tSelectorProps } from "./types"
/** Styles */
import { colorStyles, StyledCheckbox, StyledClearOptions, StyledClearX } from "./styles"
import { getFlagEnabled } from "../getFlagValue"

const SelectorV3: React.FC<tSelectorProps> = props => {
    const {
        clearable,
        isCreatable,
        isMulti,
        isDisabled,
        optionList, // If "optionList" is provided, we will not fetch from the backend.
        resourceName,
        shouldClearCache,
        updateClearCache,
        valueFormatter,
        placeholder,
    } = props
    const value = props.value ?? (isMulti ? [] : {})
    const clearText = props.clearable ? props.clearText ?? "Show All" : ""
    const resourceFormatters = referenceablesToValueFormatters[resourceName]
    let initialSelectedOption
    if (isMulti) {
        initialSelectedOption = value.map((item: Record<string, any>) => {
            const keyValue = resourceFormatters?.titleFormatter?.({ value: item })
            /**
             * If "options" attribute exists, React Select will treat it as the option's categories.
             * Our "projects" data has the "options" attribute. This will cause an error.
             * So we are storing the original data under "data" and access it after selecting an option.
             * Also, attempt to use the item's id if it's a referenceable in order to make the values unique
             * and avoid issues where two options have the same value.
             */
            return {
                data: item,
                value: item.id || keyValue,
                label: keyValue,
            }
        })
    } else {
        const keyValue =
            resourceFormatters?.titleFormatter?.({ value }) || (valueFormatter && valueFormatter({ value }))

        if (keyValue) {
            initialSelectedOption = { data: value, value: value, label: keyValue }
        } else {
            // initialSelectedOption needs to be null if there is no initial value
            // in order for the placeholder to be displayed
            initialSelectedOption = null
        }
    }
    // List options.
    const [options, setOptions] = useState<Array<tSelectorOption | Record<string, any>>>([])
    // Select loading status.
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // Search input value.
    const [inputValue, setInputValue] = useState<string>("")
    const [debouncedInputValue, setDebouncedInputValue] = useState<string>("")
    // Cache to store searched results.
    const [cache, setCache] = useState<Record<string, tSelectCacheProps>>({})
    // Whether is at the bottom of the option list, for pagination purpose.
    const [isListBottom, setIsListBottom] = useState(false)
    // Whether the dropdown menu is open.
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const inputRef = useRef("")
    inputRef.current = inputValue

    // Create a variable that can be referred to everywhere we
    // used to refer to inputValue that we would be referring to
    // debouncedInputValue with flag on instead.
    const searchTerm = getFlagEnabled("WA-7965-selectors-should-debounce") ? debouncedInputValue : inputValue

    /** Get option list based on the inputValue. Use the cached result if exists. */
    useEffect(() => {
        const cachedResult = cache[searchTerm]
        if (cachedResult) setOptions(cachedResult.options)
        else if (isMenuOpen) getOptions()
    }, [searchTerm])

    /** Get more options if scrolling to the bottom of the option list. */
    useEffect(() => {
        if (isListBottom) getOptions()
        else setIsListBottom(false)
    }, [isListBottom])

    /** If any other filters have changed in the Side Rail, reset cache. */
    useEffect(() => {
        if (shouldClearCache && updateClearCache) {
            setCache({})
            updateClearCache(false)
        }
    }, [shouldClearCache])

    useEffect(() => {
        if (getFlagEnabled("WA-7965-selectors-should-debounce")) {
            // We don't need to trigger off an update to the debounced
            // input value if it's already the same as the regular one
            if (inputValue === debouncedInputValue) {
                return
            }
            // Set the debounced value after 500ms, which
            // will trigger a re-render and result in
            // an API call to fetch options
            const timerId = setTimeout(() => {
                setDebouncedInputValue(inputValue)
            }, 500)

            // Return a clearTimeout to cancel the timer if
            // this component is re-rendered, skipping setting
            // the debounced value
            return () => clearTimeout(timerId)
        }
        // This uses inputValue instead of searchTerm
        // because we need to queue up debouncedInputValue changes
        // every time inputValue changes
    }, [inputValue])

    /**
     * This function gets passed to autocompleteSearch, once the API returns, it will first check
     * if the inputValue that it was working with is current, if so, call setOptions
     * @param loadedOptions the options returned by the API
     * @param oldInputValue the inputValue that autocompleteSearch had at the time of it's invocation
     */
    const setOptionsCheck = (
        loadedOptions: Array<tSelectorOption | Record<string, any>>,
        oldInputValue: string
    ) => {
        if (oldInputValue === inputRef.current) setOptions(loadedOptions)
    }

    /** Fetch list options. */
    const getOptions = () => {
        const cachedResult = cache[searchTerm]
        // Check if there are more options to be fetched.
        if (cachedResult && !cachedResult.hasMore) return
        setIsLoading(true)
        const currentTotal = cachedResult?.options?.length || 0

        const searchFunction = getFlagEnabled("WA-7965-selectors-should-debounce")
            ? autocompleteSearch
            : getDebouncedSearch({ leading: getFlagEnabled("WA-7424-no-leading-debounce") })

        searchFunction(
            props.dispatch,
            {
                setCache,
                setIsLoading,
                setIsListBottom,
                setOptions,
                setOptionsCheck,
            },
            {
                cache,
                extraFilters: props.filters,
                idsToExclude: props.idsToExclude,
                inputValue: searchTerm,
                resourceName: props.resourceName,
                total: currentTotal,
                disableInactive: props.disableInactive,
            }
        )
    }

    /** Callback invoked when dropdown menu opens. */
    const onMenuOpen = () => {
        setIsMenuOpen(true)
        getOptions()
    }

    /** Callback invoked when dropdown menu closes. */
    const onMenuClose = () => {
        const { onMenuCloseCallBack } = props
        setIsMenuOpen(false)
        if (onMenuCloseCallBack) onMenuCloseCallBack()
    }

    /**
     * Update search input state.
     * @param {string} search New input field.
     */
    const onInputChange = (search: any, action: any) => {
        if (
            // If we set the input value to any incoming search, the user's search string
            // will clear every time they make another selection, whether they want it to or not.
            // It should only clear when the menu closes
            action.action === "input-change" ||
            action.action === "menu-close"
        )
            setInputValue(search)
    }

    /**
     * Fetch more list options after scrolling to the bottom of the current list.
     * Notes:
     * - onMenuScrollToBottom doesn't have access to the current states.
     * - Use "isListBottom" to trigger "getOptions".
     */
    const onMenuScrollToBottom = () => setIsListBottom(true)

    /**
     * Callback invoked after adding/removing a selected option.
     * Pass in the original data from the "data" attribute.
     * @param {Object[]|Object} selectedOption Selected option.
     */
    const onChange = (selectedOption: any) =>
        props.onChange(
            isMulti ? selectedOption.map((option: Record<string, any>) => option.data) : selectedOption?.data
        )

    /**
     * Format list options.
     * @param {Object} config Option configuration and data.
     * @prop {boolean} config.isSelected Whether the option is selected.
     * @return {ReactElement} Formatted list option.
     */
    const componentsOption = (config: any) => {
        const { isSelected } = config
        const icon = isMulti ? (
            <StyledCheckbox type="checkbox" checked={isSelected} onChange={() => null} />
        ) : (
            isSelected && <img src={require("check-mark.svg")} alt="" width="15" height="15" />
        )
        return (
            <components.Option {...config} className="select-option">
                {icon}
                <SelectorOption
                    context={props.context}
                    option={config.data.data}
                    prefixFormatter={props.prefixFormatter}
                    primarySubtitleFormatter={props.primarySubtitleFormatter}
                    removeSelected={false}
                    secondarySubtitleFormatter={props.secondarySubtitleFormatter}
                    titleFormatter={props.titleFormatter}
                    valueFormatter={props.valueFormatter}
                />
            </components.Option>
        )
    }

    /** Clear selected options */
    const clearComponent = (
        <>
            {clearText}
            <StyledClearX className="btn-dismiss" id="clear-x" />
        </>
    )

    if (props.isDesignSystem) {
        const selectorOptionConfig = {
            context: props.context,
            prefixFormatter: props.prefixFormatter,
            primarySubtitleFormatter: props.primarySubtitleFormatter,
            secondarySubtitleFormatter: props.secondarySubtitleFormatter,
            titleFormatter: props.titleFormatter,
            valueFormatter: props.valueFormatter,
        }

        // Provide a lit of options.
        if (optionList) {
            return (
                <Selector
                    isClearable={clearable}
                    isCreatable={isCreatable}
                    initialSelectedOption={initialSelectedOption}
                    innerRef={props.innerRef}
                    isMulti={isMulti}
                    onChange={onChange}
                    onCreateOption={props.onCreateOption}
                    openMenuOnFocus={true}
                    options={optionList as tSelectorOption[]}
                    SelectorOption={SelectorOption}
                    selectorOptionConfig={selectorOptionConfig}
                    placeholder={placeholder || "Showing All..."}
                />
            )
        }
        return (
            <Selector
                isClearable={clearable}
                isCreatable={isCreatable}
                initialSelectedOption={initialSelectedOption}
                inputValue={inputValue}
                isLoading={isLoading || inputValue != searchTerm}
                isMulti={isMulti}
                onChange={onChange}
                onCreateOption={props.onCreateOption}
                onInputChange={onInputChange}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onMenuScrollToBottom={onMenuScrollToBottom}
                options={options as tSelectorOption[]}
                innerRef={props.innerRef}
                SelectorOption={SelectorOption}
                selectorOptionConfig={selectorOptionConfig}
                placeholder={placeholder || "Showing All..."}
            />
        )
    }

    const clearIndicator = (config: any) => {
        const {
            innerProps: { ref, ...restInnerProps },
        } = config
        return (
            <StyledClearOptions {...restInnerProps} ref={ref} className="clear-text-icon-wrapper">
                {clearComponent}
            </StyledClearOptions>
        )
    }

    const valueRenderer = (config: any) => {
        const { valueFormatter } = props
        const value = valueFormatter
            ? valueFormatter({ value: config.data.data, context: props.context })
            : config.children
        return (
            <components.MultiValue {...config}>
                <span className="Select-value-label">{value}</span>
            </components.MultiValue>
        )
    }

    return (
        <Select
            className="Selector"
            closeMenuOnSelect={!isMulti}
            components={{
                ClearIndicator: clearIndicator,
                MultiValue: valueRenderer,
                Option: componentsOption,
            }}
            filterOption={option => !!option}
            getOptionValue={props.getOptionValue}
            hideSelectedOptions={false}
            inputValue={inputValue}
            isClearable={clearable}
            isDisabled={isDisabled}
            isLoading={isLoading || inputValue != searchTerm}
            isMulti={isMulti}
            onChange={onChange}
            onInputChange={onInputChange}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onMenuScrollToBottom={onMenuScrollToBottom}
            openMenuOnFocus={props.openMenuOnFocus === undefined ? true : props.openMenuOnFocus}
            options={options as tSelectorOption[]}
            ref={props.innerRef}
            styles={colorStyles}
            value={initialSelectedOption}
        />
    )
}

export default connect()(SelectorV3)
