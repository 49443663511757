import {
    booleanColDefCreator,
    currencyColDefCreator,
    checkboxColDefCreator,
    equipmentColDefCreator,
} from "./standard-col-def-creators"
import { companyGroupsFilterDef, singleProjectFilterDef } from "../../../filters/filter-defs"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"

export const getProjectEquipmentSettings = (featureFlags, currentProject, _, __, rowData) => {
    // Named this 'showPricing' instead of 'hidePricing' to make this logical condition more readable
    const showPricing = featureFlags.pricing && currentProject && currentProject.options.equipment_pricing
    const settings = {
        tableName: "Equipment",
        navId: "projectEquipment",
        resources: ["projectEquipment"],
        relatedNames: {
            projectEquipment: {
                equipment: ["/equipment"],
            },
        },
        filters: [companyGroupsFilterDef, singleProjectFilterDef],
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            checkboxColDefCreator(),
            {
                ...equipmentColDefCreator({
                    editable: true,
                    idsToExclude:
                        rowData && rowData.sourceData && rowData.sourceData.projectEquipment
                            ? rowData.sourceData.projectEquipment.map(r => r.equipment)
                            : [],
                }),
                excludeFromExport: true,
                invertRelatedFilters: ["projectId"],
            },
            {
                headerName: "Equipment ID*",
                field: "/equipment_id",
                referenceableMap: {
                    referenceableKey: "equipment",
                    rowKey: "equipment",
                },
                minWidth: 160,
            },
            {
                headerName: "Caltrans ID",
                field: "/caltrans_id",
                referenceableMap: {
                    referenceableKey: "equipment",
                    rowKey: "equipment",
                },
                minWidth: 160,
            },
            {
                headerName: "Type*",
                field: "/category",
                referenceableMap: {
                    referenceableKey: "equipment",
                    rowKey: "equipment",
                },
                minWidth: 160,
            },
            {
                headerName: "Description*",
                field: "/description",
                referenceableMap: {
                    referenceableKey: "equipment",
                    rowKey: "equipment",
                },
                minWidth: 160,
            },
            ...(showPricing
                ? [
                      currencyColDefCreator({
                          headerName: "Running Time",
                          field: "/running_time",
                          editable: true,
                          required: true,
                      }),
                      currencyColDefCreator({
                          headerName: "Idle Time",
                          field: "/idle_time",
                          editable: true,
                          required: true,
                      }),
                      currencyColDefCreator({
                          headerName: "Overtime",
                          field: "/over_time",
                          editable: true,
                          required: true,
                      }),
                  ]
                : []),
            {
                headerName: "Rented/Owned",
                field: "/status",
                referenceableMap: {
                    referenceableKey: "equipment",
                    rowKey: "equipment",
                },
                minWidth: 160,
                headerTooltip: "Is the item (O)wned or (R)ented?",
            },
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    referenceableMap: {
                        referenceableKey: "equipment",
                        rowKey: "equipment",
                    },
                    minWidth: 100,
                    editable: false,
                }),
                valueFormatter: isActiveValueFormatter,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Equipment",
            subcontent:
                `Equipment includes any piece of rental or owned equipment assigned to this project.
            Add equipment to this project by uploading a .CSV file or manually typing in the equipment ` +
                "information.",
            hiddenColumnDefaults: {
                project: "projectId",
            },
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedCell",
                    },
                ],
                row: [
                    {
                        label: "Remove",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/projects/equipment/upload/",
                        },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }

    return settings
}
