import React from "react"
import {
    addWeeks,
    format,
    differenceInHours,
    differenceInMinutes,
    parseISO,
    set,
    isValid,
    max,
    min,
    addDays,
} from "date-fns"
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz"

import {
    DATE_ONLY_FORMAT,
    DATETIME_FORMAT,
    DATETIME_FORMAT_24H,
    STORED_DATE_ONLY_FORMAT,
    TIME_FORMAT,
    TIME_FORMAT_24H,
} from "./constants"

import {
    IconAttachment,
    IconCalendarWeek,
    IconCostCode,
    IconCraftWorker,
    IconDelay,
    IconDocumentGeneric,
    IconDocumentImage,
    IconDocumentPDF,
    IconDocumentText,
    IconDocumentVideo,
    IconEquipment,
    IconMaterials,
    IconMeasurement,
    IconMisc,
    IconRadioButtonSelected,
    IconRadioButtonUnselected,
    IconSafety,
    IconSignature,
    IconTime,
    IconTrash,
} from "@rhumbix/rmbx_design_system_web"

type tDateTimeFormatResponse = {
    dateFormat: string
    timeFormat: string
}

type tDatetimeAdjustments = {
    hours?: number | undefined
    minutes?: number | undefined
    seconds?: number | undefined
    milliseconds?: number | undefined
}

// pieces needed to build locale so we can set the start of the week
import formatDistance from "date-fns/locale/en-US/_lib/formatDistance"
import formatRelative from "date-fns/locale/en-US/_lib/formatRelative/index.js"
import localize from "date-fns/locale/en-US/_lib/localize/index.js"
import match from "date-fns/locale/en-US/_lib/match/index.js"
import formatLong from "date-fns/locale/en-GB/_lib/formatLong/index.js"
import { iMutableFilterState, tStartOfWeekIndex } from "../filters/types"
import { iCurrentUser, SignaturePadType, tJSONValue, tTransformOption } from "./types"
import { LDClient } from "launchdarkly-js-client-sdk"
import { ReactElement } from "react"
import { iEmployeeWorkShift } from "../cached-data/types"
import { tResourceObject } from "../dashboard-data/types"
import { tContext } from "../components/custom-dashboards/types"
import { getFlagEnabled } from "../getFlagValue"
import { tFormAttachment } from "../new-custom-forms/components/types"
import { isNumber } from "./validators"

/**
 * Compares two unordered arrays for equality. [1,2,3] and [3,2,1] are equivalent in this case.
 *
 * If the two arrays are not equal, return false, otherwise return true.
 */
export const compareUnorderedNumberArrays = (
    array1: Array<number | string>,
    array2: Array<number | string>
): boolean => {
    if (array1.length !== array2.length) {
        return false
    }

    if (array1.length === 1 && array2.length === 1) {
        return !!(array1[0] === array2[0])
    } else {
        const sortedArray1: Array<number | string> = array1.slice(0).sort()
        const sortedArray2: Array<number | string> = array2.slice(0).sort()

        const resultArray = sortedArray1.filter((item, index) => {
            return item !== sortedArray2[index]
        })

        return !!(resultArray.length === 0)
    }
}

/**
 * Given 2 dates, return the duration as a string HH:MM format
 */
export const formatDurationAsHoursAndMinutes = (endDate: Date, startDate: Date): string => {
    const hours = Math.abs(differenceInHours(endDate, startDate))
    const minutes = Math.abs(differenceInMinutes(endDate, startDate)) % 60
    return `${hours}:${String(minutes).padStart(2, "0")}`
}

/**
 * Given a date object or string, and an optional timezone, returns a date object that can be converted to tz's time
 * If no timezone is given, we use the assumed local timezone of the user
 */
export const convertUtcToTimezone = (dateString: Date | string, tz: string = getAssumedLocalTimezone()): Date => {
    // converts a date/date string into a timezone offset Date
    return utcToZonedTime(dateString, tz)
}

/**
 * Given a date object or string, and an optional timezone, returns a date object that can be converted to UTC time
 * If no timezone is given, we use the assumed local timezone of the user
 * To get the UTC timestamp from the date object you have to use .toISOString()
 */
export const convertTimezoneToUtc = (value: Date | string, tz: string = getAssumedLocalTimezone()): Date => {
    return zonedTimeToUtc(value, tz)
}

/**
 * Given a mode string, and a boolean checking for military time, returns an obj with the date and time formats
 * @param mode Specify "date" for date-only, "time" for time-only, or "datetime" for date and time
 * @param is24Hour  Set to true for a 24-hour (military-style) time format
 */
export const getDateTimeFormats = (mode: string, is24Hour: boolean): tDateTimeFormatResponse => {
    let dateFormat = is24Hour ? DATETIME_FORMAT_24H : DATETIME_FORMAT
    const timeFormat = is24Hour ? TIME_FORMAT_24H : TIME_FORMAT
    if (mode == "date") {
        dateFormat = DATE_ONLY_FORMAT
    } else if (mode == "time") {
        dateFormat = timeFormat
    }
    return { dateFormat: dateFormat, timeFormat: timeFormat }
}

/**
 * A easier way to reference the built in Intl functionality for getting user's local timezone
 */
export const getAssumedLocalTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Given a timezone string, or using the assumed local timezone, return a string with the timezone acronym or offset
 */
export const getTimezoneAcronym = (tz: string = getAssumedLocalTimezone()): string => {
    const tzFmt = new Intl.DateTimeFormat("en", {
        timeZoneName: "short",
        timeZone: tz,
    })
    // if tz has no acronym, show the UTC offset of GMT+4 (https://stackoverflow.com/a/61109337/6710369)
    const currentTimeStr = tzFmt.format(new Date())
    return currentTimeStr.slice(currentTimeStr.search(/ [A-Z+0-9-]*$/g))
}

/**
 * We have a lot of place where we interchange if we are using date strings or date objects, so using this
 * will ensure that we always are passing a Date object into date-fns methods that expect Date objects
 */
export const getAsDate = (dateValue: string | Date): Date => {
    if (typeof dateValue === "string") {
        dateValue = parseISO(dateValue)
    }
    return dateValue
}

/**
 * Returns true if a given date string is in ISO format
 * @param { string } value - string value of the selected date for a date selector
 * @returns { boolean } - true if date string is in ISO Format, false otherwise
 */
export const isISOFormat = (value: string): boolean => {
    return value ? isValid(parseISO(value)) : false
}

/**
 * Given a date object, hour and minute integers - return a date object with those adjustments
 * ex adjustDatetimeValues(datObj, {"hours": 8, "minutes": 0})
 *
 * Second/millisecond values default to 0 unless they are specifically given
 */
export const adjustDatetimeValues = (date: Date, adjustments: tDatetimeAdjustments): Date => {
    adjustments["seconds"] = adjustments["seconds"] ? adjustments["seconds"] : 0
    adjustments["milliseconds"] = adjustments["milliseconds"] ? adjustments["milliseconds"] : 0
    return set(date, adjustments)
}

/**
 *
 * @param {tStartOfWeekIndex} startOfWeekIndex 0 = Sun / 6 = Sat
 * Given a start of the week index, return a locale object that can be used by react-datepicker
 */
export const getLocaleForDatePicker = (startOfWeekIndex: tStartOfWeekIndex): Record<string, any> => {
    return {
        formatDistance: formatDistance,
        formatLong: formatLong,
        formatRelative: formatRelative,
        localize: localize,
        match: match,
        options: {
            weekStartsOn: startOfWeekIndex,
        },
    }
}

/**
 * @param {string} key - key to find
 * @param {any} obj - object to search
 * @returns any
 * Retrieve a nested key from a deeply nested dictionary. This doesn't handle nested
 * arrays because we're assuming we're not looking for keys from nested lists.
 */
export const recursiveDictKeyLookup = (key: string, obj: Record<string, any>): any => {
    if (!obj || !key) return null
    if (key in obj) {
        return obj[key]
    }
    for (const val of Object.values(obj)) {
        if (val && typeof val === "object") {
            const candidate = recursiveDictKeyLookup(key, val)
            if (candidate) {
                return candidate
            }
        }
    }
    return null
}

/**
 * Add the number of weeks to the given date.
 * @param {Date} date Date.
 * @param {number} numberOfWeeks Number of weeks to add.
 * @param {string} [dateFormat] Output date format.
 * @returns {Date} Calculated date.
 */
export const getDateWithWeeks = (date: Date, numberOfWeeks: number, dateFormat?: string): string =>
    format(addWeeks(date, numberOfWeeks), dateFormat || STORED_DATE_ONLY_FORMAT)

/**
 * @param {string} value - input string to check for a number
 * @returns string | null
 * Guides the user through typing a proper number into a text input field.
 * They can type a number freely, but they won't be able to input nonsense.
 * Unfortunately, this can still result in something that looks like it *could* become
 * a number, such as "-" or ".", so the return value may not necessarily parse to a number.
 */
export const prepNumberFieldValue = (value: string): string | null => {
    // Wonderful guide to this regex for matching decimals:
    // https://regexland.com/regex-decimal-numbers/
    // Also matches an in-progress decimal or negative number, e.g. "-" or "."
    const decimalRegex = /[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)|[+-]|\./
    const match = value.match(decimalRegex)
    if (match == null) return null
    return match[0]
}

export const fahrenheitToCelsius = (tempF: number, decimalPlaces: number): string => {
    return (((tempF - 32) * 5) / 9).toFixed(decimalPlaces)
}

/**
 * Given an angle, return the cardinal (compass) direction acronym for that angle.
 * @param windAngle - number between 0-360
 * @returns cardinal direction acronym string
 */
export const angleToCardinalDirection = (windAngle: number): string => {
    if (!isNumber(windAngle)) return ""
    const directions = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW",
    ]
    return directions[Math.round(windAngle / 22.5) % 16]
}

export const convertDotPathToJsonPointer = (dotPath: string): string => {
    const regex = /\./g
    return `/${dotPath.replace(regex, "/")}`
}

export const convertJsonPointerToDotPath = (jsonPath: string): string => {
    const regex = /\//g
    if (!jsonPath) return ""
    // trim off the initial /
    if (jsonPath.startsWith("/")) {
        jsonPath = jsonPath.substring(1)
    }
    return jsonPath.replace(regex, ".")
}

/**
 * @param {object} response - response object from a 400-level API error response
 * @param {string} defaultMessage - default error message to use if one can't be parsed from the response
 * @returns string
 * Attempts to parse out an error message from the myriad of error responses the app might encounter.
 */
// TODO: Update as new error response structures are discovered.
type ErrorData = {
    detail?: string
    errors?: {
        non_field_errors?: string[] | string
        detail?: string
    } & Record<string, string>
}
type ErrorResponse = { data: ErrorData }
type HttpErrorResponse = { response: ErrorResponse }
export const getMessageFromAPIErrorResponse = (
    response: HttpErrorResponse | ErrorResponse | ErrorData,
    defaultMessage: string
): string => {
    let message = defaultMessage
    // Check for an "errors" key in the error response
    // OK if |errors| ends up undefined.
    let errorData: ErrorData
    if ("response" in response) {
        // Dealing with a full-blown HTTP Response object
        errorData = response.response.data
    } else if ("data" in response) {
        // Dealing with an unwrapped Response object
        errorData = response.data
    } else if ("detail" in response || "errors" in response) {
        // Response data was already passed in
        errorData = response
    } else {
        // Who knows what we've got on our hands
        return defaultMessage
    }

    // There might just be a top-level "detail" key
    if (errorData.detail) {
        return errorData.detail
    }

    // Usually there's an "errors" key to comb through
    const { errors } = errorData
    if (errors?.non_field_errors) {
        const non_field_errors = errors.non_field_errors
        if (Array.isArray(non_field_errors)) {
            message = non_field_errors[0]
        } else {
            message = non_field_errors
        }
    } else if (errors?.detail) {
        message = errors.detail
    } else {
        // Take the first field we find
        for (const key in errors) {
            message = errors[key]
            break
        }
    }

    return message || defaultMessage
}

export const featureFlagClientIdentifyUser = (user: iCurrentUser, client: LDClient): void => {
    // Monthly Active Users (MAUs) are tracked by key and affect billing.
    // We don't want to track test users because that would run up the MAU total.
    // Instead, use a static MAU key for all test users so they collectively count as just one MAU.
    const key =
        // BUILD_ENV will be "test" when the bundle is built specifically for UI testing.
        // NODE_ENV will be automatically set to "test" by Jest for unit testing.
        process.env.BUILD_ENV === "test" || process.env.NODE_ENV === "test"
            ? "authenticated-test-user"
            : user.employee_id.toString()
    const loggedInUserContext = {
        kind: "user",
        key,
        anonymous: false,
        company_id: user.company_id,
        company_name: user.company,
        company_role: user.company_role,
        email: user.email,
        is_staff: user.is_staff,
        platform: "web",
        user_role: user.user_role,
        _meta: {
            privateAttributes: ["email"],
        },
    }
    client.identify(loggedInUserContext)
}

/**
 * Given the relatedTransform and relatedSchemas from the context object, can the selected
 * forms be bundled.
 * @param {number | null} relatedTransform - transform number
 * @param {array | null} relatedSchemas - list of schemas
 * @returns boolean - returns true if forms can be bundled
 */
export const isBundleEnabled = (relatedTransform: number | null, relatedSchemas: any[] | null): boolean => {
    return (
        !!relatedTransform &&
        !!relatedSchemas?.length &&
        relatedSchemas?.every(schema => schema.schema_type === "BUNDLE")
    )
}

/**
 * Take a string and convert it into a clean file name for file download naming
 * ex. "T&M Tracking" -> "tm_tracking"
 * @param initialValue
 * @returns
 */
export const convertToFilename = (initialValue: string): string => {
    // if nothing is given, return a generic filename
    if (!initialValue) return "rhumbix_download"
    // remove anything that isn't a letter or digit, space, underscore, /
    let cleanFilename = initialValue.replace(/[^a-zA-Z0-9_ /-]/gi, "")
    // remove any trailing or leading spaces
    cleanFilename = cleanFilename.trim()
    // swap 1 or more spaces for underscores
    cleanFilename = cleanFilename.replace(/[ /-]+/gi, "_")
    // set all char to be lowercase
    return cleanFilename.toLowerCase()
}

/**
 * Given a list of transforms and a schema id, find all schemas that have the schema id in both the from_schemas
 * list and is the same as the to_schema id
 * @param transforms List of transforms
 * @param schemaId ID of schema to match
 * @returns List of valid transforms where schema id is present in from_schemas and matches to_schema id
 */
export const filterCopyToTransformsBySchemaId = (
    transforms: tTransformOption[],
    schemaId: number
): tTransformOption[] => {
    return filterTransformsBySchemaId(
        transforms,
        schemaId,
        true,
        !getFlagEnabled("WA-7878-copy-to-import-cross-schema")
    )
}

/**
 * Given a list of transforms and a schema id, find all schemas that match the from schema and/or the to schema
 * Copy-To transforms would match both the to and from schema
 * For Bundles - the from schema would match the form schema id, and the to schema would be the bundle schema id
 * @param transforms List of transforms
 * @param schemaId ID of schema to match
 * @param matchFromSchema If true, will filter transforms based on if schema id is in from_schemas list
 * @param matchToSchema If true, will filter transforms based on if the schema id matches the to schema
 * @returns List of valid transforms for the provided schema id and match settings.
 */
export const filterTransformsBySchemaId = (
    transforms: tTransformOption[],
    schemaId: number,
    matchFromSchema = false,
    matchToSchema = false
): tTransformOption[] => {
    if (!transforms?.length || !schemaId || (!matchFromSchema && !matchToSchema)) return []
    let validTransforms: tTransformOption[] = transforms
    if (matchFromSchema) {
        validTransforms = validTransforms.filter(transform => transform.from_schemas?.includes(schemaId))
    }
    if (matchToSchema) {
        validTransforms = validTransforms.filter(transform => transform.to_schema === schemaId)
    }
    return validTransforms
}

/**
 * Gets all transforms where the schema(s) that are related to the selected form(s) are in the from_schemas list
 * Returns all valid transforms for the selected row(s)
 * @param context - list view context
 * @param bundleTransforms List of transforms
 * @returns Set of all valid transforms or an empty set if no valid bundle transforms available
 */
export const getBundleTransforms = (
    context: tContext,
    bundleTransforms: tTransformOption[]
): Set<tTransformOption | null> => {
    const validTransforms: Set<tTransformOption | null> = new Set()
    context.selectedRows.forEach(store => {
        const bundleTransformForSchema = filterTransformsBySchemaId(
            bundleTransforms,
            store?.schema,
            true // matchFromSchema
        )
        // we need to account for schemas that don't have bundling set up, so add a null if no valid bundles
        if (!bundleTransformForSchema?.length) validTransforms.add(null)
        else {
            // if there are more than one matching bundle transforms for a given schema, use the newest one
            bundleTransformForSchema.sort((a, b) =>
                a.created_on < b.created_on ? -1 : a.created_on === b.created_on ? 0 : 1
            )
            validTransforms.add(bundleTransformForSchema[0])
        }
    })
    // if we only have nulls in the set of transforms - return empty set
    if (validTransforms.size === 1 && validTransforms.values().next().value === null) return new Set()
    return validTransforms
}

export type IconName =
    | "attachment"
    | "cost_code"
    | "date"
    | "default"
    | "delay"
    | "delete"
    | "document"
    | "employee"
    | "equipment"
    | "file"
    | "fileIcon"
    | "labor"
    | "material"
    | "materials"
    | "measurement"
    | "misc"
    | "pdf"
    | "pdfIcon"
    | "radioSelected"
    | "radioUnselected"
    | "safety"
    | "signature"
    | "time"
    | "trash"
    | "text"
    | "textIcon"
    | "video"
    | "videoIcon"

/**
 * Given an icon name, return the Icon from the Design System as React Element - defaults to misc if not found
 * @param { IconName } name A valid icon name - see IconName type for options
 * @param { Record<string, any> } styles Optional. Any specific styles you want applied to the icon
 * @returns SVG Icon as React Element
 */
export const getStyledIcon = (name: IconName | undefined, styles: Record<string, string>): ReactElement => {
    switch (name) {
        case "attachment":
            return new IconAttachment(styles)
        case "cost_code":
            return new IconCostCode(styles)
        case "date":
            return new IconCalendarWeek(styles)
        case "delay":
            return new IconDelay(styles)
        case "delete":
        case "trash":
            return new IconTrash(styles)
        case "equipment":
            return new IconEquipment(styles)
        case "labor":
        case "employee":
            return new IconCraftWorker(styles)
        case "material":
        case "materials":
            return new IconMaterials(styles)
        case "measurement":
            return new IconMeasurement(styles)
        case "misc":
            return new IconMisc(styles)
        case "radioSelected":
            return new IconRadioButtonSelected(styles)
        case "radioUnselected":
            return new IconRadioButtonUnselected(styles)
        case "safety":
            return new IconSafety(styles)
        case "signature":
            return new IconSignature(styles)
        case "time":
            return new IconTime(styles)
        case "pdfIcon":
        case "pdf":
            return new IconDocumentPDF(styles)
        case "textIcon":
        case "document":
        case "text":
            return new IconDocumentText(styles)
        case "videoIcon":
        case "video":
            return new IconDocumentVideo(styles)
        case "fileIcon":
        case "file":
            return new IconDocumentImage(styles)
        default:
            return new IconMisc(styles)
    }
}

export const getFileIconOrImage = (
    attachment: tFormAttachment,
    iconStyle: Record<string, any>,
    className = ""
): HTMLImageElement | JSX.Element => {
    let thumb = <IconDocumentGeneric style={{ ...iconStyle }} />
    if (attachment.type === "application/pdf") {
        thumb = <IconDocumentPDF style={{ ...iconStyle }} />
    } else if (attachment.type?.startsWith("image/")) {
        thumb = <img src={attachment.url} alt="Thumbnail" className={className} />
    } else if (attachment.type?.startsWith("video/")) {
        thumb = <IconDocumentVideo style={{ ...iconStyle }} />
    }
    return thumb
}

/**
 * Given a url string, will return an object where the query param is the key and the value is, well, the value
 * @param {string} url
 * @returns {Object} Object with breakdown of the queryparams in the url
 */
export const parseQueryString = (url: string): Record<string, any> => {
    // pull out querystring params to pass on to action and subsequent api call
    const queryParams = url.split("?")[1] || ""
    const queryObj: Record<string, any> = {}
    for (const group of queryParams.split("&")) {
        if (group) {
            const keyValue = group.split("=")
            queryObj[keyValue[0]] = keyValue[1]
        }
    }
    return queryObj
}

/**
 * Given a list of objects with at least one field that holds a date or date string, will return an object
 * with minDate and maxDate keys with the min/max dates from the list
 * @param { Record<string, any> }objs List of objects
 * @param { string } dateKey Key with the targeted date value
 * @returns { Record <"minDate" | "maxDate", Date>} min and max date values as Date objects
 */
export const getMinMaxDates = (
    objs: Record<string, any>[],
    dateKey: string
): Record<"minDate" | "maxDate", Date> => {
    const allDates: Date[] = []
    objs.forEach(item => {
        // if the item has a date, and it's valid, get it as a date object
        if (item?.[dateKey]) {
            try {
                const itemDate = getAsDate(item?.[dateKey])
                allDates.push(itemDate)
            } catch (error) {
                // if we can't get the value as a date, skip it
                return
            }
        }
    })
    const maxDate = max(allDates)
    const minDate = min(allDates)
    return { maxDate, minDate }
}

/**
 * Given a number of minutes, the value will be converted into a hh:mm timestamp
 * @param {number} minutes
 * @returns {string} minute value converting into "hh:mm"
 */
export const convertMintoTimestamp = (minutes: number): string => {
    //return string of hours:minutes from a number of minutes
    const hours = Math.floor(minutes / 60)
    const min: number = Math.floor(minutes - hours * 60)
    //return HH:MM format
    return `${hours}:${String(min).padStart(2, "0")}`
}

type timeType = "totalTime" | "stTime" | "otTime" | "dtTime"

/**
 * Given a list of employee workshifts, return an object with ST/OT/DT/Total times in "hh:mm" timestamps
 * @param ewss list of employeeworkshifts to total
 * @returns {Record<string, string>} Object with breakdowns of time
 */
export const getEWSTimeBreakdown = (ewss: iEmployeeWorkShift[]): Record<timeType, string> => {
    let stMin = 0
    let otMin = 0
    let dtMin = 0
    // go through all employee work shifts and their respective work components to get all time in minutes
    ewss.forEach(ews => {
        if (ews.work_components) {
            ews.work_components.forEach(wc => {
                if (wc.adjusted_minutes_st) stMin += wc.adjusted_minutes_st
                if (wc.adjusted_minutes_ot) otMin += wc.adjusted_minutes_ot
                if (wc.adjusted_minutes_dt) dtMin += wc.adjusted_minutes_dt
            })
        }
    })
    const totalTime = convertMintoTimestamp(stMin + otMin + dtMin)
    const stTime = convertMintoTimestamp(stMin)
    const otTime = convertMintoTimestamp(otMin)
    const dtTime = convertMintoTimestamp(dtMin)
    return {
        totalTime,
        stTime,
        otTime,
        dtTime,
    }
}

// order of "granularity" for signature pad types
const padTypes: Record<SignaturePadType, number> = {
    DRAW: 1,
    TYPE: 2,
    HYBRID: 3,
}

/**
 * Given a list of employee workshifts, check work components for project level settings for signature
 * If we are signing for multiple projects, we defer to the most granular setting. Given a mix of booleans,
 * we will defer to the truthy setting.
 * @param ewss list of employeeworkshifts to total
 * @returns {Record<string, string>} Object with signature settings for signature screen
 */
export const getSigSettingsFromEws = (ewss: iEmployeeWorkShift[]): Record<string, any> => {
    // start with the broadest possible settings
    const sigSettings = {
        signaturePeriod: "WEEKLY",
        signaturePadType: "HYBRID" as SignaturePadType,
        promptEodQuestions: false,
        displayStartStop: false,
    }
    ewss.forEach(ews => {
        if (ews.work_components) {
            ews.work_components.forEach(wc => {
                if (wc.signature_period === "DAILY") sigSettings.signaturePeriod = "DAILY"
                if (
                    wc.signature_pad_type &&
                    padTypes[wc.signature_pad_type] < padTypes[sigSettings.signaturePadType]
                )
                    sigSettings.signaturePadType = wc.signature_pad_type
                if (wc.prompt_eod_questions) sigSettings.promptEodQuestions = true
                if (wc.display_start_stop) sigSettings.displayStartStop = true
            })
        }
    })
    return sigSettings
}

/**
 * Given a boolean, return Yes or No string based on the value
 */
export const convertBoolToYesNo = (value: boolean): "Yes" | "No" => {
    return value ? "Yes" : "No"
}

/**
 * Used to get the named key from the cookie
 * @param name value you want to extract from the cookie
 * @returns
 */
export const getCookie = (name = "csrftoken"): string | null => {
    let cookieValue = null
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";")
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

/**
 * Sets a cookie
 * @param name The name of the cookie you want to set
 * @param value The value of the cookie you want to set
 * @param days The number of days you want the cookie to live.
 *             Not providing this value will create a session cookie
 */
export const setCookie = (name: string, value: string, path = "/", days: number | undefined): void => {
    let expires = ""
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=" + path
}

/**
 * Deletes a cookie
 * @param name Cookie name you want to delete
 */
export const deleteCookie = (name: string, path = "/"): void => {
    if (getCookie(name)) {
        document.cookie = name + "=" + (path ? ";path=" + path : "") + ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
    }
}

/**
 * Set the value of a cell's data. Ideally this would live in an ag-grid-ts-util type file,
 * but that currently causes a circular dependency
 * @param rowData The data for the row
 * @param colDef The column definition
 * @param newValue The new value to set it to
 */
export const setCellData = (rowData: tResourceObject, colDef: Record<string, any>, newValue: tJSONValue): void => {
    const valueSetter = colDef.valueSetter
    if (valueSetter) {
        valueSetter({
            data: rowData,
            colDef: colDef,
            newValue: newValue,
        })
    } else {
        rowData[colDef.field] = newValue
    }
}

/**
 * Given a url and a filename, download an image
 * @param url
 * @param filename
 */
export const downloadImage = (url: string, filename?: string): void => {
    const link = document.createElement("a")
    link.href = url
    if (filename) link.download = filename
    link.click()
}

/**
 * Given a string, return the string with every word capitalized. All other letters will be lowercased.
 * All instances of whitespace will be replaced with a single space.
 * @param text String to be updated with capital letters
 * @returns String with every word capitalized
 */
export const capitalizeFirstLetters = (text: string): string => {
    if (!text) return ""
    const words = text.trim().split(/\s+/)
    return words.map((word: string) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)).join(" ")
}

export const getSelectedRowStatuses = (context: tContext): string[] => {
    const allStatusesForRows = context.selectedRows.map(
        (row: { schema: number; status: string; deleted_on: string }) => {
            let statusTransitions = [] as string[]
            if (
                context.referenceableData != undefined &&
                context.referenceableData.companyFormSchemas &&
                !row.deleted_on
            ) {
                // we don't allow action on deleted rows
                const schema = context.referenceableData.companyFormSchemas[row.schema]
                if (schema != undefined) {
                    statusTransitions = schema.status_transitions[row.status] || []
                }
            }
            return statusTransitions
        }
    )
    // An array of statuses that *every* selected row could be transitioned to
    const firstRowStatuses = allStatusesForRows.shift() || []

    return firstRowStatuses.filter(rowStatus => {
        return allStatusesForRows.every(statusList => statusList.indexOf(rowStatus) !== -1)
    })
}

/**
 * Given a filter state, return a list of projectIds. If no projects filtered, return empty list.
 * @param filters - filter state
 * @returns list of any selected project ids
 */
export const getFilteredProjects = (filters: iMutableFilterState): number[] => {
    if (!filters?.projectId || !getFlagEnabled("WA-7130-disable-shift-extra-add-row")) return []
    return Array.isArray(filters.projectId) ? filters.projectId : [filters.projectId]
}

/**
 * Given a string - replace spaces with separator, removes any non-alpha/non-number char except for /,
 * and converts string to lowercase with no spaces. Spaces are replaced with the separator.
 * @param label Value to convert into slug. If undefined, returns empty string
 * @param separator: Optional - string char to replace spaces. Default is "-"
 * @returns
 */
export const slugify = (label?: string, separator = "-"): string => {
    if (!label) return ""
    return label
        .toLowerCase()
        .replace(/[^a-z0-9 /]/gi, "") // remove any non-alpha/non-digit chars except for spaces and forward slashes
        .replace(/[ -]+/gi, separator) // replace one or more spaces with the separator
}

export const isDateTheNextDay = (srcDate: Date, targetDate: Date): boolean => {
    const nextDay = addDays(srcDate, 1)
    return (
        nextDay.getFullYear() === targetDate.getFullYear() &&
        nextDay.getMonth() === targetDate.getMonth() &&
        nextDay.getDate() === targetDate.getDate()
    )
}
