import { ApprovalStatus, BaseTkEntry, tUserRole } from "../../common/types"
import { getFlagEnabled } from "../../getFlagValue"
import { tContext, WeeklyViewSourceData } from "./types"
import { iTimekeepingStatus } from "../../cached-data/types"

const entriesAreAllSynced = (tkes: Pick<BaseTkEntry, "status">[]) =>
    tkes.length > 0 && tkes.every(tke => tke.status === ApprovalStatus.SYNCED)

const sourceDataIsAllSynced = (sourceData: WeeklyViewSourceData) => {
    const { absences, employeeEntries, timekeepingEntries, ewsStartStopTimes } = sourceData
    return entriesAreAllSynced([...absences, ...employeeEntries, ...timekeepingEntries, ...ewsStartStopTimes])
}

export const selectedCellHasAllSyncedData = (context: tContext): boolean => {
    // During a Fancy Search, selectedCellData is briefly an empty array (due to a table re-render?).
    // In any case, avoid failing.
    if (Array.isArray(context.selectedCellData) || !context.selectedCellData) {
        return false
    }
    return sourceDataIsAllSynced(context.selectedCellData)
}

const entriesUnableToTransition = (
    tkes: Pick<BaseTkEntry, "status">[],
    userRole: tUserRole,
    statuses?: iTimekeepingStatus[]
) => {
    if (tkes.length > 0) {
        if (getFlagEnabled("WA-8087-custom-timekeeping-statuses") && statuses) {
            return tkes.some(tke => {
                if (!tke.status) return false
                const statusObj = statuses.find(s => {
                    const status = typeof tke.status === "object" ? tke.status.name : tke.status
                    return s.name === status
                })
                return !statusObj?.can_edit_cell || !statusObj.can_edit_cell.includes(userRole)
            })
        } else {
            const statusList = tkes.reduce((statuses, tke) => {
                const status = tke.status as ApprovalStatus
                if (status !== undefined && !statuses.includes(status)) {
                    statuses.push(status)
                }
                return statuses
            }, [] as ApprovalStatus[])

            return (
                ["FOREMAN", "OFFICE_STAFF"].includes(userRole) ||
                (userRole === "PM" &&
                    [ApprovalStatus.PAYROLL_VERIFIED, ApprovalStatus.EXPORTED].some(s => statusList.includes(s)))
            )
        }
    }
    return false
}

const sourceDataUnableToTransition = (
    sourceData: WeeklyViewSourceData,
    userRole: tUserRole,
    statuses?: iTimekeepingStatus[]
) => {
    if (!sourceData) return false

    const { absences, employeeEntries, timekeepingEntries, ewsStartStopTimes } = sourceData
    return entriesUnableToTransition(
        [...absences, ...employeeEntries, ...timekeepingEntries, ...ewsStartStopTimes],
        userRole,
        statuses
    )
}

/**
 * Determines whether the current table context or selected cell can transition
 * @param context A context containing the data for the selected cell/table
 * @returns
 */
export const selectedCellUnableToTransition = (context: tContext): boolean => {
    // During a Fancy Search, selectedCellData is briefly an empty array (due to a table re-render?).
    // In any case, avoid failing.
    if (Array.isArray(context.selectedCellData)) {
        return false
    }
    const tkStatuses =
        getFlagEnabled("WA-8087-custom-timekeeping-statuses") && context.referenceableData?.timekeepingStatuses
            ? Object.values(context.referenceableData.timekeepingStatuses)
            : undefined
    return sourceDataUnableToTransition(context.selectedCellData, context.currentUser.user_role, tkStatuses)
}
