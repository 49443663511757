import * as API from "../api"
import Rmbx from "../../js/util"
import makeRequest from "../networkClient"

import { format } from "date-fns"
import { STORED_DATE_ONLY_FORMAT, WEEKDAY_INDEX } from "../common/constants"
import { getAsDate } from "../common/ts-utils"
import { getFlagEnabled } from "../getFlagValue"

export const defaultErrorMsg =
    "Unknown error, please contact your administrator " + "or Rhumbix at support@rhumbix.com"

export function sendMassText(companyId, message, employeeIds) {
    return dispatch => {
        dispatch(sendingMassText())
        return API.sendMassText(companyId, message, employeeIds)
            .then(() => {
                dispatch(massTextSuccessful())
            })
            .catch(response => {
                dispatch(massTextFailed())
                const errors = response && response.data ? response.data.errors : defaultErrorMsg
                dispatch(create_failed("massTextMessage", errors))
            })
    }
}

export function sendingMassText() {
    return {
        type: "SENDING_MASS_TEXT",
    }
}

export function exportActionStarted() {
    return {
        type: "EXPORT_ACTION_STARTED",
    }
}

export function exportActionCompleted() {
    return {
        type: "EXPORT_ACTION_COMPLETED",
    }
}
export function massTextSuccessful() {
    return {
        type: "MASS_TEXT_SUCCESSFUL",
    }
}

export function massTextFailed() {
    return {
        type: "MASS_TEXT_FAILED",
    }
}

export function addEmployeeToList(employeeId) {
    return {
        type: "ADD_EMPLOYEE_TO_LIST",
        employeeId,
    }
}

export function removeEmployeeFromList(employeeId) {
    return {
        type: "REMOVE_EMPLOYEE_FROM_LIST",
        employeeId,
    }
}

export function toggle_company_form_submittable() {
    return {
        type: "TOGGLE_COMPANY_FORM_SUBMIT_ENABLED",
    }
}

export const updateSideRailFilterState = (startDate, endDate) => {
    return {
        type: "SET_MULTIPLE_FILTER_VALUES",
        payload: {
            filtersToAdd: {
                startDate,
                endDate,
            },
        },
    }
}

export const updatePageFilterState = (startDate, endDate) => {
    return (dispatch, getState) => {
        const currentUser = getState().current_user
        dispatch({
            type: "SET_PENDING_FILTERS_TO_STATE",
            payload: {
                filtersToAdd: {
                    startDate,
                    endDate,
                },
                currentUser,
            },
        })
    }
}

export function create_failed(form, errors) {
    return {
        type: "CREATE_FAILED",
        form,
        errors,
    }
}

export function create_successful(form, data) {
    return {
        type: "CREATE_SUCCESSFUL",
        form,
        data,
    }
}

export function update_field(form, name, value) {
    return {
        type: "UPDATE_FIELD",
        form,
        name,
        value,
    }
}

export function set_fields(form, data) {
    return {
        type: "SET_FIELDS",
        form,
        data,
    }
}

export function clear_fields(form) {
    return {
        type: "CLEAR_FIELDS",
        form,
    }
}

export function validate(form, key, errors) {
    if (errors && errors.length > 0) {
        return validation_failed(form, key, errors)
    } else {
        return validation_passed(form, key)
    }
}

export function validation_failed(form, key, errors) {
    return {
        type: "VALIDATION_FAILED",
        form,
        key,
        errors,
    }
}

export function validation_passed(form, key) {
    return {
        type: "VALIDATION_PASSED",
        form,
        key,
    }
}

export function update_current_user(data) {
    return {
        type: "UPDATE_CURRENT_USER",
        data,
    }
}

export function update_feature_toggles(data) {
    return {
        type: "FEATURES_UPDATE",
        data,
    }
}

export function projectUpdateSucceeded() {
    return {
        type: "UPDATE_PROJECT_SUCCEEDED",
    }
}

export function projectUpdateFailed(errorObj) {
    return {
        type: "UPDATE_PROJECT_FAILED",
        errorObj,
    }
}

export function projectCreateFailed(errorObj) {
    return {
        type: "CREATE_PROJECT_FAILED",
        errorObj,
    }
}

export function updateBudgetItems(data) {
    return {
        type: "UPDATE_BUDGET_ITEMS",
        data,
    }
}

export function update_employees(data) {
    return {
        type: "UPDATE_EMPLOYEES",
        data,
    }
}

export function updateProjectEmployees(data) {
    if (!data) {
        return {
            type: "NOOP",
        }
    } else {
        return {
            type: "UPDATE_PROJECT_EMPLOYEES",
            data,
        }
    }
}

export function updateEquipment(data) {
    return {
        type: "UPDATE_EQUIPMENT",
        data,
    }
}

export function employeeIdFilterUpdated(id) {
    return {
        type: "EMPLOYEE_ID_FILTER_UPDATED",
        id,
    }
}

export function updateEmployeesCsvData(data) {
    return {
        type: "UPDATE_EMPLOYEES_CSV_DATA",
        data,
    }
}

export function updateCostCodesCsvData(data) {
    return {
        type: "UPDATE_COST_CODES_CSV_DATA",
        data,
    }
}

export function updateEquipmentCsvData(data) {
    return {
        type: "UPDATE_EQUIPMENT_CSV_DATA",
        data,
    }
}

export function updateMaterialsCsvData(data) {
    return {
        type: "UPDATE_MATERIAL_CSV_DATA",
        data,
    }
}

export function clearEmployeesCsvData() {
    return {
        type: "CLEAR_EMPLOYEES_CSV_DATA",
    }
}

export function clearEquipmentCsvData() {
    return {
        type: "CLEAR_EQUIPMENT_CSV_DATA",
    }
}

// region CHANGE PASSWORD

export function changePassword(data) {
    return () => {
        return API.changePassword(data)
    }
}

// endregion

export function create_absence(data) {
    return dispatch => {
        return API.createAbsence(data).then(() => {
            dispatch(query_work_shifts())
        })
    }
}

export const get_current_user = () => (dispatch, getState) => {
    const current_user = getState().current_user

    if (Object.keys(current_user).length > 0) {
        return Promise.resolve()
    }

    return API.getCurrentUser()
        .then(data => {
            const userData = { ...data[0] }

            const startOfWeekIndex = WEEKDAY_INDEX[userData.employee.company.start_of_week]
            userData["employee"]["company"]["start_of_week_index"] = startOfWeekIndex

            dispatch(update_current_user(userData))
            dispatch(update_feature_toggles(userData.features))

            //TODO: Maybe there is a better place to put this?
            if (userData.user_role === "WORKER" && window.location.pathname !== "/rhumbix/role-not-available/") {
                window.location = "/rhumbix/role-not-available/"
            } else {
                try {
                    Rmbx.tracker.loginUser(userData)
                } catch (err) {
                    // Do nothing - FullStory isn't working
                }
            }
        })
        .catch(err => {
            dispatch(get_current_user_failed(err))
        })
}

export function get_current_user_failed(err) {
    // If the request failed due to a network error, the response will be part
    // of the error object
    const data = err.response
        ? {
              errorStatus: err.response.status,
              errorStatusText: err.response.statusText,
              errorResponseData: err.response.data,
          }
        : {
              errorStatus: null,
              errorStatusText: "Non-network error",
              errorResponseData: {
                  detail: "Non-network error",
              },
          }

    return {
        type: "GET_CURRENT_USER_FAILED",
        data,
    }
}

export function getBudgetItems(queryParams) {
    return dispatch => {
        return API.getBudgetItems(queryParams).then(function (data) {
            dispatch(updateBudgetItems(data.results || []))
            if (data.next) {
                let newQueryParams = data.next.split("?")[1]
                newQueryParams = newQueryParams.split("&")
                newQueryParams = newQueryParams.reduce((acc, qp) => {
                    const kv = qp.split("=")
                    acc[kv[0]] = kv[1]
                    return acc
                }, {})
                return dispatch(getBudgetItems(newQueryParams))
            }
        })
    }
}

export function getExportFormats() {
    return dispatch => {
        dispatch(fetchingExportFormats())
        return API.getExportFormats().then(function (data) {
            dispatch(updateExportFormats(data))
        })
    }
}

export function fetchingExportFormats() {
    return {
        type: "FETCHING_EXPORT_FORMATS",
    }
}

export function updateExportFormats(data) {
    return {
        type: "UPDATE_EXPORT_FORMATS",
        data,
    }
}

export function updateExportFormatSelected(data) {
    return {
        type: "UPDATE_EXPORT_FORMAT_SELECTED",
        data,
    }
}

export function getEmployees(is_active = true) {
    return dispatch => {
        return API.getEmployees({ is_active }).then(data => {
            dispatch(update_employees(data))
        })
    }
}

export function getProjectEmployees(project_id, is_active = true) {
    return dispatch => {
        return API.getProjectEmployees(project_id, { is_active }).then(data => {
            dispatch(updateProjectEmployees(data))
        })
    }
}

export function getEquipment(projectId) {
    return dispatch => {
        return API.getEquipment(projectId)
            .then(data => {
                dispatch(updateEquipment(data))
            })
            .catch(error => {
                // handle case where feature flag is off
                if (error.response && error.response.status === 403) {
                    dispatch(updateEquipment({}))
                }
            })
    }
}

export function logout() {
    return {
        type: "LOGOUT",
    }
}

export const employeeWorkShiftsChanged = data => {
    return {
        type: "EMPLOYEE_WORK_SHIFTS_CHANGED",
        data,
    }
}

export const startLoader = () => {
    return {
        type: "LOADING_INDICATOR_STARTED",
    }
}

export const stopLoader = () => {
    return {
        type: "LOADING_INDICATOR_STOPPED",
    }
}

export const getEmployeeWorkShifts = (projectId, startDate, endDate, page_size = 4096) => {
    return dispatch => {
        dispatch(startLoader())
        return API.getEmployeeWorkShifts(projectId, startDate, endDate, page_size)
            .then(data => {
                dispatch(employeeWorkShiftsChanged(data["results"]))
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export const getEmployeeWorkShiftToSign = (ewsId, combinedKey, params = {}) => {
    return dispatch => {
        dispatch(startLoader())
        return API.getEmployeeWorkShiftToSign(ewsId, combinedKey, params)
            .then(data => {
                // for some reason a 400 response doesn't always go to catch - process the errors here if we get 'em
                // a 401 ends up here, while a 403 ends up in the catch. It's weird.
                if (data?.error) {
                    alert(`${data.error}`)
                    return
                } else if (data.length == 0) {
                    alert("This timecard has already been signed. Please contact your foreman for a new link.")
                }
                dispatch(employeeWorkShiftsChanged(data))
            })
            .catch(err => {
                try {
                    const error = JSON.parse(err.response.body)
                    if (error?.error) {
                        alert(`${error.error}`)
                        return
                    }
                } catch {
                    alert("There was an issue with this request. Please contact your foreman for a new link.")
                }
                dispatch(employeeWorkShiftsChanged([]))
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}

export const postSignedEmployeeWorkshifts = (ewsId, combinedKey, form_data, csrftoken, skipClear = false) => {
    return dispatch => {
        const resource = `sign/${ewsId}:${combinedKey}`
        dispatch(startLoader())
        API.sendFormData(
            form_data,
            resource,
            "POST",
            4,
            { "X-CSRFToken": csrftoken },
            { credentials: "same-origin" }
        )
            .then(response => {
                // the old signature needed ews cleared to refresh properly
                // we want to keep them in place for new sig for the summary screen
                if (!skipClear) {
                    return { results: [] }
                }
                // 400s were not being caught with a catch, so only process json response if 200
                if (response.status === 200 || !getFlagEnabled("WA-7683-sig-errors")) {
                    return response.json()
                }
                // if error, return an empty list, the UI will show an error message if hasSigned = true but no ews
                return { results: [] }
            })
            .then(data => {
                dispatch(employeeWorkShiftsChanged(data?.results))
            })
            .finally(() => dispatch(stopLoader()))
    }
}

export function work_shifts_changed(data) {
    return {
        type: "WORK_SHIFTS_CHANGED",
        data: data,
    }
}

export function query_work_shifts(startDate = null, endDate = null, eid = null, groupId = null) {
    return (dispatch, getState) => {
        startDate = startDate || getState().filters.startDate
        endDate = endDate || getState().filters.endDate
        eid = eid || getState().employee_id_filter
        dispatch(startLoader())
        const data = {
            start_date: format(getAsDate(startDate), STORED_DATE_ONLY_FORMAT),
            end_date: format(getAsDate(endDate), STORED_DATE_ONLY_FORMAT),
            project_id: getState().filters.projectId || "",
            eid: eid || "",
            ...(getFlagEnabled("WA-7921-fix-daily-view-group-filter") && groupId ? { group_id: groupId } : {}),
        }

        return makeRequest({
            url: `/api/v3/work_shifts/?${Rmbx.util.serializeQueryParams(data)}`,
            method: "GET",
        })
            .then(returnedData => {
                dispatch(work_shifts_changed(returnedData))
            })
            .finally(() => {
                dispatch(stopLoader())
            })
    }
}
// endregion

// region API TOKEN INTEGRATION
export const beginApiTokenLoading = () => {
    return {
        type: "START_API_INTEGRATION_LOADING_INDICATOR",
        isLoadingIndicatorShown: true,
    }
}

export const endApiTokenLoading = () => {
    return {
        type: "STOP_API_INTEGRATION_LOADING_INDICATOR",
        isLoadingIndicatorShown: false,
    }
}

export const apiIntegrationTokenNameChanged = data => {
    return {
        type: "API_INTEGRATION_TOKEN_NAME_CHANGED",
        data,
    }
}

export const tokenIntegrationApiFailure = (response, textStatus) => {
    return {
        type: "TOKEN_INTEGRATION_API_FAILURE",
        response,
        textStatus,
    }
}

export const updateOperatingTokenValues = (tokenValue, tokenValueErrors) => {
    return {
        type: "UPDATE_OPERATING_TOKEN_VALUES",
        tokenValue,
        tokenValueErrors,
    }
}

export const createApiTokenIntegrationName = (integrationName, coId, empId) => {
    return dispatch => {
        dispatch(beginApiTokenLoading())
        return API.createApiTokenIntegrationName(integrationName, coId, empId)
            .catch(error => {
                if (error.response) {
                    dispatch(tokenIntegrationApiFailure(error.response, error.response.statusText))
                }
            })
            .finally(() => {
                dispatch(getApiTokenIntegrationName())
                dispatch(endApiTokenLoading())
            })
    }
}

export const getApiTokenIntegrationName = () => {
    return dispatch => {
        dispatch(beginApiTokenLoading())
        return API.getApiIntegrationName()
            .then(data => {
                dispatch(apiIntegrationTokenNameChanged(data))
            })
            .catch(error => {
                if (error.response) {
                    dispatch(tokenIntegrationApiFailure(error.response, error.response.statusText))
                }
            })
            .finally(() => {
                dispatch(endApiTokenLoading())
            })
    }
}

export const deactivateIntegrationToken = id => {
    return dispatch => {
        dispatch(beginApiTokenLoading())
        return API.deactivateIntegrationToken(id)
            .then(() => {
                dispatch(getApiTokenIntegrationName())
            })
            .catch(error => {
                if (error.response) {
                    dispatch(tokenIntegrationApiFailure(error.response, error.response.statusText))
                }
            })
            .finally(() => {
                dispatch(endApiTokenLoading())
            })
    }
}
// endregion

// Field Form store updates
export const triggerCustomFromStoreUpdate = data => {
    return {
        type: "TRIGGER_CF_STORE_UPDATE",
        data,
    }
}
// end region

export function clearMaterialCsvData() {
    return {
        type: "CLEAR_MATERIAL_CSV_DATA",
    }
}

export function fetchProjectMaterialsSuccess(response) {
    return {
        type: "FETCH_PROJECT_MATERIALS_SUCCESS",
        payload: { projectMaterials: response.results },
    }
}

export function fetchProjectMaterialsFailed(error) {
    return {
        type: "FETCH_PROJECT_MATERIALS_FAILED",
        payload: { error: error },
    }
}

export function fetchProjectMaterials(projectId) {
    return dispatch =>
        API.getProjectMaterials(projectId)
            .then(data => {
                dispatch(fetchProjectMaterialsSuccess(data))
            })
            .catch(error => {
                dispatch(fetchProjectMaterialsFailed(error))
            })
}

export function fetchMaterialsSuccess(response) {
    return {
        type: "FETCH_MATERIALS_SUCCESS",
        payload: { materials: response.results },
    }
}

export function fetchMaterialsFailed(error) {
    return {
        type: "FETCH_MATERIALS_FAILED",
        payload: { error: error },
    }
}

export function fetchMaterials() {
    return dispatch =>
        API.getMaterials()
            .then(data => {
                dispatch(fetchMaterialsSuccess(data))
            })
            .catch(error => {
                dispatch(fetchMaterialsFailed(error))
            })
}

export function fetchGuestFormSharesSuccess(response) {
    return {
        type: "FETCH_GUEST_FORM_SHARES_SUCCESS",
        payload: { guestFormShares: response.results },
    }
}

export function fetchGuestFormSharesFailed(error) {
    return {
        type: "FETCH_GUEST_FORM_SHARES_FAILED",
        payload: { error: error },
    }
}

export function fetchGuestFormShares() {
    return dispatch =>
        API.getGuestFormShares()
            .then(data => {
                dispatch(fetchGuestFormSharesSuccess(data))
            })
            .catch(error => {
                dispatch(fetchGuestFormSharesFailed(error))
            })
}

export function fetchCompanyFormStoresSuccess(response) {
    return {
        type: "FETCH_COMPANY_FORM_STORES_SUCCESS",
        payload: { companyFormStores: response.results },
    }
}

export function fetchCompanyFormStoresFailed(error) {
    return {
        type: "FETCH_COMPANY_FORM_STORES_FAILED",
        payload: { error: error },
    }
}

export function fetchCompanyFormStores() {
    return dispatch =>
        API.getCompanyFormStores()
            .then(data => {
                dispatch(fetchCompanyFormStoresSuccess(data))
            })
            .catch(error => {
                dispatch(fetchCompanyFormStoresFailed(error))
            })
}

export const createLookerURLSuccessful = data => {
    return {
        type: "LOOKER_URL_SUCCESSFUL",
        data,
    }
}

export const lookerURLCleared = () => {
    return {
        type: "LOOKER_URL_CLEARED",
    }
}

export const createLookerURLFailed = () => {
    return {
        type: "LOOKER_URL_FAILED",
    }
}

export const getLookerURL = element => {
    return dispatch =>
        API.createLookerURL(element)
            .then(data => {
                dispatch(createLookerURLSuccessful({ ...data, looker_element: element.element }))
            })
            .catch(() => {
                dispatch(createLookerURLFailed())
            })
}

export const clearLookerURL = () => {
    return dispatch => dispatch(lookerURLCleared())
}

export function fetchCompanyAbsenceTypesSuccess(response) {
    return {
        type: "FETCH_COMPANY_ABSENCE_TYPES_SUCCESS",
        payload: { companyAbsenceTypes: response.results },
    }
}

export function fetchCompanyAbsenceTypesFailure(error) {
    return {
        type: "FETCH_COMPANY_ABSENCE_TYPES_FAILED",
        payload: { error },
    }
}

export function fetchCompanyAbsenceTypes() {
    return dispatch => {
        API.getV4Resources("companyAbsenceTypes")
            .then(data => {
                dispatch(fetchCompanyAbsenceTypesSuccess(data))
            })
            .catch(error => {
                dispatch(fetchCompanyAbsenceTypesFailure(error))
            })
    }
}

export function fetchCompanyStartStopTypesSuccess(response) {
    return {
        type: "FETCH_COMPANY_START_STOP_TYPES_SUCCESS",
        payload: { companyStartStopTypes: response.results },
    }
}

export function fetchCompanyStartStopTypesFailure(error) {
    return {
        type: "FETCH_COMPANY_START_STOP_TYPES_FAILED",
        payload: { error },
    }
}

export function fetchCompanyStartStopTypes() {
    return dispatch => {
        API.getV4Resources("companyStartStopTypes")
            .then(data => {
                dispatch(fetchCompanyStartStopTypesSuccess(data))
            })
            .catch(error => {
                dispatch(fetchCompanyStartStopTypesFailure(error))
            })
    }
}

export const togglePlaceholdersVisible = () => {
    return {
        type: "TOGGLE_PLACEHOLDERS_VISIBLE",
    }
}
