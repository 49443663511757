import {
    integerColDefCreator,
    statusColDefCreator,
    stringColDefCreator,
    modifyButtonColDefCreator,
    signaturePeriodColDefCreator,
} from "./standard-col-def-creators"

import { companyGroupsFilterDef, projectStatusFilterDef, multiProjectFilterDef } from "../../../filters/filter-defs"
import { projectDashboardUploadRoutePath } from "../../../router/constants"

export function getProjectsListViewSettings(featureFlags) {
    const hide_groups = !featureFlags.groups
    const hide_equipment = !featureFlags.equipment
    const hide_materials = !featureFlags.materials

    return {
        tableName: "Projects",
        navId: "projects",
        resources: ["projects"],
        filters: [companyGroupsFilterDef, projectStatusFilterDef, multiProjectFilterDef],
        additionalQueryParams: { expanded_annotations: true },
        colDefs: [
            stringColDefCreator({
                headerName: "Job #",
                field: "/job_number",
                sort: "desc",
            }),
            stringColDefCreator({
                headerName: "Project Name",
                field: "/name",
            }),
            stringColDefCreator({
                headerName: "Address",
                field: "/address",
                width: 320,
            }),
            stringColDefCreator({
                headerName: "Group",
                field: "/group_name",
                hide: hide_groups,
            }),
            statusColDefCreator(),
            stringColDefCreator({
                headerName: "Client",
                field: "/client_name",
            }),
            signaturePeriodColDefCreator(),
            integerColDefCreator({
                headerName: "Team Members",
                field: "/employees_count",
            }),
            integerColDefCreator({
                headerName: "Cost Codes",
                field: "/cost_codes_count",
            }),
            integerColDefCreator({
                headerName: "Equipment",
                field: "/equipment_count",
                hide: hide_equipment,
            }),
            integerColDefCreator({
                headerName: "Materials",
                field: "/materials_count",
                hide: hide_materials,
            }),
            modifyButtonColDefCreator({
                headerName: "Settings",
                field: "/settings",
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
            },
            // SSRM Settings
            rowModelType: "serverSide",
            serverSideStoreType: "partial",
            rowSelection: "multiple",
            cacheBlockSize: 100,
            suppressMultiSort: true,
        },
        otherSettings: {
            buttons: {
                table: [
                    {
                        label: "Create Project",
                        icon: "add",
                        action: "addProject",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: projectDashboardUploadRoutePath,
                        },
                    },
                ],
            },
            enableSearchBar: true,
            updateOnSave: true,
        },
    }
}
