import { booleanColDefCreator, checkboxColDefCreator, stringColDefCreator } from "./standard-col-def-creators"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"

export const getMaterialSettings = () => {
    return {
        tableName: "Materials",
        navId: "materials",
        resources: ["materials"],
        filters: [],
        colDefs: [
            checkboxColDefCreator(),
            stringColDefCreator({
                headerName: "Material*",
                field: "/name",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
            }),
            stringColDefCreator({
                headerName: "Units*",
                field: "/units",
                required: true,
            }),
            stringColDefCreator({
                headerName: "Part #",
                field: "/part_number",
            }),
            stringColDefCreator({
                headerName: "Group",
                field: "/group",
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: true,
                    default: true,
                }),
                valueFormatter: isActiveValueFormatter,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Materials",
            subcontent:
                "Material includes any building materials used at the company. " +
                "Add materials by uploading a .CSV file or manually typing in the material information.",
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedCell",
                    },
                ],
                row: [
                    {
                        label: "Activate",
                        icon: "view",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: true,
                        },
                    },
                    {
                        label: "Deactivate",
                        icon: "hide",
                        action: "bulkUpdateField",
                        args: {
                            field: "is_active",
                            value: false,
                        },
                    },
                    { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/company-admin/materials/upload/",
                        },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }
}
