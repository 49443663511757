import { loadAllEntities } from "../../../cached-data/actions"
import { employeeRoleValueSetter, phoneValueSetter } from "../../../common/ag-grid-value-setters"

import {
    phoneNumberFormatter,
    titleCaseFormatter,
    filterFormatter,
    isActiveValueFormatter,
    dateTimeValueFormatter,
    declinableBooleanValueFormatter,
} from "../../../common/ag-grid-value-formatters"
import { getFlagEnabled } from "../../../getFlagValue"
import { EMPLOYEE_ROLE_DEFAULT_OPTION, EMPLOYEE_ROLE_OPTIONS } from "../../../common/constants"
import { isValidEmail, isLowercaseEmail } from "../../../common/validators"
import { publicApiControlsTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import {
    checkboxColDefCreator,
    stringColDefCreator,
    booleanColDefCreator,
    employeeGroupsColDefCreator,
    equipmentColDefCreator,
    datetimeColDefCreator,
    companyCrewTypeColDefCreator,
    picklistColDefCreator,
    employeeCohortsColDefCreator,
} from "./standard-col-def-creators"
import { getValueFormatter } from "../../../common/ag-grid-value-formatters"
import {
    companyGroupsFilterDef,
    employeeRoleFilterDef,
    employeeStatusFilterDef,
} from "../../../filters/filter-defs"

export function getEmployeesSettings(featureFlags) {
    const is_editable = featureFlags && !featureFlags.read_only_employees
    const cellClasses = is_editable ? [] : ["readonly"]
    const showLaborType = featureFlags.cost_code_controls
    const pinned = getFlagEnabled("WA-8305-freeze-employee-columns") ? { pinned: "left" } : {}

    return {
        tableName: "Employees",
        navId: "employees",
        resources: ["employees"],
        relatedNames: {
            employees: {
                equipment: ["/default_equipment"],
            },
        },
        initAction: () => dispatch => {
            // Load the groups so that we can selectively access and assign users
            // to the Main group
            dispatch(loadAllEntities("companyGroups"))
        },
        filters: getFlagEnabled("WA-7860-employee-page-filters")
            ? [companyGroupsFilterDef, employeeRoleFilterDef, employeeStatusFilterDef]
            : [],
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            ...(is_editable
                ? [
                      checkboxColDefCreator({
                          headerCheckboxSelection: false,
                          ...pinned,
                      }),
                  ]
                : []),
            stringColDefCreator({
                headerName: "Employee ID*",
                field: "/company_supplied_id",
                headerTooltip: "Your internal company id",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "First Name*",
                field: "/first_name",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "Last Name*",
                field: "/last_name",
                required: true,
                ...pinned,
            }),
            {
                ...stringColDefCreator({
                    headerName: "Role*",
                    field: "/user_role",
                    width: 120,
                    enum: EMPLOYEE_ROLE_OPTIONS,
                    default: EMPLOYEE_ROLE_DEFAULT_OPTION,
                    // we always want this to be editable, even if nothing else is
                    editable: true,
                    required: true,
                }),
                valueFormatter: titleCaseFormatter,
                valueSetter: employeeRoleValueSetter,
                customTooltip: undefined,
                cellClass: [],
            },
            {
                ...stringColDefCreator({
                    headerName: "Mobile Phone",
                    field: "/phone",
                    headerTooltip: "Required for Foremen",
                }),
                valueFormatter: phoneNumberFormatter,
                valueSetter: phoneValueSetter,
            },
            {
                ...stringColDefCreator({
                    headerName: "Email",
                    field: "/email",
                    width: 230,
                    headerTooltip: "Required for roles other than Worker",
                }),
                cellEditorParams: {
                    validators: [isValidEmail, isLowercaseEmail],
                },
            },
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
            }),
            stringColDefCreator({
                headerName: "Trade",
                field: "/trade",
            }),
            ...(featureFlags.cost_code_level_modifiers
                ? [
                      equipmentColDefCreator({
                          field: "/default_equipment",
                          required: false,
                          editable: false,
                          excludeFromExport: true,
                      }),
                  ]
                : []),
            ...(getFlagEnabled("WA-7923-assign-ccc-labor-type")
                ? [
                      companyCrewTypeColDefCreator({
                          alphabetize: true,
                          hide: !showLaborType,
                      }),
                  ]
                : [
                      stringColDefCreator({
                          headerName: "Labor Types",
                          editable: false,
                          hide: !showLaborType,
                          valueFormatter: getValueFormatter(["/name"], ", "),
                          field: "/crew_types",
                      }),
                  ]),

            picklistColDefCreator("Certifications", "/certifications", "Certification"),
            picklistColDefCreator("Licenses", "/licenses", "License"),
            picklistColDefCreator("Unions", "/unions", "Union"),
            employeeGroupsColDefCreator({
                groupsCompanyFeatureToggleActive: featureFlags?.groups,
                excludeFromExport: !featureFlags?.groups,
                alphabetize: true,
            }),
            ...(getFlagEnabled("WA-7989-employee-cohorts") ? [{ ...employeeCohortsColDefCreator({}) }] : []),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: false,
                    default: true,
                    uneditableMessage: "Select checkbox beside Employee ID to update this field",
                }),
                valueFormatter: isActiveValueFormatter,
            },

            {
                ...stringColDefCreator({
                    headerName: "Disabled Status",
                    field: "/disabled_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    editable: true,
                    required: false,
                }),
            },
            {
                ...stringColDefCreator({
                    headerName: "Veteran Status",
                    field: "/veteran_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    editable: true,
                    required: false,
                }),
            },
            datetimeColDefCreator({
                headerName: "Created On",
                editable: false,
                field: "/created_on",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
            datetimeColDefCreator({
                headerName: "Last Active",
                editable: false,
                field: "/last_active",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                cellClass: cellClasses,
                customTooltip: publicApiControlsTooltip,
                editable: is_editable,
                getQuickFilterText: filterFormatter,
            },
            // allow our click events to happen
            suppressRowClickSelection: true,
            rowSelection: "multiple",
            // SSRM settings
            rowModelType: "serverSide",
            serverSideStoreType: "partial",
            cacheBlockSize: 100,
        },
        otherSettings: {
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            hiddenColumnDefaults: {
                project: "projectId",
            },
            buttons: {
                cell: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Edit",
                                  icon: "edit",
                                  action: "editFocusedCell",
                              },
                              {
                                  label: "Copy",
                                  icon: "copy",
                                  action: "copyFocusedCell",
                              },
                              {
                                  label: "Paste",
                                  icon: "paste",
                                  action: "pasteIntoFocusedCell",
                              },
                          ]
                        : []),
                ],
                row: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Activate",
                                  icon: "view",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: true,
                                  },
                              },
                              {
                                  label: "Deactivate",
                                  icon: "hide",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: false,
                                  },
                              },
                              { separator: true },
                              { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                          ]
                        : []),
                ],
                table: [
                    ...(is_editable
                        ? [
                              { label: "Add Row", icon: "add", action: "addNewRow" },
                              {
                                  label: "Upload",
                                  icon: "upload",
                                  action: "navigateTo",
                                  args: { url: "/rhumbix/company-admin/users/upload/" },
                              },
                          ]
                        : []),
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
            rowLevelValidators: [
                data => {
                    if (
                        (data.email == null || data.email.trim().length === 0) &&
                        ["ADMIN", "OFFICE_STAFF", "PAYROLL_ADMIN", "PM", "FOREMAN"].includes(data.user_role)
                    ) {
                        data.errors["/email"] = ["This role requires an email address"]
                    }
                    if (
                        (data.phone == null || data.phone.trim().length === 0) &&
                        ["FOREMAN"].indexOf(data.user_role) > -1
                    ) {
                        data.errors["/phone"] = ["This role requires a mobile phone number"]
                    }
                    return data
                },
            ],
        },
    }
}
