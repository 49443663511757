import {
    colorAttentionBlue50,
    colorBrandRhumbixBlack,
    colorBrandRhumbixYellow,
    colorFunctionalGray70,
    colorFunctionalWhite,
} from "@rhumbix/rmbx_design_system_web"
import { IRowNode } from "ag-grid-community"
import { cellEventHandlers, rowEventHandlers, tableEventHandlers } from "../event-handlers"
import {
    eToolbarMode,
    tToolbarProps,
    tButtonParams,
    tExtraButtonParams,
    tToolbarEventHandlers,
    ToolbarButtonSeparator,
} from "../types"
import { getFlagEnabled } from "../../getFlagValue"

export const autoRowSelectionDisabled = (props: tToolbarProps): boolean =>
    !!props.context.settings.gridSettings.suppressRowClickSelection ||
    !!props.context.settings.gridSettings.pivotMode

export const getButtons = (
    props: tToolbarProps,
    mode: eToolbarMode
): (tButtonParams | ToolbarButtonSeparator)[] => {
    const settings = props.context.settings
    const buttonSettings =
        settings.otherSettings && settings.otherSettings.buttons ? settings.otherSettings.buttons : {}

    let buttons: tButtonParams[] = buttonSettings.table || []
    if (mode === eToolbarMode.CELL) {
        buttons = buttonSettings.cell || []
    } else if (mode === eToolbarMode.ROW) {
        buttons = buttonSettings.row || []
    } else if (mode === eToolbarMode.GROUP_ROW) {
        buttons = buttonSettings.groupRow || []
    }

    return buttons.filter(
        b => !(getFlagEnabled("WA-7940-hide-history-checkbox") && b.hideInHistory && props.isAudit)
    )
}

export const getEventHandlers = (
    mode: eToolbarMode,
    extraButtonParams: tExtraButtonParams
): tToolbarEventHandlers => {
    if (mode === eToolbarMode.CELL) {
        return cellEventHandlers(extraButtonParams)
    } else if (mode === eToolbarMode.ROW || mode === eToolbarMode.GROUP_ROW) {
        return rowEventHandlers(extraButtonParams)
    } else {
        return tableEventHandlers(extraButtonParams)
    }
}

export const getToolbarMode = (props: tToolbarProps): eToolbarMode => {
    const settings = props.context.settings
    const buttons = settings.otherSettings && settings.otherSettings.buttons ? settings.otherSettings.buttons : {}
    const hasRowButton = !!buttons.row && buttons.row.length
    const hasCellButton = !!buttons.cell && buttons.cell.length
    const hasGroupRowButton = !!buttons.groupRow && buttons.groupRow.length

    let mode = eToolbarMode.TABLE
    if (!!props.context.selectedRows?.length && hasRowButton) {
        // At least one row is selected and there's at least one row button
        mode = eToolbarMode.ROW
    } else if (
        props.context.focusedCellIsActionable &&
        autoRowSelectionDisabled(props) &&
        hasCellButton &&
        props.context.gridApi?.getFocusedCell()
    ) {
        // An actionable cell is selected AND the grid is not about to auto-select
        // its row (which would cause flickering) AND there's at least one cell button
        mode = eToolbarMode.CELL
    } else if (
        props.gridApi.getSelectedNodes().filter((node: IRowNode) => node.group).length > 0 &&
        hasGroupRowButton
    ) {
        // Selected group rows do not come back through getSelectedRows(), but can be found by calling
        // getSelectedNodes and looking for nodes with group === true
        mode = eToolbarMode.GROUP_ROW
    }
    return mode
}

export const getToolbarMessage = (props: tToolbarProps, mode: eToolbarMode): string => {
    const canPaste =
        props.context.focusedCellIsActionable &&
        (Object.keys(props.pivotClipboard).length || props.clipboard.length)

    if (mode === eToolbarMode.CELL) {
        return `1 cell ${canPaste ? "copied" : "selected"}`
    } else if (mode === eToolbarMode.ROW) {
        const numRows = props.context.selectedRows.length
        return `${numRows} row${numRows > 1 ? "s" : ""} selected`
    } else if (mode === eToolbarMode.GROUP_ROW) {
        // Use getSelectedNodes() to find selected group rows
        const numRows = props.gridApi.getSelectedNodes().filter((node: IRowNode) => node.group).length
        return `${numRows} row${numRows > 1 ? "s" : ""} selected`
    }

    return ""
}

export const getBackgroundColor = (props: tToolbarProps): string => {
    switch (getToolbarMode(props)) {
        case eToolbarMode.ROW:
        case eToolbarMode.GROUP_ROW:
            return colorAttentionBlue50
        case eToolbarMode.CELL:
            return colorBrandRhumbixYellow
        default:
            return colorFunctionalWhite
    }
}

export const getTextColor = (props: tToolbarProps): string => {
    switch (getToolbarMode(props)) {
        case eToolbarMode.ROW:
        case eToolbarMode.GROUP_ROW:
            return colorFunctionalWhite
        case eToolbarMode.CELL:
            return colorBrandRhumbixBlack
        default:
            return colorFunctionalGray70
    }
}
