import { getStringValueFormatter, getValueFormatter } from "./ag-grid-value-formatters"
import { tReferenceablesToValueFormatters } from "./types"
import { DATE_ONLY_FORMAT, TIME_FORMAT } from "./constants"
import { tSelectorOption } from "../SelectorWrapper/types"
import { getAsDate } from "./ts-utils"
import { format } from "date-fns"
import { JsonPointer as jsonpointer } from "json-ptr"

export const referenceablesToValueFormatters: tReferenceablesToValueFormatters = {
    cohorts: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    companyAbsenceTypes: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    companyFormStores: {
        valueFormatter: getValueFormatter(["/form_num"]),
        titleFormatter: getValueFormatter(["/form_num"]),
    },
    companyGroups: {
        valueFormatter: getValueFormatter(["/name"]),
        prefixFormatter: getValueFormatter(["/parent_name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    costCodes: {
        valueFormatter: getValueFormatter(
            ["/project/job_number", "/code", "/description"],
            " - ",
            "",
            "",
            (value: Record<string, any>) => {
                return (
                    jsonpointer.get(value, "/project/status") === "INACTIVE" ||
                    jsonpointer.get(value, "/is_active") === false
                )
            }
        ),
        titleFormatter: getValueFormatter(["/code", "/description"], " \u2022 "),
        primarySubtitleFormatter: getValueFormatter(["/project/job_number", "/project/name"], " \u2022 "),
        titleAndStatusFormatter: getValueFormatter(
            ["/code", "/description"],
            " \u2022 ",
            "",
            "",
            (value: Record<string, any>) => jsonpointer.get(value, "/project/status") === "INACTIVE"
        ),
    },
    costCodeControls: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    companyCrewTypes: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    companyStartStopTypes: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    costItems: {
        valueFormatter: getValueFormatter(["/change_order_name"]),
        titleFormatter: getValueFormatter(["/change_order_name"]),
        primarySubtitleFormatter: getValueFormatter(["/change_order_number"]),
    },
    changeOrders: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/number"]),
    },
    employees: {
        valueFormatter: getValueFormatter(
            ["/last_name", "/first_name"],
            ", ",
            "",
            "",
            (value: Record<string, any>) => {
                return jsonpointer.get(value, "/is_active") === false
            }
        ),
        titleFormatter: getValueFormatter(
            ["/last_name", "/first_name"],
            ", ",
            "",
            "",
            (value: Record<string, any>) => jsonpointer.get(value, "/is_active") === false
        ),
        primarySubtitleFormatter: getValueFormatter(["/company_supplied_id"], "", "ID#: "),
        secondarySubtitleFormatter: getValueFormatter(["/trade", "/classification"], " \u2022 "),
        tertiarySubtitleFormatter: getValueFormatter(["/default_equipment"]),
    },
    employeeClassifications: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    employeeTrades: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    equipment: {
        valueFormatter: getValueFormatter(["/equipment_id", "/description"], " - "),
        titleFormatter: getValueFormatter(["/equipment_id"]),
        primarySubtitleFormatter: getValueFormatter(["/description"]),
        secondarySubtitleFormatter: getValueFormatter(["/category"]),
    },
    guestFormShares: {
        valueFormatter: getValueFormatter(["/form", "/guest"]),
        titleFormatter: getValueFormatter(["/form", "/guest"]),
    },
    materials: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/description"]),
    },
    picklistItems: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/code"]),
    },
    projectMaterials: {
        valueFormatter: getValueFormatter(["/material/name"]),
        titleFormatter: getValueFormatter(["/material/name"]),
        primarySubtitleFormatter: getValueFormatter(["/material/description"]),
    },
    projects: {
        valueFormatter: getValueFormatter(
            ["/job_number", "/name"],
            " \u2022 ",
            "",
            "",
            (value: Record<string, any>) => jsonpointer.get(value, "/status") === "INACTIVE"
        ),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/job_number"]),
        titleAndStatusFormatter: getValueFormatter(
            ["/name"],
            " \u2022 ",
            "",
            "",
            (value: Record<string, any>) => jsonpointer.get(value, "/status") === "INACTIVE"
        ),
    },
    projectShares: {
        valueFormatter: getValueFormatter(["/sub_company_name"], ", "),
        titleFormatter: getValueFormatter(["/sub_company_name"], ", "),
    },
    companies: {
        valueFormatter: getValueFormatter(["/company_name"]),
        titleFormatter: getValueFormatter(["/company_name"]),
    },
    schemaStatusNames: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
    },
    analyticsDashboards: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/description"]),
    },
    companyTrades: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/code"]),
    },
    companyClassifications: {
        valueFormatter: getValueFormatter(["/name"]),
        titleFormatter: getValueFormatter(["/name"]),
        primarySubtitleFormatter: getValueFormatter(["/code"]),
    },
    workShifts: {
        valueFormatter: getValueFormatter(["/id"], "", "Time Card ID #"),
        titleFormatter: getValueFormatter(["/id"], "", "Time Card ID #"),
        primarySubtitleFormatter: getValueFormatter(["/start_time", "/end_time"], " - ", "", TIME_FORMAT),
        secondarySubtitleFormatter: getValueFormatter(["/start_time"], "", "", DATE_ONLY_FORMAT),
    },
    timeCards: {
        valueFormatter: getStringValueFormatter("ID #{0} \u2022 {1}, {2}", [
            "/id",
            "/foreman/last_name",
            "/foreman/first_name",
        ]),
        titleFormatter: getStringValueFormatter("ID #{0} \u2022 {1}, {2}", [
            "/id",
            "/foreman/last_name",
            "/foreman/first_name",
        ]),
        primarySubtitleFormatter: getStringValueFormatter("{0} \u2022 {1} - {2}", [
            { value: "/start_time", dateFormat: DATE_ONLY_FORMAT },
            { value: "/start_time", dateFormat: TIME_FORMAT },
            { value: "/end_time", dateFormat: TIME_FORMAT },
        ]),
    },
    timekeepingStatuses: {
        valueFormatter: getValueFormatter(["/label"]),
        titleFormatter: getValueFormatter(["/label"]),
    },
}

///////////////////////////////////////////////////////////////////////////////
// Detailed content formatters
// The selector can support more flexible content using certain selector components, such as
// TitleAndDetailsSelectorOption which take a cusomContent parameter. Instead of the usual set of
// valueFormatters that can be used one-per-line, these detailed formatters build the content
// in a custom way and deliver as many lines of content as the design calls for

export const detailedContentForWorkShifts: any = (option: tSelectorOption, projectsSelector: any) => {
    if (!option || !option.data) return {}

    const workerIds: number[] = []
    const projectIds: number[] = []
    const projectNames: string[] = []

    // Work shifts only carry the IDs of projects. Find matching
    // entities or record which ones need to be loaded
    option.data.employee_work_shifts?.forEach((shift: Record<string, any>) => {
        if (!workerIds.find(id => id === shift.employee)) workerIds.push(shift.employee)
        shift.work_components?.forEach((wc: Record<string, any>) => {
            if (!projectIds.find(id => id === wc.project)) projectIds.push(wc.project)
        })
    })

    // Some projects may not have loaded yet, but loading them after the fact and
    // getting them into the selector (and its cached options) is a beast - simpler and
    // more performant just to let it go
    projectIds.forEach(id => {
        if (projectsSelector[id]) projectNames.push(projectsSelector[id].name)
    })

    // Build the option line by line
    const title = `ID #${option.data.id} ${
        option.data.foreman ? `\u2022  ${option.data.foreman.last_name}, ${option.data.foreman.first_name}` : ""
    }`

    const details: string[] = []
    details.push(
        `${format(getAsDate(option.data.start_time), DATE_ONLY_FORMAT)} \u2022 ${format(
            getAsDate(option.data.start_time),
            TIME_FORMAT
        )} - ${format(getAsDate(option.data.end_time), TIME_FORMAT)}`
    )

    if (workerIds.length > 0) details.push(`${workerIds.length} Worker${workerIds.length > 1 ? "s" : ""}`)
    if (projectNames.length > 0) details.push(projectNames.join(", "))

    return { value: option.value, customContent: { title, details }, data: option.data }
}

export const detailedContentForCustomFormStores: any = (option: tSelectorOption) => {
    if (!option || !option.data) return {}

    const title = `${option.data.id} ${option.data.schema_name} ${
        option.data.form_num ? `#${option.data.form_num}` : ""
    }`
    const details: string[] = []

    details.push(
        `${format(getAsDate(option.data.created_on), DATE_ONLY_FORMAT)} \u2022 ${option.data.project_name}`
    )
    details.push(option.data.status)
    details.push(`Created By: ${option.data.employee.first_name} ${option.data.employee.last_name}`)

    return { value: option.value, customContent: { title, details }, data: option.data }
}
