import React, { useContext, useEffect } from "react"

/** Components */
import { SideRailContext } from "../../SideRail/SideRailContext"
import { ReduxState, tResourceName } from "../../../common/types"
import { getFlagEnabled } from "../../../getFlagValue"
import { connect } from "react-redux"
import { tResourceObject, tSourceData } from "../../../dashboard-data/types"

/** Types */
type Props = {
    data: Record<string, any>
    style: Record<string, string>
    config: Record<string, any>
    sideRailSettings: Record<string, any>
    sourceData: tSourceData
    openRemapModal?: (params: Record<string, any>) => void
}

export const RightRailButtonCellRenderer: React.FC<Props> = props => {
    const { enableSideRail } = useContext(SideRailContext)

    const openRightRail = () => {
        props.data.onClick?.()
        if (getFlagEnabled("WA-7989-employee-cohorts")) {
            // props.data gets stale if we have just created the row, so we need to find
            // the current row data in the state.
            const sourceObject = props.sourceData[props.data.dataType as tResourceName]?.find(
                (d: tResourceObject) => d.gridId === props.data.gridId
            )
            const title =
                typeof props.sideRailSettings?.title === "function"
                    ? props.sideRailSettings?.title(sourceObject)
                    : props.sideRailSettings?.title || props.data.tableName
            const primarySubtitle =
                typeof props.sideRailSettings?.primarySubtitle === "function"
                    ? props.sideRailSettings?.primarySubtitle(sourceObject)
                    : props.sideRailSettings?.primarySubtitle
            const secondarySubtitle =
                typeof props.sideRailSettings?.secondarySubtitle === "function"
                    ? props.sideRailSettings?.secondarySubtitle(sourceObject)
                    : props.sideRailSettings?.secondarySubtitle
            enableSideRail({
                flow: "DATA_TABLE",
                config: {
                    useBasicHeader: true,
                    headerIcon: props.data.icon,
                    rowData: sourceObject || {},
                    ...props.config,
                },
                filters: [],
                resource: props.data.resource as tResourceName,
                ...props.sideRailSettings,
                primarySubtitle,
                secondarySubtitle,
                title,
                openRemapModal: (params: Record<string, any>) => {
                    if (!props.openRemapModal) return
                    props.openRemapModal(params)
                },
            })
        } else
            enableSideRail({
                flow: "DATA_TABLE",
                config: {
                    useBasicHeader: true,
                    headerIcon: props.data.icon,
                },
                filters: [],
                resource: props.data.resource as tResourceName,
                title: props.data.tableName,
            })
    }

    useEffect(() => {
        if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
            if (props.data.autoOpen) openRightRail()
        }
    }, [])

    return (
        <button
            onClick={openRightRail}
            id={props.data.buttonId}
            type="button"
            className="action-button"
            style={{ marginTop: "2px" }}
        >
            <div style={{ ...props.style, marginTop: "-5px" }}>Modify</div>
        </button>
    )
}

const mapStateToProps = (state: ReduxState) => {
    return {
        sourceData: state.sourceData.sourceData,
    }
}

export default connect(mapStateToProps)(RightRailButtonCellRenderer)
