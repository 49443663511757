import { TableIds } from "../../../common/table-identifier-utils"
import {
    checkboxColDefCreator,
    costCodeColDefCreator,
    dateColDefCreator,
    editableTimeCardColDefCreator,
    employeeColDefCreator,
    foremanColDefCreator,
    hoursFromMinutesColDefCreator,
    lengthLimitedColDefCreator,
    signatureActionsColDefCreator,
    stringColDefCreator,
    timekeepingStatusColDefCreator,
} from "./standard-col-def-creators"
import { dateAndDefaultTimeValueSetter } from "../../../common/ag-grid-value-setters"
import {
    addEmployeeRowsTableAction,
    addRowTableAction,
    alertCellClassRule,
    editTimecardDetailsAction,
    getAvailableStatusValues,
    isGroupRow,
    timekeepingDataIsEditable,
    timekeepingRowIsEditable,
} from "../../../common/ag-grid-utils"
import GroupRowFormattingDefaultCellRenderer from "../cell-renderers/GroupRowFormattingDefaultCellRenderer"
import { createModifierColDefs } from "../../../tk-modifiers/utils"
import { totalHoursValueGetter } from "../../../common/ag-grid-value-getters"
import {
    customSignatureTooltip,
    timekeepingCommentTooltip,
    timekeepingEntryGeneralErrorsTooltip,
    timekeepingEntrySignatureActionsTooltip,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import { getFlagEnabled } from "../../../getFlagValue"
import AttachmentsRenderer from "../cell-renderers/attachments-cell-renderer"
import SignatureRenderer from "../cell-renderers/signature-renderer"
import { compareGroupColumns } from "../../../common/ag-grid-comparators"
import { validateTimekeepingEntries } from "../../../common/validators"
import { lockedPeriodCellClassRule } from "../../../common/ag-grid-ts-utils"
import { getSelectorActionButtons } from "./action-button-settings"
import { referenceablesToValueFormatters } from "../../../common/referenceable-value-formatters"

const timekeepingEntriesDefaultColDef = {
    cellClassRules: {
        "period-locked": lockedPeriodCellClassRule,
        readonly: params => !timekeepingDataIsEditable(params).canEdit,
        "alert-cell": alertCellClassRule,
    },
    editable: params => timekeepingDataIsEditable(params).canEdit,
    filter: false,
    customTooltip: timekeepingEntryGeneralErrorsTooltip,
}

export const getTimekeepingEntryTable = (
    featureFlags,
    currentProject,
    user,
    currentProjectIds,
    sourceData,
    equipmentRequired = false
) => {
    const userRole = user ? user.user_role : undefined
    const features = user ? user.features : {}
    const groupByTableAction = {
        label: "Group By",
        action: "groupBy",
        icon: "group",
    }
    return {
        tableName: "Timekeeping",
        id: TableIds.TimekeepingModal,
        resources: ["timekeepingEntries"],
        colDefs: [
            {
                ...dateColDefCreator(),
                valueSetter: dateAndDefaultTimeValueSetter,
            },
            {
                ...checkboxColDefCreator(),
                checkboxSelection: p => !isGroupRow(p),
            },
            {
                ...employeeColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                ignoreRelatedFilters: ["projectId"],
            },
            {
                ...costCodeColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    titleFormatter: referenceablesToValueFormatters.costCodes.titleAndStatusFormatter,
                }),
            },
            ...(getFlagEnabled("WA-7996-timecard-projects")
                ? [
                      {
                          ...editableTimeCardColDefCreator({
                              headerName: "Time Card",
                              required: true,
                              width: 300,
                          }),
                          ignoreRelatedFilters: getFlagEnabled("WA-7996-timecard-projects") ? [] : ["projectId"],
                          extraFilterQueryParams: getFlagEnabled("WA-7996-timecard-projects")
                              ? ["start_date", "foreman_id", "project"]
                              : ["start_date", "foreman_id"],
                          extraSearchFilters: { allow_empty: true },
                          actionOptions: [getSelectorActionButtons().createNewTimecardButton],
                      },
                  ]
                : [
                      {
                          ...foremanColDefCreator({
                              headerName: "Time Card Owner",
                          }),
                          ignoreRelatedFilters: ["projectId", "employeeTrade"],
                      },
                  ]),
            ...(featureFlags.cost_code_level_modifiers
                ? createModifierColDefs(
                      user.company_tk_modifiers ?? [],
                      sourceData?.sourceData,
                      timekeepingEntriesDefaultColDef,
                      equipmentRequired
                  )
                : []),
            {
                ...hoursFromMinutesColDefCreator({
                    headerName: "ST",
                    field: "/adjusted_minutes_st",
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                aggFunc: "sum",
            },
            {
                ...hoursFromMinutesColDefCreator({
                    headerName: "OT",
                    field: "/adjusted_minutes_ot",
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                aggFunc: "sum",
            },
            {
                ...hoursFromMinutesColDefCreator({
                    headerName: "DT",
                    field: "/adjusted_minutes_dt",
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                aggFunc: "sum",
            },
            {
                ...hoursFromMinutesColDefCreator({
                    headerName: "Total",
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                valueGetter: totalHoursValueGetter,
                aggFunc: "sum",
            },
            ...(featureFlags.timekeeping_comments
                ? [
                      {
                          ...lengthLimitedColDefCreator({
                              headerName: "Comment",
                              field: "/comment",
                              maxLength: user.company_options.tkcomment_max_length,
                          }),
                          customTooltip: timekeepingCommentTooltip,
                      },
                  ]
                : []),
            {
                ...timekeepingStatusColDefCreator({
                    enum: getAvailableStatusValues(userRole),
                }),
                cellRenderer: GroupRowFormattingDefaultCellRenderer,
                aggFunc: "statusColumnAggFunc",
            },
            ...(getFlagEnabled("WA-5644-tk-signatures")
                ? [
                      {
                          headerName: "Signature",
                          field: "/signature",
                          width: 110,
                          editable: false,
                          cellRenderer: AttachmentsRenderer("Signature"),
                          sortable: false,
                          suppressSizeToFit: true,
                      },
                  ]
                : [
                      {
                          headerName: "Signature",
                          field: "/signature",
                          width: 110,
                          editable: false,
                          cellRenderer: SignatureRenderer,
                          customTooltip: customSignatureTooltip,
                          sortable: false,
                          suppressSizeToFit: true,
                      },
                  ]),
            ...(getFlagEnabled("WA-5644-tk-signatures") && !features?.disable_web_signature_flow
                ? [
                      {
                          ...signatureActionsColDefCreator({
                              headerName: "Actions",
                              field: "/signature",
                              shouldPreventActionFocus: true,
                          }),
                          customTooltip: timekeepingEntrySignatureActionsTooltip,
                      },
                  ]
                : []),
            ...(getFlagEnabled("WA-7799-tk-tabs-show-errors") && !getFlagEnabled("WA-7996-timecard-projects")
                ? [
                      {
                          ...stringColDefCreator({
                              headerName: "Time Card ID",
                              field: "/work_shift_id",
                              editable: false,
                          }),
                      },
                  ]
                : []),
        ],
        gridSettings: {
            defaultColDef: timekeepingEntriesDefaultColDef,
            autoGroupColumnDef: {
                headerName: "Employee",
                suppressMenu: true,
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                checkboxSelection: p => isGroupRow(p),
                groupSelectsChildren: false,
                minWidth: 250,
            },
            enableRangeSelection: true,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressMultiRangeSelection: true,
            rowHeight: 47,
            isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
        },
        otherSettings: {
            enableClipboard: true,
            pasteRangeEnabled: true,
            filterByLockedColumns: true,
            highlightErrorRows: true,
            hideLockedColumns: true,
            sizeColumnsToFitSize: 915,
            isInModalTab: true,
            rowLevelValidators: [validateTimekeepingEntries],
            rowLevelErrorDisplay: true,
            suppressExportButton: true,
            // Although the settings specifies possible groupBy options for the
            // Timekeeping tab of the modal, these options are configured on the fly based on
            // the grouping that's in place on the main table when you open the modal.
            // That configuration is handled in the groupingAllowedInCurrentConfiguration function
            // in toolbar/event-handlers/utils.ts
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/employee"],
                    label: "Employee",
                },
                {
                    cols: ["/cost_code"],
                    label: "Cost Code",
                },
            ],
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: {
                            local_state_update: true,
                        },
                    },
                    ...editTimecardDetailsAction(),
                ],
                groupRow: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRowToGroup",
                    },
                ],
                table: [
                    groupByTableAction,
                    addRowTableAction(featureFlags),
                    addEmployeeRowsTableAction,

                    {
                        label: "Add Cost Codes",
                        action: "addCostCodeRows",
                        icon: "costCode",
                        disabled: context => context.groupKeyInfo.find(key => key.colDef.field === "/cost_code"),
                        tooltip: context =>
                            context.groupKeyInfo.find(key => key.colDef.field === "/cost_code")
                                ? "The entries you are viewing are for a specific " +
                                  "cost code. New cost codes cannot be added to this view"
                                : undefined,
                    },
                    {
                        label: "Show Placeholders",
                        action: "togglePlaceholders",
                        icon: "view",
                        customButtonType: "placeholders",
                    },
                    ...editTimecardDetailsAction(),
                ],
            },
        },
    }
}
