import React from "react"
import Rmbx from "../../util"
import { validateDataLengthAndHeaders, validateStatus } from "./utils"
import { connect } from "react-redux"
import _isEqual from "lodash/isEqual"
import makeRequest from "../../networkClient"
import CsvUploadWrapper from "./generic-csv-upload-wrapper"
import { clearMaterialCsvData, fetchProjectMaterials } from "../../actions/index"
import FilterController from "../../filters/FilterController"
import { companyGroupsFilterDef, singleProjectFilterDef } from "../../filters/filter-defs"
import { currentProjectIdSelector, currentProjectSelector } from "../../selectors"
import { error as Errors } from "../../strings"
import { getSectionIdentifierForPath, SectionIdentifiers } from "../../common/page-identifier-utils"

const filters = [companyGroupsFilterDef, singleProjectFilterDef]

import { invalidateCache } from "../../cached-data/actions"

const maximumRowsInBulk = 1000
const uploadMessage = `Upload Your .CSV. Maximum ${maximumRowsInBulk} rows per file.`

const PricingColumns = { "Unit Price*": "unit_price" }

class MaterialCSVUploadForm extends React.Component {
    hasPricingEnabled = () => {
        return (
            this.props.pricingEnabled &&
            this.props.currentProject &&
            this.props.currentProject.options.material_pricing &&
            // Pricing is only available in the Project Settings section
            getSectionIdentifierForPath(this.props.route?.path) === SectionIdentifiers.ProjectSettings
        )
    }

    constructor(props) {
        super(props)
        this.columnNameMapping = {
            "Material*": "name",
            Description: "description",
            "Units*": "units",
            "Part #": "part_number",
            ...(this.hasPricingEnabled() ? PricingColumns : {}),
            Group: "group",
            Status: "is_active",
        }
    }

    validateRowData = (data, errors, existing_material_names) => {
        const material_names = new Set()
        const changes = {
            new: [],
            updates: [],
        }

        // Validate row data and report back row num and error
        data.forEach((row, index) => {
            const displayIndex = index + 1
            const rowErrors = {}
            row["_id"] = displayIndex

            // Names must be unique per project
            const colValue = row["name"]?.trim()
            if (colValue) {
                material_names.has(colValue)
                    ? (rowErrors["Name"] = [`Duplicate Material Name: ${colValue}`])
                    : material_names.add(colValue)
            } else {
                rowErrors["Material"] = ["Missing field"]
            }

            // Check other required fields
            if (!row["units"] || !row["units"].trim()) {
                rowErrors["Units"] = ["Missing field"]
            }

            validateStatus(row["is_active"], rowErrors)

            // If there are errors, assign them based on row number
            if (Object.entries(rowErrors).length > 0) {
                errors[displayIndex] = rowErrors
            }

            // Check to see if that material name belongs to an existing material
            if (colValue in existing_material_names) {
                // Add in the id in so the objects match
                existing_material_names[colValue]["_id"] = row._id

                // If the row doesn't match the existing information - mark as update
                if (!_isEqual(row, existing_material_names[colValue])) {
                    changes["updates"].push(colValue)
                    row["id"] = existing_material_names[colValue]["id"]
                }
            } else {
                // If we don't have the material name yet - entry is new
                changes["new"].push(colValue)
            }
        })

        return changes
    }

    // Check the csv data for updates/new status and that all relevant content
    // is included/formatted properly
    validateCsv = (data, parserResults) => {
        const errors = []

        // Pricing columns should not be required, even if pricing is enabled on this page.
        // For example, an export from the Company Settings page (which is missing the pricing columns)
        // should upload without a problem to the Project Settings page even if pricing is on
        const requiredHeaders = Object.keys(this.columnNameMapping).filter(
            c => !Object.keys(PricingColumns).find(pc => pc === c)
        )
        if (
            !validateDataLengthAndHeaders(
                data,
                parserResults.meta.fields,
                requiredHeaders,
                errors,
                "materials_missing"
            )
        )
            return { errors }
        if (data.length > maximumRowsInBulk) {
            errors.push({
                "Too Many Rows": [`${Errors.maximumRowsExceeded} ${maximumRowsInBulk} rows per file.`],
            })
            return { errors }
        }
        // Get all existing materials so we can know what is new vs update
        const existing_material_names = {}
        // these are ALL Materials, not just ones specific to this project
        const materials = this.props.projectMaterials["projectMaterials"]
        // Map all the relevant material info to determine if row is new or update
        // and consider Project-MaterialName uniqueness constraint
        if (Array.isArray(materials) && materials.length) {
            materials.map(material => {
                if (material.project_id === this.props.currentProjectId) {
                    existing_material_names[material.name] = {
                        id: material.id || null,
                        name: material.name || "",
                        description: material.description || "",
                        units: material.units || "",
                        part_number: material.part_number || "",
                        unit_price: material.unit_price || 0,
                        group: material.group || "",
                        is_active: material.is_active ? "Active" : "Inactive",
                    }
                }
            })
        }

        // Check to make sure the row has the necessary information
        const all_changes = this.validateRowData(data, errors, existing_material_names)

        return {
            errors,
            new: all_changes["new"],
            updates: all_changes["updates"],
        }
    }

    handleNext = () => {
        this.getProjectInfo()
        this.props.dispatch(clearMaterialCsvData())
        const next_url =
            !this.props.isCompanyLevel && this.props.currentProjectId
                ? "/rhumbix/projects/materials/edit"
                : "/rhumbix/company-admin/materials/edit"
        Rmbx.util.history.push(next_url)
    }

    getProjectInfo = () => {
        this.props.dispatch(fetchProjectMaterials(this.props.currentProjectId))
    }

    /**
     * Send the csv to the server for further processing
     * @param data
     */
    save = data => {
        const apiUrlCreate =
            !this.props.isCompanyLevel && this.props.currentProjectId
                ? "/api/v4/materials/bulk_insert/?project=" + this.props.currentProjectId
                : "/api/v4/materials/bulk_insert/"
        this.props.dispatch(invalidateCache("materials"))
        return makeRequest(
            {
                url: apiUrlCreate,
                method: "POST",
                body: JSON.stringify(data),
            },
            200
        )
    }

    /**
     * Render this react component
     * @returns {XML}
     */
    render() {
        const csvTemplateName = this.hasPricingEnabled() ? "MaterialsPricing.csv" : "Materials.csv"
        return (
            <div className="flex-vertically">
                <FilterController filters={this.props.isCompanyLevel ? [] : filters} route={this.props.route} />
                <div className="page-view-container scrollable">
                    <CsvUploadWrapper
                        pageTitle="Upload Materials"
                        stepOneDescription="Download and Fill Out the Materials .CSV"
                        stepTwoDescription={uploadMessage}
                        csvImageProps={{
                            src: require("../../../images/material-fake-csv.png"),
                        }}
                        csvTemplateName={csvTemplateName}
                        manualInputText="Type in materials manually"
                        csvHeadersMap={this.columnNameMapping}
                        validateCsv={this.validateCsv}
                        handleNext={this.handleNext}
                        csvSave={this.save}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return Object.assign(
        {},
        {
            currentProjectId: currentProjectIdSelector(state),
            pricingEnabled: state.featureFlags.features.pricing,
            projectMaterials: state.projectMaterials,
            currentProject: currentProjectSelector(state),
        }
    )
}

export default connect(mapStateToProps)(MaterialCSVUploadForm)
