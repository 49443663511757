import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select-v1"
import { getDebouncedSearch } from "../api/search"
import SelectorOption from "./Option"

import AsyncPaginate from "./AsyncPaginate"

import "./Selector.less"
import { getFlagEnabled } from "../getFlagValue"

class Selector extends Component {
    constructor(props) {
        super(props)
        this.debouncedSearch = getDebouncedSearch({ leading: getFlagEnabled("WA-7424-no-leading-debounce") })
    }

    loadOptions = (inputValue, callback, currentOptions = []) =>
        this.debouncedSearch(inputValue, callback, currentOptions, {
            resourceName: this.props.resourceName,
            dispatch: this.props.dispatch,
            extraFilters: this.props.filters,
            idsToExclude: this.props.idsToExclude,
        })

    onFocus = () => {
        // Ensure that the focus event gets triggered on the wrapped Selector
        // and/or AsyncPaginate components. In the case of AsyncPaginate, this
        // also triggers loading an initial set of options from the backend.
        this.props.innerRef.current.focus()
    }

    optionRenderer = option => (
        <SelectorOption
            option={option}
            context={this.props.context}
            valueFormatter={this.props.valueFormatter}
            prefixFormatter={this.props.prefixFormatter}
            titleFormatter={this.props.titleFormatter}
            primarySubtitleFormatter={this.props.primarySubtitleFormatter}
            secondarySubtitleFormatter={this.props.secondarySubtitleFormatter}
        />
    )

    clearRenderer = () => {
        const clearText = this.props.clearText ? this.props.clearText : "Show All"

        return (
            <div className="clear-text-icon-wrapper">
                {clearText} <i className="btn-dismiss" id="clear-x" />
            </div>
        )
    }

    valueRenderer = value =>
        this.props.valueFormatter
            ? this.props.valueFormatter({
                  value: value,
                  context: this.props.context,
              })
            : value.label

    render() {
        const isLoadOptionsFromAPI = !this.props.options
        const SelectorComponent = isLoadOptionsFromAPI ? AsyncPaginate : Select
        return (
            <SelectorComponent
                autoload={!this.props.suppressAutoload}
                className="Selector Selector-v1"
                openOnFocus={this.props.openOnFocus}
                ref={this.props.innerRef}
                multi={!!this.props.multiselect}
                closeOnSelect={!this.props.multiselect}
                disabled={!!this.props.disabled}
                removeSelected={false}
                autosize={false}
                clearable={!!this.props.clearable}
                loadOptions={isLoadOptionsFromAPI ? this.loadOptions : undefined}
                options={this.props.options}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onFocus={isLoadOptionsFromAPI ? this.onFocus : undefined}
                onClose={this.props.onClose}
                valueKey={this.props.valueKey}
                value={this.props.value}
                placeholder={this.props.placeholder}
                optionRenderer={this.optionRenderer}
                clearRenderer={this.clearRenderer}
                valueRenderer={this.valueRenderer}
                // Prevent react-select from performing additional client-side filtering
                filterOptions={options => options}
                primaryKey={this.props.primaryKey}
                filterValue={this.props.filterValue}
            />
        )
    }
}

const ConnectedSelector = connect()(Selector)

export default React.forwardRef((props, ref) => <ConnectedSelector innerRef={ref} {...props} />)
