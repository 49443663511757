import uuid from "uuid"

import { Action } from "redux"
import * as API from "../api"
import { getListOfTransformsOptions, openCustomFormErrorModal } from "../components/modals/actions"
import Rmbx from "./../util"
import {
    AsyncThunk,
    iActionData,
    tCustomFormTransform,
    tFileAttachment,
    Thunk,
    tSchemaPermission,
} from "../common/types"
import { putS3 } from "../common/image-utils"
import { isNumber } from "lodash"
import { SchemaVariant, iCompanyFormSchema, iCompanyFormStore, tProject } from "../cached-data/types"
import { sourceDataRowAdded, sourceDataUpdated } from "../dashboard-data/actions"
import { loadAllReferenceableData } from "../cached-data/actions"
import { setNotificationMessage } from "../websockets/actions"
import { PDF_CREATING } from "../websockets/types"
import { SchemaVariantActions } from "../reducers/types"
import { getFlagEnabled } from "../getFlagValue"

export const COMPANY_FORM_UPDATE_SUCCESSFUL = "COMPANY_FORM_UPDATE_SUCCESSFUL"
export const UPDATE_CUSTOM_FORM = "UPDATE_CUSTOM_FORM"
export const CLEAR_CUSTOM_FORM_DATA = "CLEAR_CUSTOM_FORM_DATA"
export const CUSTOM_FORM_TOGGLE_EDITING = "CUSTOM_FORM_TOGGLE_EDITING"
export const PDF_LINK_SUCCESSFUL = "PDF_LINK_SUCCESSFUL"
export const PDF_LINK_FAILED = "PDF_LINK_FAILED"
export const PDF_STATUS_UPDATE = "PDF_STATUS_UPDATE"
export const PDF_KEY_RELOAD_ATTEMPTS = "PDF_KEY_RELOAD_ATTEMPTS"
export const FORM_ATTACHMENTS_CHANGED = "FORM_ATTACHMENTS_CHANGED"
export const ATTACHMENTS_UPLOAD_STARTED = "ATTACHMENTS_UPLOAD_STARTED"
export const ATTACHMENTS_UPLOAD_COMPLETE = "ATTACHMENTS_UPLOAD_COMPLETE"
export const ATTACHMENTS_UPLOAD_FAILED = "ATTACHMENTS_UPLOAD_FAILED"
export const GET_CUSTOM_FORM_PROJECT_SUCCEEDED = "GET_CUSTOM_FORM_PROJECT_SUCCEEDED"
export const GET_CUSTOM_FORM_PROJECT_FAILED = "GET_CUSTOM_FORM_PROJECT_FAILED"
export const GET_SINGLE_COMPANY_FORM_SCHEMA = "GET_SINGLE_COMPANY_FORM_SCHEMA"
export const CLEAR_SINGLE_COMPANY_FORM_SCHEMA = "CLEAR_SINGLE_COMPANY_FORM_SCHEMA"
export const CUSTOM_FORM_ITEM_SAVE_COMPLETE = "CUSTOM_FORM_ITEM_SAVE_COMPLETE"
export const COMPANY_FORM_UPDATE_FAILED = "COMPANY_FORM_UPDATE_FAILED"
export const CUSTOM_FORM_ITEM_SAVE_ERROR = "CUSTOM_FORM_ITEM_SAVE_ERROR"
export const CUSTOM_FORM_MAX_HISTORY_RETRIEVED = "CUSTOM_FORM_MAX_HISTORY_RETRIEVED"
export const CUSTOM_FORM_TOGGLE_NEW_UI = "CUSTOM_FORM_TOGGLE_NEW_UI"
export const CUSTOM_FORM_USE_NEW_UI = "CUSTOM_FORM_USE_NEW_UI"
export const SCHEMA_PERMISSIONS_UPDATED = "SCHEMA_PERMISSIONS_UPDATED"
export const GUEST_INFO_CHANGED = "GUEST_INFO_CHANGED"

export interface iSchemaPermissionUpdateSuccessful extends iActionData {
    type: typeof SCHEMA_PERMISSIONS_UPDATED
}

export interface iGuestInfoChanges extends iActionData {
    type: typeof GUEST_INFO_CHANGED
}

export interface iCustomFormDataChanged extends iActionData {
    type: typeof UPDATE_CUSTOM_FORM
}

export interface iCustomFormDataCleared extends Action {
    type: typeof CLEAR_CUSTOM_FORM_DATA
}

export interface iGetCompanyFormProjectSuceeded extends iActionData {
    type: typeof GET_CUSTOM_FORM_PROJECT_SUCCEEDED
}

export interface iGetCompanyFormProjectFailed extends Action {
    type: typeof GET_CUSTOM_FORM_PROJECT_FAILED
}

export interface iPdfStatusMessage extends Action {
    type: typeof PDF_STATUS_UPDATE
    status: string
    msg: string
}

export interface iPdfKeyReloadAttempts extends Action {
    type: typeof PDF_KEY_RELOAD_ATTEMPTS
    pdfKeyReloadAttempts: number
}

export interface iFormAttachmentsChanged extends Action {
    type: typeof FORM_ATTACHMENTS_CHANGED
    attachments: Array<tFileAttachment>
}

export interface iAttachmentUploadStarted extends Action {
    type: typeof ATTACHMENTS_UPLOAD_STARTED
}

export interface iAttachmentUploadComplete extends Action {
    type: typeof ATTACHMENTS_UPLOAD_COMPLETE
}

export interface iAttachmentUploadFailed extends Action {
    type: typeof ATTACHMENTS_UPLOAD_FAILED
}

export interface iGetPdfLinkSucceeded extends iActionData {
    type: typeof PDF_LINK_SUCCESSFUL
}

export interface iGetPdfLinkFailed extends Action {
    type: typeof PDF_LINK_FAILED
}

export interface iCompanyFormStoreUpdateSuccessful extends iActionData {
    type: typeof COMPANY_FORM_UPDATE_SUCCESSFUL
    data: any
}

export interface iRemoveCustomFormItemFromSaveList extends Action {
    type: typeof CUSTOM_FORM_ITEM_SAVE_COMPLETE
    id: any
}

export interface iCompanyFormStoreUpdateFailed extends Action {
    type: typeof COMPANY_FORM_UPDATE_FAILED
}

export interface iGetSingleCompanyFormSchema extends iActionData {
    type: typeof GET_SINGLE_COMPANY_FORM_SCHEMA
}

export interface iClearSingleCompanyFormSchema extends Action {
    type: typeof CLEAR_SINGLE_COMPANY_FORM_SCHEMA
}

export interface iCustomFormToggleEditing extends Action {
    type: typeof CUSTOM_FORM_TOGGLE_EDITING
    data: { isEditingActive?: boolean }
}

export interface iCustomFormSaveErrorNotification extends iActionData {
    type: typeof CUSTOM_FORM_ITEM_SAVE_ERROR
}

export interface iCustomFormMaxHistoryRetrieved extends iActionData {
    type: typeof CUSTOM_FORM_MAX_HISTORY_RETRIEVED
    data: any
}

export type tFormViewActions =
    | iCustomFormDataChanged
    | iGetCompanyFormProjectSuceeded
    | iGetCompanyFormProjectFailed
    | iPdfStatusMessage
    | iPdfKeyReloadAttempts
    | iFormAttachmentsChanged
    | iAttachmentUploadStarted
    | iAttachmentUploadComplete
    | iAttachmentUploadFailed
    | iGetPdfLinkFailed
    | iGetPdfLinkSucceeded
    | iCompanyFormStoreUpdateSuccessful
    | iRemoveCustomFormItemFromSaveList
    | iCompanyFormStoreUpdateFailed
    | iCustomFormDataCleared
    | iGetSingleCompanyFormSchema
    | iClearSingleCompanyFormSchema
    | iCustomFormToggleEditing
    | iCustomFormSaveErrorNotification
    | iCustomFormMaxHistoryRetrieved

export type NewFormData = {
    employee: number // this is employee id, named employee to remain consistent with existing format for save
    project: number // this is project id, named project to remain consistent with existing format for save
    store: Record<string, any>
    schema: number // this is schema id, named schema to remain consistent with existing format for save
}

export const getCustomFormData = (
    id: number | null,
    sharedKey: string | null,
    transform: tCustomFormTransform | null,
    history_id?: number,
    skipSchemaFetch?: boolean,
    variantName?: string
): Thunk => {
    return dispatch => {
        if (transform) {
            return API.transformDataAPI(transform).then(response => {
                const data = { store: { ...response.results } }
                dispatch(customFormDataChanged(data))
                dispatch(
                    getCompanyFormSchemas({ schema_id: transform.schema_id, include_classic: true }, sharedKey, id)
                )
            })
        } else if (history_id && !sharedKey) {
            return API.getCustomFormDataHistory(id, history_id).then(data => {
                dispatch(customFormDataChanged(data[0]))
                // Don't reload the schema if we don't have to (i.e. navigating field forms in the right rail)
                if (!skipSchemaFetch) {
                    dispatch(
                        getCompanyFormSchemas({ schema_id: data[0].schema, include_classic: true }, sharedKey, id)
                    )
                }
            })
        } else if (id) {
            return API.getCustomFormData(id, sharedKey, history_id).then(data => {
                dispatch(customFormDataChanged(data))
                // Don't reload the schema if we don't have to (i.e. navigating field forms in the right rail)
                if (!skipSchemaFetch) {
                    dispatch(
                        getCompanyFormSchemas(
                            { schema_id: data.schema, include_classic: true },
                            sharedKey,
                            id,
                            variantName
                        )
                    )
                }
                if (!sharedKey) {
                    dispatch(getListOfTransformsOptions(data.schema))
                }
            })
        }
    }
}

export const customFormDataChanged = (
    data: iCompanyFormStore | { store: Record<string, any> }
): tFormViewActions => {
    return {
        type: UPDATE_CUSTOM_FORM,
        data,
    }
}

export const getCompanyFormProjectSuceeded = (data: tProject): tFormViewActions => {
    return {
        type: GET_CUSTOM_FORM_PROJECT_SUCCEEDED,
        data,
    }
}

export const getCompanyFormProjectFailed = (): tFormViewActions => {
    return {
        type: GET_CUSTOM_FORM_PROJECT_FAILED,
    }
}

export const getFormStoreVariantsSucceeded = (data: string[]): SchemaVariantActions => {
    return {
        type: "FETCH_SCHEMA_VARIANT_NAMES_SUCCESS",
        data,
    }
}

export const getFormStoreVariantsFailed = (): SchemaVariantActions => {
    return {
        type: "FETCH_SCHEMA_VARIANT_NAMES_FAILURE",
    }
}

export const getVariantTemplatesSucceeded = (data: SchemaVariant[]): SchemaVariantActions => {
    return {
        type: "FETCH_VARIANT_TEMPLATES_SUCCESS",
        data,
    }
}

export const getVariantTemplatesFailed = (): SchemaVariantActions => {
    return {
        type: "FETCH_SCHEMA_VARIANT_NAMES_FAILURE",
    }
}

export const setSelectedVariant = (selectedVariant: string): SchemaVariantActions => {
    return {
        type: "SET_SCHEMA_VARIANT_NAME",
        selectedVariant,
    }
}

export const clearFormVariants = (): SchemaVariantActions => {
    return {
        type: "CLEAR_SELECTED_AND_AVAILABLE_VARIANTS",
    }
}

/**
 * Used for downloading a PDF as a guest user. We treat this similar to a logged in user, 
 * but use the guest mode signed key for auth.

 * @param storeId - id of the store the guest wants to download
 * @param signedKey - key from guest url that we use for authentication to a specific store
 */
export const triggerGuestPdfDownload = (storeId: number, signedKey: string): Thunk => {
    const uniqueRequestId = uuid.v4()
    return dispatch => {
        return API.triggerGuestPdfDownload(storeId, signedKey, uniqueRequestId)
            .then(() => {
                // set a progress message and req id - socket will only download file if req id matches in redux
                // this prevents multiple downloads if the user has multiple browsers open
                dispatch(
                    setNotificationMessage("Generating PDFs. Download will begin shortly", "info", {
                        uniqueRequestId,
                    })
                )
                return
            })
            .catch((error: Error) => {
                const errorMessage = `There was an error with your download. ${error.message}`
                dispatch(
                    // reset the uniqueRequestId if there is an error to re-enable the Download button
                    openCustomFormErrorModal([errorMessage], "server_error")
                )
                dispatch(
                    // reset the uniqueRequestId if there is an error to re-enable the Download button
                    setNotificationMessage(errorMessage, "warning")
                )
                return
            })
    }
}

export const triggerBulkPdfDownload = (
    storeIds: Array<number>,
    filename?: string,
    variantName?: string | null
): Thunk => {
    const uniqueRequestId = uuid.v4()
    return dispatch => {
        dispatch(setNotificationMessage("PDF Download Request Sent", "info", { uniqueRequestId }))
        return API.triggerBulkPdfDownload(storeIds, filename, uniqueRequestId, variantName)
            .then(() => {
                // set a progress message and req id - socket will only download file if req id matches in redux
                // this prevents multiple downloads if the user has multiple browsers open
                dispatch(
                    setNotificationMessage("Generating PDFs. Download will begin shortly", "info", {
                        uniqueRequestId,
                    })
                )
                return
            })
            .catch((error: Error) => {
                dispatch(
                    // reset the uniqueRequestId if there is an error to re-enable the Download button
                    setNotificationMessage(`There was an error with your download. ${error.message}`, "warning")
                )
                return
            })
    }
}

export const getPdfDownloadLink = (
    taskId: string,
    formId: number,
    allowRegeneratePDF: boolean,
    signedKey = "",
    iteration = 0
): Thunk => {
    return dispatch => {
        return API.getS3DownloadLink(taskId, formId, signedKey)
            .then(data => {
                if ("download_url" in data) {
                    dispatch(getPdfLinkSucceeded(data))
                    dispatch(pdfStatusMessage("READY", ""))
                } else if ("status" in data) {
                    // check if the pdf is still processing - we give it up to a min to process
                    // if the task fails we should get a FAILURE status back
                    if (iteration < 20 && data["status"] === "PDF_REQUEST_MADE") {
                        dispatch(pdfStatusMessage("CREATING", "PDF is processing. This may take a moment."))
                        setTimeout(function () {
                            dispatch(
                                getPdfDownloadLink(taskId, formId, allowRegeneratePDF, signedKey, iteration + 1)
                            )
                        }, 3000)
                        //    }
                    } else if (
                        allowRegeneratePDF &&
                        (data["status"] === "PENDING" || data["status"] === "FAILURE")
                    ) {
                        // If it failed (or is in the generic but unhelpful PENDING state), regenerate the PDF.
                        // This is disallowed on the next try in order to prevent multiple pointless retries
                        API.regeneratePDF(formId)
                        setTimeout(function () {
                            dispatch(getPdfDownloadLink(taskId, formId, false, signedKey, iteration + 1))
                        }, 6000)
                    } else {
                        dispatch(getPdfLinkFailed())
                        dispatch(pdfStatusMessage("ERROR", "There was an error processing this PDF."))
                    }
                }
            })
            .catch(() => {
                dispatch(getPdfLinkFailed())
                dispatch(pdfStatusMessage("ERROR", "There was an error processing this PDF."))
            })
    }
}

export const pdfStatusMessage = (status: string, msg: string): tFormViewActions => {
    return {
        type: PDF_STATUS_UPDATE,
        status: status,
        msg: msg,
    }
}

export const updatePdfKeyReloadAttempts = (count: number): tFormViewActions => {
    return {
        type: PDF_KEY_RELOAD_ATTEMPTS,
        pdfKeyReloadAttempts: count,
    }
}

export const formAttachmentsChanged = (attachments: Array<tFileAttachment>): tFormViewActions => {
    return {
        type: FORM_ATTACHMENTS_CHANGED,
        attachments,
    }
}

export const attachmentUploadStarted = (): tFormViewActions => {
    return {
        type: ATTACHMENTS_UPLOAD_STARTED,
    }
}

export const attachmentUploadComplete = (): tFormViewActions => {
    return {
        type: ATTACHMENTS_UPLOAD_COMPLETE,
    }
}

export const attachmentUploadFailed = (): tFormViewActions => {
    return {
        type: ATTACHMENTS_UPLOAD_FAILED,
    }
}

export const getPdfLinkSucceeded = (data: { download_url: string }): tFormViewActions => {
    return {
        type: PDF_LINK_SUCCESSFUL,
        data,
    }
}

export const getPdfLinkFailed = (): tFormViewActions => {
    return {
        type: PDF_LINK_FAILED,
    }
}

// Kick off the process that regenerates a PDF. This only kicks off the process and does not
// wait for the result
export const regeneratePDF = (formId: number): Thunk => {
    return dispatch => {
        if (!formId) return
        dispatch(setNotificationMessage(PDF_CREATING, "info", { ignoreIds: { companyFormStores: [formId] } }))
        return API.regeneratePDF(formId)
        // TODO: We could consider tracking and reporting this error, but this call's failure may not
        // end up stopping our efforts to track down the PDF for a form
    }
}

export const clearCustomFormStore = (): iCustomFormDataCleared => {
    return {
        type: CLEAR_CUSTOM_FORM_DATA,
    }
}

export const updateCompanyFormStore = (
    id: string | number,
    store: Record<string, any>,
    signedKey?: string,
    guestEmail?: string,
    status?: string,
    fields?: string[],
    gridId?: string
): AsyncThunk => {
    const body = {
        store,
        status,
    }
    if (status) {
        body["status"] = status
    }
    return dispatch => {
        return API.updateCompanyFormStore(id, body, signedKey, guestEmail, fields).then(data => {
            dispatch(companyFormStoreUpdateSuccessful(data))
            // We want to update the List View table source data with the API response. ~*Dynamic!*~
            if (gridId)
                // But only if the record we're currently editing is in the list view. if we're editing a
                // bundled form, it won't be so it doesn't make any sense.
                dispatch(sourceDataUpdated({ companyFormStores: [{ ...data, gridId }] }))
            dispatch(removeCustomFormItemFromSaveList(isNumber(id) ? id : parseInt(id)))
            return data
        })
    }
}

export function companyFormStoreUpdateSuccessful(data: Record<string, any>): tFormViewActions {
    return {
        type: COMPANY_FORM_UPDATE_SUCCESSFUL,
        data,
    }
}

export function removeCustomFormItemFromSaveList(id: number): tFormViewActions {
    return {
        type: CUSTOM_FORM_ITEM_SAVE_COMPLETE,
        id,
    }
}

export function companyFormStoreUpdateFailed(): tFormViewActions {
    return {
        type: COMPANY_FORM_UPDATE_FAILED,
    }
}

export function getCompanyFormSchemas(
    filters: Record<string, any> = {},
    sharedKey: any = null,
    form_id: any = null,
    variantName: string | null = null
): Thunk {
    return dispatch => {
        // @ts-ignore - getCompanyFormSchemas is a JS file with a null default and TS was complainting if string
        return API.getCompanyFormSchemas(filters, sharedKey, form_id, variantName).then(data => {
            dispatch(getSingleCompanyFormSchema(data))
        })
    }
}

export const getSingleCompanyFormSchema = (data: iCompanyFormSchema[]): tFormViewActions => {
    return {
        type: GET_SINGLE_COMPANY_FORM_SCHEMA,
        data,
    }
}

export const clearSingleCustomFormSchema = (): iClearSingleCompanyFormSchema => {
    return {
        type: CLEAR_SINGLE_COMPANY_FORM_SCHEMA,
    }
}

export const customFormToggleEditing = (isEditingActive?: boolean): tFormViewActions => {
    return {
        type: CUSTOM_FORM_TOGGLE_EDITING,
        data: { isEditingActive },
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function customFormSaveErrorNotification(data: any): tFormViewActions {
    return {
        type: CUSTOM_FORM_ITEM_SAVE_ERROR,
        data,
    }
}

export const deleteCompanyFormStore = (id: number): Thunk => {
    return () => {
        return API.deleteCompanyFormStore(id)
            .then(data => {
                return data
            })
            .catch(error => {
                return error.response
            })
    }
}

export const getFormProject = (parameters: Record<string, any>): Thunk => {
    return dispatch => {
        return API.getProjects(parameters).then(data => {
            dispatch(getCompanyFormProjectSuceeded(data.results[0]))
        })
    }
}

/**
 * Retrieve a list of all variant names available to *all* of the provided store ids
 * @param storeIds List of form store ids
 */
export const getFormStoreVariants = (storeIds: number[]): Thunk => {
    return dispatch => {
        return API.getFormStoreVariants({ store_id: storeIds })
            .then((data: string[]) => {
                dispatch(getFormStoreVariantsSucceeded(data))
            })
            .catch(() => {
                dispatch(getFormStoreVariantsFailed())
            })
    }
}

/**
 * Retrieves a list of variant objects with templates
 * can filter by store_id, schema_id, schema_name, variant_name, variant_id, status_id, and status_name
 * @param filters Obj with query param filters
 */
export const getVariantTemplates = (filters: Record<string, any>): Thunk => {
    return dispatch => {
        return API.getVariantTemplates(filters)
            .then(data => {
                dispatch(getVariantTemplatesSucceeded(data))
            })
            .catch(() => {
                dispatch(getVariantTemplatesFailed())
            })
    }
}

export const generateUrlAndUploadAttachment = (
    attachment: File | Blob,
    filename: string,
    addAttachment: (proxyUrl: string, name: string, type: string) => void
): Thunk => {
    const attachmentData = { [filename]: { content_type: attachment.type } }
    return dispatch => {
        dispatch(attachmentUploadStarted())
        return API.generateS3Urls(attachmentData).then(data => {
            const attachmentDescription = data[filename]
            putS3(
                attachmentDescription["put_url"],
                attachment,
                attachmentDescription["proxy_url"],
                (proxyUrl: string, name: string, type: string) => {
                    dispatch(attachmentUploadComplete())
                    addAttachment(proxyUrl, filename, type)
                },
                () => {
                    dispatch(attachmentUploadFailed())
                }
            )
        })
    }
}

// remove with WA-5689-update-external-form-view
export const openBlankForm = (parameters: Record<string, any>): Thunk => {
    const projectId = parameters["project"]
    return () => {
        API.getCompanyFormSchemas({ ...parameters, include_classic: true }).then(data => {
            const schemaId = data.length > 0 ? data[0].id : null
            if (schemaId) {
                window.open(`/rhumbix/custom-form/create/${projectId}/${schemaId}`)
            }
        })
    }
}

export const createCompanyFormStore = (
    formData: NewFormData,
    disableSideRail?: boolean | (() => void), // remove boolean with WA-5689-update-external-form-view
    skipRefresh = false,
    fields?: string[]
): Thunk => {
    return dispatch => {
        return API.createCompanyFormStore(formData, fields)
            .then((data: Record<string, any>) => {
                dispatch(companyFormStoreUpdateSuccessful(data))
                if (
                    disableSideRail &&
                    typeof disableSideRail === "function" &&
                    getFlagEnabled("WA-5689-update-external-form-view")
                ) {
                    disableSideRail()
                }
                if (!disableSideRail) {
                    // we only need to do this for the new tab experience - but we're removing create from there
                    // remove else with WA-5689-update-external-form-view
                    Rmbx.util.history.push(`/rhumbix/custom-form/${data.id}`)
                    dispatch(customFormToggleEditing())
                } else if (!skipRefresh) {
                    // if creating from right rail - add the new form to the source data
                    // we don't use alreadySavedtoDB = true bc it won't mark the row as newRow = true, which we need
                    dispatch(sourceDataRowAdded("companyFormStores", data))
                    // if we are creating on an empty list view, we need to pull the schema into ag-grid
                    dispatch(loadAllReferenceableData("companyFormSchemas", [data.schema]))
                }
                return data
            })
            .catch(error => {
                if (getFlagEnabled("WA-5689-update-external-form-view")) {
                    dispatch(companyFormStoreUpdateFailed())
                    if (error.response) {
                        dispatch(customFormSaveErrorNotification(error.response))
                    }
                    dispatch(openCustomFormErrorModal([error.message], "server_error"))
                } else {
                    // form-view-wrapper-classic has a catch in it for errors, so to maintain that throw error
                    throw error
                }
            })
    }
}

export const getCustomFormMaxHistory = (id: number): Thunk => {
    return dispatch => {
        return API.getCustomFormMaxHistory(id).then(data => {
            dispatch(customFormMaxHistoryChanged(data))
        })
    }
}

export const customFormMaxHistoryChanged = (data: iCompanyFormStore): iCustomFormMaxHistoryRetrieved => {
    return {
        type: CUSTOM_FORM_MAX_HISTORY_RETRIEVED,
        data,
    }
}

export type tGuestInfo = {
    guest_first_name: string
    guest_last_name: string
    guest_company?: string
    guest_role?: string
}

export const guestInfoChanged = (data: tGuestInfo): iGuestInfoChanges => {
    return {
        type: GUEST_INFO_CHANGED,
        data,
    }
}

export const guestInfoUpdate = (data: tGuestInfo, signedKey: string, email: string): Thunk => {
    return dispatch => {
        return API.updateGuestInfo(data, signedKey, email).then(() => {
            dispatch(guestInfoChanged(data))
        })
    }
}

export const updatePermissionSuccessful = (data: tSchemaPermission): iSchemaPermissionUpdateSuccessful => {
    return {
        type: SCHEMA_PERMISSIONS_UPDATED,
        data,
    }
}

export const updateSchemaPermission = (
    schemaId: number,
    statusName: string,
    userRole: string,
    companyRole: string,
    fieldPermissions: Record<string, any> = {},
    objectPermissions: Record<string, any> = {}
): void => {
    API.updatePermissions(schemaId, statusName, userRole, companyRole, fieldPermissions, objectPermissions)
}

export const updateSchemaActions = (payload: Record<string, any>): void => {
    API.updateSchemaActions(payload)
}

export const updateSchemaStatuses = (payload: Record<string, any>): void => {
    API.updateSchemaStatuses(payload)
}

export const addSchemaStatuses = (payload: Record<string, any>): Promise<any> => {
    return API.addSchemaStatuses(payload)
}

export const addSchemaActions = (payload: Record<string, any>): void => {
    API.addSchemaActions(payload)
}

export const deleteSchemaActions = (payload: number[]): void => {
    API.deleteItemsById("schemaActions", payload)
}

export const deleteSchemaStatuses = (payload: number[]): void => {
    API.deleteItemsById("schemaStatusNames", payload)
}
