import { createSelector } from "reselect"
import { getFlagEnabled } from "../getFlagValue"
/**
 * Return company form schemas
 */
export const companyFormSchemasSelector = state => state.companyFormSchema.all
export const schemaTemplatesSelector = state => state.schemaVariants.variantTemplates
export const currentVariantSelector = state => state.schemaVariants.selectedVariantName
export const currentSchemaSelector = state =>
    state.customForm?.customFormData?.schema ?? state.companyFormSchema?.single?.id

/**
 * Return
 */
export const listOfCompanyFormSchemas = createSelector(companyFormSchemasSelector, companyFormSchemas => {
    if (companyFormSchemas?.length) {
        return companyFormSchemas.map(schema => {
            return { name: schema.name, id: schema.id }
        })
    }
    return companyFormSchemas || []
})

// schemas for the main list view
export const primaryListViewSchemaNames = state => state.listViewSchemas.schemas?.map(schema => schema.name)
// schemas for related list views (ie Bundle list views)
export const relatedListViewSchemaNames = state => state.relatedSchemas.relatedSchemas?.map(schema => schema.name)

export const currentTemplateSelector = createSelector(
    [schemaTemplatesSelector, currentVariantSelector, currentSchemaSelector],
    (variantTemplates, currentVariant, currentSchema) =>
        variantTemplates.find(vt => vt.name === currentVariant && vt.schema_id === currentSchema)
)

export const schemaVariantNamesForListView = createSelector(
    [schemaTemplatesSelector, primaryListViewSchemaNames],
    (variantTemplates, primaryListViewNames) => {
        if (!getFlagEnabled("WA-8154-ff-variants")) return []
        return (
            variantTemplates?.filter(vt => primaryListViewNames.includes(vt.schema_name)).map(vt => vt.name) || []
        )
    }
)

export const schemaVariantNamesForRelatedListView = createSelector(
    [schemaTemplatesSelector, relatedListViewSchemaNames],
    (variantTemplates, relatedListViewNames) => {
        if (!getFlagEnabled("WA-8154-ff-variants")) return []
        return (
            variantTemplates?.filter(vt => relatedListViewNames?.includes(vt.schema_name)).map(vt => vt.name) || []
        )
    }
)
