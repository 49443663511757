import { roleHasWritePermission } from "../../common/ag-grid-utils"
import memoize from "memoize-one"

import { bulkWorkflowAction } from "../../actions/bulk-actions"
import {
    deleteSourceData,
    bulkUpdateSourceDataField,
    reshareGuestFormShares,
    softDeleteSourceData,
    bulkUpdateSourceDataFieldNoAutosave,
} from "../actions"
import { openCopyToModal, openFormShareModal, openImportToFieldFormModal } from "../../components/modals/actions"
import { tSourceData, tQueryParams, tResourceObject } from "../../dashboard-data/types"
import { tContext } from "../../components/custom-dashboards/types"
import {
    tButtonClickHandler,
    tButtonClickHandlerFactory,
    tButtonClickHandlerTuple,
    tBulkActionParams,
    tExtraButtonParams,
} from "../types"
import { getFormShareGuestVariants, getFormSharePermissions, updateRow } from "../../api"
import {
    getDefaultEmailMessage,
    fetchPreviousCollaborators,
    userRolesWithAdditionalSharePrivileges,
} from "../../components/guest-login/guest-login-utils"
import { getFlagEnabled } from "../../getFlagValue"
import { VIEW_ONLY, REQUEST_SIGNATURE, STORED_DATE_ONLY_FORMAT, BUNDLE_TABLE_NAME } from "../../common/constants"
import { IconAddCollaborator } from "@rhumbix/rmbx_design_system_web"
import { BulkViewMode } from "../../forms/BulkReviewForm/types"
import { FORM_BULK_ACTION, logUserAmplitudeEvent } from "../../common/amplitude-event-logging"
import { wsConnect } from "../../websockets/actions"
import { getAuditHistoryById } from "../../actions/audit-history"
import { getSocketUrl } from "../../websocket-utils"
import { setSelectedVariant, triggerBulkPdfDownload } from "../../actions/form-view-actions"
import { IRowNode } from "ag-grid-community"
import {
    convertToFilename,
    filterCopyToTransformsBySchemaId,
    getBundleTransforms,
    getSelectedRowStatuses,
} from "../../common/ts-utils"
import { format } from "date-fns"
import { bulkAddSourceData, updateSourceDataRow } from "../../dashboard-data/actions/write"
import { getFormSummaryInfo } from "./utils"
import { iTimekeepingStatus } from "../../cached-data/types"
import { sourceDataUpdated } from "../../dashboard-data/actions"

export const deleteSelectedRows: tButtonClickHandler = (e, { args, context, gridApi }) => {
    const selectedRowsWithWritePermission = context.selectedRows.filter(
        row => !row.status || roleHasWritePermission(row.status, context.currentUser.user_role)
    )

    const sourceDataToDelete: tSourceData = {
        [context.settings.resources[0]]: selectedRowsWithWritePermission,
    }

    if (!args?.no_autosave && !args?.local_state_update) {
        context.createModalAction({
            title: "Delete selected rows?",
            description: "This action cannot be undone",
            action: () => {
                context.dispatch(
                    deleteSourceData(
                        sourceDataToDelete,
                        undefined,
                        context.settings.gridSettings?.rowModelType === "serverSide"
                    )
                )
            },
            close: () => context.createModalAction(null),
        })
    } else if (args?.no_autosave) {
        context.dispatch(
            softDeleteSourceData(
                sourceDataToDelete,
                gridApi.getSelectedNodes(),
                context.settings.gridSettings?.rowModelType === "serverSide"
            )
        )
    } else if (context.updateSourceDataCb) {
        context.updateSourceDataCb?.({ [context.settings.resources[0]]: selectedRowsWithWritePermission }, true)
    }
}

export const bulkManageAccess: tButtonClickHandler = async (e, { context }) => {
    let itemType = context.settings.resources[0]

    // Resource name is pluralized and camelcase
    // but itemType should be lowercase without pluralization
    // front-end and back-end used different conventions for this
    // unfortunately so we have to translate
    if (itemType === "picklistItems") {
        itemType = "picklistitem"
    } else if (itemType == "companyTrades") {
        itemType = "companytrade"
    } else if (itemType == "companyClassifications") {
        itemType = "companyclassification"
    }

    const selectedRows = context.selectedRows.map(s => s.id)
    const itemName = selectedRows.length > 1 ? `${selectedRows.length} Items` : context.selectedRows[0].name
    const tableName = context.settings.tableName

    // Our display name is different than the table name in some cases
    // so mapping them here
    let title = ""
    if (tableName === "Company Trades") {
        title = "Trades"
    } else if (tableName === "Company Classifications") {
        title = "Classifications"
    } else {
        title = tableName
    }

    const defaultOpenRemapModal = (_: Record<string, any>) => {}
    const openRemapModal =
        context.sideRailContext.sideRailConfig.flow === "DATA_TABLE"
            ? context.sideRailContext.sideRailConfig.openRemapModal || defaultOpenRemapModal
            : defaultOpenRemapModal

    context.sideRailContext.enableSideRail({
        flow: "MANAGE_PICKLIST_ACCESS",
        type: itemType,
        ids: selectedRows,
        previousConfig: context.sideRailContext.sideRailConfig,
        itemTitle: `${title}, ${itemName}`,
        openRemapModal: openRemapModal,
        remapEnabled: selectedRows.length === 1,
    })
}

export const openDeletePicklistItemModal: tButtonClickHandler = (e, { context }) => {
    const { selectedRows, sideRailContext } = context
    const { sideRailConfig } = sideRailContext

    if (
        sideRailConfig.flow !== "DATA_TABLE" ||
        sideRailConfig.resource !== "picklistItems" ||
        selectedRows.length !== 1 ||
        !sideRailConfig.openRemapModal
    ) {
        return
    }

    sideRailConfig.openRemapModal({
        isOpen: true,
        isDelete: true,
        picklistItemId: selectedRows[0].id,
        projectIds: [],
    })
}

export const getOptInOutButtons = (params: tExtraButtonParams, field: string): tButtonClickHandlerTuple[] => {
    const optInAction = () =>
        bulkUpdateField(null, {
            args: {
                field,
                value: true,
            },
            ...params,
        })

    const optOutAction = () =>
        bulkUpdateField(null, {
            args: {
                field,
                value: false,
            },
            ...params,
        })

    return [
        ["Opt In", optInAction, "share", false, "Opt Into Emails"],
        ["Opt Out", optOutAction, "share", false, "Opt Out of Emails"],
    ]
}

export const bulkUpdateField: tButtonClickHandler = (
    e,
    {
        args,
        context,
        gridApi,
        modalTitle = "",
        modalDescription = "",
        showWarningModal = false,
        updateUsingResponseData = false,
    }
) => {
    const resourceName = context.settings.resources[0]
    const selectedRowNodes = gridApi.getSelectedNodes()
    // for account settings we don't allow bulk updates to "other field forms" outside your company
    const sourceDataToUpdate: tSourceData = {
        [resourceName]:
            getFlagEnabled("WA-7979-ff-notifications") && resourceName === "accountSettings"
                ? selectedRowNodes
                      ?.map(node => node?.data)
                      .filter(data =>
                          args?.field?.startsWith("other_field_form") ? !data?.external_company : data
                      )
                : context.selectedRows,
    }
    const typedArgs = args as tBulkActionParams
    const isSSRM = context.settings.gridSettings?.rowModelType === "serverSide"
    if (showWarningModal) {
        context.createModalAction({
            title: modalTitle,
            description: modalDescription,
            action: async () => {
                // on the Api Integration page, this fixes a glitch
                // where the unselectable rows are still highlighted once the deactivation occurs.
                // the calls to deselectAll() and clearFocusedCell() should prevent that
                if (resourceName === "apiIntegration") {
                    gridApi.deselectAll()
                    gridApi.clearFocusedCell()
                }
                await context.dispatch(
                    bulkUpdateSourceDataField(
                        sourceDataToUpdate,
                        typedArgs,
                        updateUsingResponseData,
                        selectedRowNodes,
                        isSSRM
                    )
                )
                if (resourceName === "apiIntegration") {
                    // force a refresh on the selected rows as the data passed down from
                    // cell renderer params doesn't seem to update without doing this
                    gridApi.refreshCells({ force: true, rowNodes: selectedRowNodes })
                }
            },
            buttonClass: "continueButton",
            close: () => {
                context.createModalAction(null)
            },
        })
    } else {
        // If the table isn't automatically saving, we take a slightly different route and only update the
        // table contents (i.e. no calls to the API)
        if (typedArgs.no_autosave) {
            context.dispatch(
                bulkUpdateSourceDataFieldNoAutosave(sourceDataToUpdate, typedArgs, selectedRowNodes, isSSRM)
            )
        } else {
            context.dispatch(
                bulkUpdateSourceDataField(sourceDataToUpdate, typedArgs, false, selectedRowNodes, isSSRM)
            )
        }
    }
}

const getWorkflowActionQueryParams = (context: tContext): tQueryParams => ({
    ...(context.settings.additionalQueryParams && context.settings.additionalQueryParams.history_for_status
        ? {
              history_for_status: context.settings.additionalQueryParams.history_for_status,
          }
        : {}),
    ...(context.settings.otherSettings.requestSpecificFields
        ? {
              field: context.settings.otherSettings.requestSpecificFields,
          }
        : {}),
})

/**
 * Takes in row context and returns an object with keys for availableVariants and commonVariantNames.
 * availableVariants are all names available across the selected stores (might not be available on all stores)
 * commonVariantNames are all names that available to every selected store
 */
const getVariantOptionInfo = (context: tContext): Record<string, string[]> => {
    const { selectedRows, listViewVariantNames, relatedVariantNames } = context

    const availableVariantNames = context?.settings?.isPrimary ? listViewVariantNames : relatedVariantNames

    // if we don't have variants, or we have more than 25 rows selected, no need for buttons
    // we disable bulk download if they select more than 25 rows, so no need to keep calculating.
    if (!availableVariantNames?.length) return {}

    // get all the lists of available variants from each selected row
    const variants: Array<string[]> = []
    const schemaNames: Set<string[]> = new Set()
    selectedRows.forEach(row => {
        if (row?.schema_name) {
            schemaNames.add(row.schema_name)
        }
        if (Array.isArray(row?.variants)) {
            variants.push(row.variants)
        }
    })

    // sort the variants, so the shortest list is first, so we keep the comparison as efficient as possible
    variants.sort((a, b) => a.length - b.length)

    // create a unique list of available names - we don't want to list duplicate names
    // the selector will make sure to pick the right variant for any given schema
    const uniqueAvailableNames = availableVariantNames?.reduce((uniqueNames: string[], name: string) => {
        if (!uniqueNames.includes(name)) uniqueNames.push(name)
        return uniqueNames
    }, [])

    // compare each list of variants and only return the ones shared across all lists
    const commonVariantNames = variants?.length
        ? variants?.reduce((names, newNames) => names.filter(name => newNames.includes(name)))
        : []

    const defaultOption = "Default Variant"
    return { availableVariants: [defaultOption, ...uniqueAvailableNames], commonVariantNames: commonVariantNames }
}

/**
 * Takes in row context and returns buttons for the list view download selected button
 */
export const getAvailableDownloadVariants: tButtonClickHandlerFactory = params => {
    const { context } = params
    const { selectedRows } = context

    // if we don't have variants, or we have more than 25 rows selected, no need for buttons
    // we disable bulk download if they select more than 25 rows, so no need to keep calculating.
    if (!selectedRows?.length || selectedRows?.length > 25) return []

    const variantInfo = getVariantOptionInfo(context)
    const { commonVariantNames, availableVariants } = variantInfo
    if (!availableVariants?.length) return []

    const defaultOption = "Default Variant"
    return availableVariants.map((variantName: string) => {
        const isDefault = variantName === defaultOption
        const action: tButtonClickHandler = (e, params) => {
            const downloadParams = { ...params }
            if (!isDefault) downloadParams["variantName"] = variantName
            params.context.dispatch(bulkDownloadPdfs(e, downloadParams))
        }
        const isDisabled = !commonVariantNames?.includes(variantName) && !isDefault
        return [variantName, action, "download", isDisabled]
    })
}

/**
 * Takes in row context and returns buttons for the list view bundle button
 */
export const getAvailableBundleVariants: tButtonClickHandlerFactory = params => {
    const { context } = params
    const { selectedRows } = context

    if (!selectedRows?.length) return []

    const variantInfo = getVariantOptionInfo(context)
    const { commonVariantNames, availableVariants } = variantInfo
    if (!availableVariants?.length) return []

    const defaultOption = "Default Variant"
    return (
        availableVariants?.map((variantName: string) => {
            const isDefault = variantName === defaultOption
            const action: tButtonClickHandler = (e, params) => {
                const bundleParams = { ...params }
                if (!isDefault) bundleParams["variantName"] = variantName
                params.context.dispatch(bundleForms(e, bundleParams))
            }
            const isDisabled = !commonVariantNames?.includes(variantName) && !isDefault
            return [variantName, action, "bundle", isDisabled]
        }) || []
    )
}

export const getWorkflowActionsHandlers: tButtonClickHandlerFactory = ({ context }) => {
    // An array of statuses that each of the selected rows could be transitioned to
    const statuses = getSelectedRowStatuses(context)

    const actionQueryParams = getWorkflowActionQueryParams(context)

    const sourceData = {
        [context.settings.resources[0]]: context.selectedRows,
    }

    return statuses.map(status => {
        const label = status
        const icon = "status"
        const action: tButtonClickHandler = (e, params) => {
            params.context.createModalAction({
                action: () => {
                    params.context.dispatch(
                        bulkWorkflowAction(sourceData, actionQueryParams, {
                            action: status,
                            queryParams: actionQueryParams,
                        })
                    )
                    if (getFlagEnabled("WA-7925-ff-status-update-pdf") && context?.notificationInfo?.socket) {
                        // subscribe to project channels so we properly update the download button
                        context?.notificationInfo?.socket.send(
                            JSON.stringify({
                                message: "Subscribe User to Project Channels",
                                type: "add.to.channels",
                                project_ids: context.selectedRows?.map(row => row.project),
                            })
                        )
                    }
                },
                close: () => {
                    params.context.createModalAction(null)

                    // Need to deselect rows so that the workflow actions
                    // refresh to reflect the status change
                    // we only have the gridApi if using list-view toolbar, in right rail, we don't
                    params?.gridApi?.deselectAll()
                },
                title: `Are you sure you want to "${status}" selected rows?`,
                description: "This action cannot be undone",
            })
        }

        return [label, action, icon]
    })
}

/**
 * Get the proper buttons/tooltips for field forms based on the selected rows in the table.
 * We support single edit/view (assuming they have permission) with or without a project selected
 * We support bulk view with or without a project selected as long as all schemas match
 * We support bulk editing if all selected forms have the same project, or single project is selected in the filter
 * @param params - ag-grid context with info on selected rows
 * @returns Form Review Action Buttons
 */
export const getFormReviewActionButtons: tButtonClickHandlerFactory = params => {
    const { context } = params
    const { referenceableData, selectedRows, current_project_id } = context
    // if nothing is selected, there is nothing to view/modify
    if (!selectedRows?.length) return []

    const isBulkEdit = selectedRows.length > 1
    // getFormSummaryInfo will go through all selected rows and return the necessary info to set the buttons
    const memoizedGetSummary = memoize(getFormSummaryInfo)
    const {
        modifyDisabled,
        viewDisabled,
        editTooltip,
        viewTooltip,
        selectedStatuses,
        selectedHashes,
        checkedSchemas,
        selectedProjectIds,
        selectedStoreIds,
    } = memoizedGetSummary(selectedRows, current_project_id, referenceableData)

    const listViewName = context.settings.tableName
    const { schema_name: schemaName } = selectedRows[0]
    const isBundle = context.settings.tableName === BUNDLE_TABLE_NAME

    const hasSingleProjectSelectedInFilter = Array.isArray(current_project_id) && current_project_id?.length === 1
    let projectId: number | undefined = undefined
    // get the project name from the selected project id and the referenceable data
    projectId = !Array.isArray(current_project_id)
        ? current_project_id
        : hasSingleProjectSelectedInFilter
        ? current_project_id[0]
        : undefined

    const selectedProjectId = selectedProjectIds.size === 1 ? selectedProjectIds.values().next().value : undefined
    // if they don't have a project filtered but only one project id is used across all forms
    // or it is a GC and only forms from one sub's project are selected, swap projectId to the sub's project
    // this will allow the GC to update the PCO picker for a single sub's forms. Cannot bulk edit PCO across subs.
    if (selectedProjectId !== projectId) {
        projectId = selectedProjectId
    }
    const project = projectId ? referenceableData?.projects?.[projectId] : {}
    const projectName = project?.name ?? ""

    const modes: BulkViewMode[] = ["View", "Modify"]
    return modes.map(mode => {
        const isModify = mode === "Modify"
        const label = isModify ? "Edit" : "View"
        const icon = isModify ? "edit" : "view"
        const disabled = isModify ? modifyDisabled : viewDisabled
        const tooltip = isModify ? editTooltip : viewTooltip
        // we need to pass this info along to amplitude. We also pass it to the right rail for the internal toolbar
        const amplitudeMetrics = {
            bulk_action: mode,
            form_statuses: [...selectedStatuses],
            project_name: projectName,
            schema_name: schemaName,
            schema_hash: [...selectedHashes][0],
            total_forms: selectedStoreIds.length,
        }

        const sideRailSettings = {
            schemaId: [...checkedSchemas][0], // all schemas match, so just grab the id of the first
            mode: mode,
            extraBtnParams: params,
            modifyButtonDisabled: modifyDisabled,
            amplitudeMetrics,
            listViewFields: context.settings.otherSettings.requestSpecificFields,
            listViewContext: params.context.settings,
            gridId: context.selectedRows[0]?.gridId,
        }

        const action: tButtonClickHandler = () => {
            !isBulkEdit
                ? context.sideRailContext.enableSideRail({
                      ...sideRailSettings,
                      flow: "FIELD_FORMS",
                      formId: context.selectedRows[0]?.id,
                      gridApi: params.gridApi,
                      isBundle: isBundle,
                      projectId: projectId,
                      listViewContext: context.settings,
                      setSelectedTab: context.setSelectedTab,
                      enableSideRail: context.sideRailContext.enableSideRail,
                  })
                : context.sideRailContext.enableSideRail({
                      ...sideRailSettings,
                      flow: "BULK_REVIEW",
                      selectedStoreIds: selectedStoreIds,
                      gridApi: params.gridApi,
                      listViewName,
                      // a GC won't have a sub's full project - we use sub project id for PCO picker
                      selectedProject: project ?? { id: projectId },
                  })
            // log the event to amplitude
            logUserAmplitudeEvent(FORM_BULK_ACTION, amplitudeMetrics)
        }
        return [label, action, icon, disabled, tooltip]
    })
}

export const getFormVariants: tButtonClickHandlerFactory = params => {
    const currentVariant: string = params.args?.extraArgs?.selectedVariantName
    const availableVariants: string[] = params.args?.extraArgs?.formVariants
    return availableVariants
        ? availableVariants
              .filter(v => v !== currentVariant)
              .map(v => {
                  const label: string = v
                  const action: tButtonClickHandler = () => {
                      params.context.dispatch(setSelectedVariant(v))
                  }
                  const icon = "view"
                  return [label, action, icon]
              })
        : []
}

/**
 * Launch Copy To in the side rail. If there are multiple transforms available for the selected
 * form,
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} params View params.
 */
export const copyToForm: tButtonClickHandler = (e, params) => {
    const allCopyToTransforms = params.context.settings.otherSettings.relatedCopyToTransforms
    const schemaId = params.context.selectedRows[0]?.schema
    // we only want to look at transforms available for the schema related to our selection
    const relatedCopyToTransforms = filterCopyToTransformsBySchemaId(allCopyToTransforms, schemaId)
    const selectedRowIds: number[] = params.context.selectedRows.map(row => row.id)
    const projectId = Array.isArray(params.context.current_project_id)
        ? params.context.current_project_id[0]
        : params.context.current_project_id

    if (relatedCopyToTransforms.length > 1) {
        params.context.dispatch(
            openCopyToModal({
                selectedRowIds: selectedRowIds,
                schemaId: schemaId,
                projectId: projectId,
            })
        )
    } else {
        params.context.sideRailContext.enableSideRail({
            flow: "FIELD_FORMS",
            mode: "Create",
            isNew: true,
            isTransform: true,
            selectedRowIds: selectedRowIds,
            schemaId: params.context.selectedRows[0].schema,
            relatedCopyToTransform: relatedCopyToTransforms[0],
            projectId: projectId,
            extraBtnParams: params,
            gridApi: params.gridApi,
            listViewFields: params.context.settings.otherSettings.requestSpecificFields,
            listViewContext: params.context.settings,
            gridId: params.context.selectedRows[0]?.gridId,
            setSelectedTab: params.context.setSelectedTab,
            enableSideRail: params.context.sideRailContext.enableSideRail,
        })
    }
}

/**
 * Launch bundle form
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} params View params.
 */
export const bundleForms: tButtonClickHandler = (e, params) => {
    const { context, variantName } = params
    const { current_project_id: currentProjectId, selectedRows } = context
    const { otherSettings } = context?.settings
    if (otherSettings === undefined || !Object.keys(otherSettings).length) return
    const bundleTransforms = otherSettings?.bundleTransforms
    const bundleSchemas = otherSettings?.related_schemas

    const bundleTransform = getBundleTransforms(context, bundleTransforms)
    // there should only be one item in the set - so grab that and set as the transformId
    const transformId = bundleTransform?.values().next().value?.id
    if (!transformId) {
        alert("The selected forms cannot be bundled")
        return
    }
    const projectId = Array.isArray(currentProjectId) ? currentProjectId[0] : currentProjectId

    const selectedRowIds: number[] = selectedRows.map(row => row.id)
    context.sideRailContext.enableSideRail({
        flow: "BUNDLE",
        selectedRowIds: selectedRowIds,
        transformId: transformId,
        bundleSchemaId: bundleSchemas[0].id,
        projectId: projectId,
        schemaId: selectedRows[0].schema,
        extraBtnParams: params,
        setSelectedTab: context.setSelectedTab,
        enableSideRail: context.sideRailContext.enableSideRail,
        bundleVariantName: variantName ?? undefined,
        listViewFields: context.settings.otherSettings.requestSpecificFields,
    })
}

export const bulkDownloadPdfs: tButtonClickHandler = (e, { context, variantName }) => {
    // extract the ids from the selected rows
    const selectedStoreIds = context.selectedRows.map(r => r.id)
    // make sure the websocket is activated to receive the download
    const { notificationInfo } = context
    if (!notificationInfo?.connected) {
        // if the socket got disconnected try and reconnect it
        context.dispatch(wsConnect(getSocketUrl("company_form_stores")))
    }
    // generate a filename based on the list view title so we know what to call the zip file
    const filename = `${convertToFilename(context.settings.listViewTitle)}_${format(
        new Date(),
        STORED_DATE_ONLY_FORMAT
    )}.zip`
    context.dispatch(triggerBulkPdfDownload(selectedStoreIds, filename, variantName))
}

/**
 * Launch the row-specific share modal.
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} params
 */
export const shareLinkToForm: tButtonClickHandler = async (e, { context }) => {
    const selectedForms = context.selectedRows.map(ea => ea.id)
    const currentUserFullName = `${context.currentUser.first_name} ${context.currentUser.last_name}`
    const companyName = context.currentUser.company
    const projectsList = context.selectedRows.map(ea => ea.project_name)
    const projectIDs = context.selectedRows.map(ea => ea.project)
    const uniqueProjectIDs = [...new Set(projectIDs)]
    const schemaIds = Object.keys(context.referenceableData.companyFormSchemas).map(id => parseInt(id))
    const schemaName = context.referenceableData.companyFormSchemas[schemaIds[0]].name

    let fetchError = null
    let uniquePreviousCollaborators

    try {
        uniquePreviousCollaborators = await fetchPreviousCollaborators(uniqueProjectIDs, schemaIds)
    } catch (e) {
        // error object needs a type - this will be fixed in typescript >4.x
        const error = e as any
        fetchError = error.message
    }

    let formSharePermissions = {} // "VIEW_ONLY" for all forms
    let formShareVariants = {}
    if (userRolesWithAdditionalSharePrivileges.includes(context.currentUser.user_role)) {
        // Get the available permissions for the selected forms. This can be a slow operation if there are many
        // forms with many different statuses to check, but hopefully that case is rare
        try {
            formSharePermissions = await getFormSharePermissions({ form_ids: selectedForms })
        } catch (e) {
            // error object needs a type - this will be fixed in typescript >4.x
            const error = e as any
            fetchError = error.message
        }
        if (getFlagEnabled("WA-8154-ff-variants")) {
            // get the variants available for the selected stores
            try {
                formShareVariants = await getFormShareGuestVariants({ form_ids: selectedForms })
            } catch (e) {
                // error object needs a type - this will be fixed in typescript >4.x
                const error = e as any
                fetchError = error.message
            }
        }
    }

    context.dispatch(
        openFormShareModal(
            uniquePreviousCollaborators,
            projectsList,
            selectedForms,
            currentUserFullName,
            companyName,
            schemaName,
            fetchError,
            formSharePermissions,
            formShareVariants
        )
    )
}

/**
 * Launch the row-specific Collaborators view in the Side Rail.
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} context View context.
 */
export const openSideRailCollaboratorsForSelectedRows: tButtonClickHandler = async (e, { context }) => {
    if (context.sideRailContext.sideRailEnabled) return
    const selectedRows = context.selectedRows.map(s => s.id)
    const resource = "guestFormShares"

    // This comes from modify production value getters
    context.sideRailContext.enableSideRail({
        flow: "DATA_TABLE",
        config: {
            formIds: selectedRows,
            useBasicHeader: true,
            hideApplyButton: true,
            cancelButtonText: "Done",
            headerIcon: IconAddCollaborator(),
        },
        filters: [],
        resource,
        title: `${context.settings.tableName} Collaborators`,
    })
}

/**
 * From the Collaborators view in the side rail, reshare the
 * guest share forms and trigger the invitation e-mails for the
 * selected rows
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} context View context.
 */
export const reshareGuestShareForm: tButtonClickHandler = (e, { context }) => {
    if (context.selectedRows.length < 1) return

    const form_ids: number[] = []
    const guests: any = []
    const currentUserFullName = `${context.currentUser.first_name} ${context.currentUser.last_name}`
    const companyName = context.currentUser.company

    context.selectedRows.forEach(row => {
        form_ids.push(row.form)
        guests.push({ email_address: row.guest.email, permissions: row.permissions })
    })

    // The interpreter believes that companyFormStores contains iCompanyFormStore objects, but the actual
    // objects have more fields than the type would indicate (including two fields that we want to use), so
    // this is being cast to any
    const companyFormStore: any = context.referenceableData.companyFormStores[form_ids[0]]

    const projectName = companyFormStore ? companyFormStore["project_name"] : ""

    const schema = context.referenceableData.companyFormSchemas[companyFormStore["schema"]]
    const formType = schema ? schema.name : ""

    // We will use the default generated e-mail message, with no opportunity to change it
    const email_body = getDefaultEmailMessage(currentUserFullName, companyName, formType, form_ids, projectName)

    context.dispatch(
        reshareGuestFormShares({
            form_ids: [...new Set(form_ids)],
            guests: guests,
            email_body: email_body,
        })
    )
}

/**
 * From the Collaborators view in the side rail, change permissions
 * on the selected guest form shares to the value selected in the dropdown.
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} context View context.
 */
export const updateGuestShareFormPermissions: tButtonClickHandlerFactory = params => {
    // TODO: This function can fire when the user selects rows on the main table as well as the right rail,
    // which is unnecessary - and when it does, the context.selectedRows are not guestFormShares. Can we
    // stop it before it gets rolling?
    const updateMethod = (permissions: string, context: any) => {
        const sourceDataToUpdate: tSourceData = {
            ["guestFormShares"]: context.selectedRows,
        }
        const args = {
            field: "permissions",
            value: permissions,
            resourceToId: "guestFormShares",
        } as tBulkActionParams
        params.context.dispatch(bulkUpdateSourceDataField(sourceDataToUpdate, args))
    }

    const updateToViewOnly: tButtonClickHandler = (e, { context }) => updateMethod(VIEW_ONLY, context)
    const updateToRequestSignature: tButtonClickHandler = (e, { context }) =>
        updateMethod(REQUEST_SIGNATURE, context)

    let isRequestSignatureEnabled = false

    if (params.sideRailContext?.sideRailConfig.flow === "DATA_TABLE") {
        if (params.context.selectedRows) {
            isRequestSignatureEnabled = true
            params.context.selectedRows.forEach(row => {
                // The available_permissions field is populated if the check_available_permissions
                // flag was passed to the query
                const perms = row.available_permissions
                if (!perms || !perms.find((p: any) => p === REQUEST_SIGNATURE)) {
                    isRequestSignatureEnabled = false
                }
            })
        }
    }

    // The View Only option is always available to any form that can trigger the Collaborators right rail
    const options: tButtonClickHandlerTuple[] = [["View Only", updateToViewOnly, "settings"]]

    // The Request Signature option is only available if this form has all share permissions
    if (isRequestSignatureEnabled) {
        options.push(["Request Signature", updateToRequestSignature, "settings"])
    }

    return options
}

/**
 * From the Collaborators view in the side rail, delete a guest form share, denying the
 * guest access to the specified form
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} context View context.
 */
export const deleteGuestShareForm: tButtonClickHandler = (e, { context }) => {
    const sourceDataToDelete: tSourceData = {
        [context.settings.resources[0]]: context.selectedRows,
    }

    context.dispatch(
        deleteSourceData(
            sourceDataToDelete,
            undefined,
            context.settings.gridSettings?.rowModelType === "serverSide"
        )
    )
}

export const openHistory: tButtonClickHandler = (e, { context }) => {
    const resource = context.settings.resources[0]
    const id = context.selectedRows[0].id
    const requestSpecificFields = context.settings.otherSettings.requestSpecificFields
    const queryParams = requestSpecificFields ? { field: requestSpecificFields } : {}

    context.dispatch(getAuditHistoryById(resource, id, queryParams))
}

/**
 * Archive function used in Right Rail to soft-delete selected rows
 * Save is executed when clicking 'OK' in confirmation modal; does not auto-save
 * Permissions for archiving are aligned with current deletion permissions
 * Archiving is achieved by setting the `deleted_on` datestamp, but record is retained and
 * can be accessed by clicking the 'show deleted/archived items' checkbox in the filter
 */
export const archiveSelectedRows: tButtonClickHandler = (e, { context }) => {
    const selectedRowsWithWritePermission = context.selectedRows.filter(
        row => !row.status || roleHasWritePermission(row.status, context.currentUser.user_role)
    )

    const sourceDataToDelete: tSourceData = {
        [context.settings.resources[0]]: selectedRowsWithWritePermission,
    }

    context.createModalAction({
        title: "Archive selected items?",
        description: "These items can be accessed by clicking the 'Show Archived Items' checkbox in the filter.",
        action: () => {
            context.dispatch(
                deleteSourceData(
                    sourceDataToDelete,
                    undefined,
                    context.settings.gridSettings?.rowModelType === "serverSide"
                )
            )
        },
        close: () => context.createModalAction(null),
    })
}

/**
 * Button click handler for the Add Row button that is available when one or more group rows is selected.
 * While the table-level Add Row button simply adds a blank row to the table, this button handler
 * adds a row under each selected group by populating it with the value associated with that group
 *
 * @param e         Not used
 * @param gridApi   The AG Grid gridApi, used to look up the selected group rows
 * @param columnApi The AG Grid columnApi, used to look up the colDefs for this table
 * @param context   The overall context for this operation, used to provide more info on the table
 */
export const addNewRowToGroup: tButtonClickHandler = (e, { gridApi, columnApi, context }) => {
    const groupRows = gridApi.getSelectedNodes().filter((node: IRowNode) => node.group)

    const colDefs = (columnApi.getColumns() || [])?.map(column => column.getColDef())

    if (groupRows?.length > 0) {
        const rowsToAdd: tResourceObject[] = []
        groupRows.forEach((groupRow: IRowNode) => {
            const row: tResourceObject = {}

            // The key comes in as a string, but check for a number just in case
            const id = groupRow.key ? (typeof groupRow.key === "string" ? parseInt(groupRow.key) : null) : null
            const field = groupRow.field ? groupRow.field.replace("/", "") : null

            if (id && field) {
                row[field] = typeof id === "string" ? parseInt(id) : id
            }

            rowsToAdd.push(row)

            // Make sure that the field we're populating will not get overwritten with a
            // default in prepNewRow. Setting its default property to undefined will tell prepNewRow
            // not to stick a default into the field
            const colForRelevantField = colDefs.find((col: any) => col.field === groupRow.field)
            if (colForRelevantField) colForRelevantField.cellEditorParams.default = undefined

            // Make sure this group row is expanded, so that the user can see the added rows
            groupRow.expanded = true
        })

        if (context.updateSourceDataCb) {
            bulkAddSourceData(
                context.settings.resources[0],
                colDefs,
                rowsToAdd,
                context.settings.otherSettings.rowLevelValidators,
                context.settings.otherSettings.hiddenColumnDefaults,
                context.groupKeyInfo,
                context.filters,
                context.updateSourceDataCb,
                context
            )
        }
    }
}

/**
 * While editing a field form, the user can import data from a time card or another form
 * if an applicable transform is available. This button handler kicks off the process
 */
export const importFromFormOrTimeCard: tButtonClickHandler = (e, { context }) => {
    let schemaId, projectId, companyFormStoreId
    const transformsForImport = context.settings.otherSettings.transformsForImport

    // If there are no rows selected in the list view, then this action must come from a
    // Create flow
    if (!context.selectedRows || context.selectedRows.length === 0) {
        if (
            !transformsForImport?.length ||
            (Array.isArray(context.filters.projectId) && !context.filters.projectId?.length) ||
            (!Array.isArray(context.filters.projectId) && !context.filters.projectId)
        )
            return
        schemaId = transformsForImport[0].to_schema

        // The filters must have a project selected in order for the Create flow to be
        // available - use that to grab the projectId
        projectId = Array.isArray(context.filters.projectId)
            ? context.filters.projectId[0]
            : context.filters.projectId
    } else {
        // Otherwise, get the data the modal needs from that selected row
        const selectedCompanyFormStore = context.selectedRows[0]

        schemaId = selectedCompanyFormStore.schema
        projectId = selectedCompanyFormStore.project
        companyFormStoreId = selectedCompanyFormStore.id
    }

    context.dispatch(
        openImportToFieldFormModal({
            schemaId: schemaId,
            projectId: projectId,
            companyFormStoreId: companyFormStoreId,
            transformsForImport: transformsForImport,
        })
    )
}

export const duplicateCohort: tButtonClickHandler = (e, { context, columnApi, gridApi }) => {
    const colDefs = (columnApi.getColumns() || [])?.map(column => column.getColDef())
    let newRowName = `${context.selectedRows[0].name} copy`
    const existingNodeNames = new Set()
    gridApi.forEachNode(n => {
        existingNodeNames.add(n.data.name)
    })
    let copyCounter = 2
    while (existingNodeNames.has(newRowName)) {
        newRowName = `${context.selectedRows[0].name} copy ${copyCounter}`
        copyCounter += 1
    }

    // This is a little weird -- we need to indicate that it's a new row in order for write.ts
    // to trigger an API POST call instead of an update. However, if there's a gridId in the object,
    // then it will think that the row already existed in the table and trigger a source data update.
    // I added the `forceAdd` parameter here (and only here) to tell it to do a source data add.
    // This also insulates us from a random new bug in some other table elsewhere.
    const newRowData = {
        ...context.selectedRows[0],
        name: newRowName,
        newRow: true,
        forceAdd: true,
    }
    if (newRowData.id) delete newRowData.id
    if (newRowData.gridId) delete newRowData.gridId

    context.dispatch(
        updateSourceDataRow(
            context.settings.resources[0],
            newRowData,
            colDefs,
            context.settings.otherSettings.rowLevelValidators,
            false,
            context,
            null,
            false
        )
    )
}

export const deleteStatus: tButtonClickHandler = (e, { context, sourceData }) => {
    context.createModalAction({
        isStatusRemapModal: true,
        sourceStatus: context.selectedRows[0],
        allStatuses: sourceData.timekeepingStatuses as iTimekeepingStatus[],
        close: () => context.createModalAction(null),
    })
}

export const unhideStatus: tButtonClickHandler = (e, { context, gridApi }) => {
    updateRow("timekeepingStatuses", { ...context.selectedRows[0], is_hidden: false })
        .then(response => {
            context.dispatch(
                sourceDataUpdated({
                    timekeepingStatuses: [{ ...response, gridId: context.selectedRows[0].gridId }],
                })
            )
            gridApi.refreshCells({ force: true })
        })
        .catch(error => {
            alert(error)
        })
}
