import cloneDeep from "clone-deep"
/** Utils */
import { getTimekeepingStatusChangeHandlers } from "./utils"
import {
    getIsChildOfTimecardWithoutData,
    isCellEditableByStatus,
    isPivotRowTotalColumn,
} from "../../common/ag-grid-utils"
import { cellValuesAreCompatible, getDescriptionOfSourceData, isPivotColumn } from "../../common/ag-grid-ts-utils"
import {
    filterData,
    getFiltersFromGrouping,
    getGroupKeyInfo,
    getGroupKeyInfoFromApis,
    getSourceDataForGroupKeyInfo,
    getSourceDataForPivotPaste,
} from "../../common/ag-grid-grouping-utils"
/** Actions */
import { copyPivotCell, copyCell, deletePivotCell, pastePivotCell } from "../actions"
import { customTooltipUpdate } from "../../actions/custom-tooltip-actions"
import { getAuditHistory, updateAuditFlag } from "../../actions/audit-history"
/** Types */
import { tButtonClickHandler, tButtonClickHandlerFactory, tExtraButtonParams } from "../types"
import { iRmbxColDef } from "../../components/custom-dashboards/settings-files/types"
import { Column, IRowNode, ValueParserFunc, ValueParserParams } from "ag-grid-community"
import { MutableSourceData, tResourceObject, tSourceData } from "../../dashboard-data/types"
import { getFlagEnabled } from "../../getFlagValue"
import { SignaturePeriod, tResourceName } from "../../common/types"
import {
    bulkRequestTimekeepingSignatures,
    getTextToSignEmployeeIds,
    getUniqueEmployeeIds,
    requestTimekeepingSignatures,
    singleEmployeeIsSelected,
} from "../../actions/timekeeping-signatures"
import { capitalizeFirstLetters, getAsDate } from "../../common/ts-utils"
import { format } from "date-fns"
import { STORED_DATE_ONLY_FORMAT } from "../../common/constants"
import { tContext } from "../../components/custom-dashboards/types"
import { tButtonClickHandlerTuple } from "../types"
import { setNotificationMessage } from "../../websockets/actions"
import { openAddEditWorkShiftModal } from "../../components/modals/actions"
import { JsonPointer as jsonpointer } from "json-ptr"
import { validateRow } from "../../dashboard-data/actions/write"

type MyNewValueParserParams = ValueParserParams & {
    newValue: Record<string, any>
}

export const copyFocusedCell: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const column = cell.column
    const colDef = cell.column.getColDef()

    if (!colDef || !colDef.valueGetter) {
        return
    }

    const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)

    // TypeScript doesn't think this is callable for some reason, but it is
    const valueGetter = colDef.valueGetter as (params: any) => any
    const sourceType = (colDef as Record<string, any>).columnType ?? (colDef as Record<string, any>).resourceName
    const value = valueGetter({
        api: gridApi,
        colDef,
        column,
        columnApi,
        context,
        data: row?.data,
        node: row,
    })
    const copiedData =
        getFlagEnabled("WA-8288-tk-improvements") && context.settings.otherSettings.pasteRangeEnabled
            ? { copiedValue: value, sourceType }
            : value

    // Copy to the system clipboard if available, as well as to our redux
    // "clipboard". The reason we need redux is that JavaScript cannot read
    // from clipboard data programmatically (for security reasons), so we cannot
    // implement a paste button that uses the system clipboard. Also, Safari
    // does not implement the clipboard API.
    //
    // Ref: https://w3c.github.io/clipboard-apis/#privacy-events
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(copiedData).then(
            () => {
                context.dispatch(copyCell(copiedData))
            },
            () => {
                context.dispatch(copyCell(copiedData))
            }
        )
    } else {
        context.dispatch(copyCell(copiedData))
    }

    if (row)
        gridApi.flashCells({
            rowNodes: [row],
            columns: [cell.column],
        })

    // Take focus away from Copy button and back to cell to allow keyboard
    // navigation
    gridApi.setFocusedCell(cell.rowIndex, cell.column)
}

export const copySelectedCells: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    const selectedRanges = gridApi.getCellRanges()

    if (!selectedRanges || !selectedRanges.length) {
        return
    }

    const clipboardData: any = []
    const rowsToFlash: IRowNode[] = []
    let colsToFlash: Column[] = []
    selectedRanges.forEach(cellRange => {
        if (!cellRange.startRow || !cellRange.endRow) return
        colsToFlash = cellRange.columns
        for (
            let rowIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
            rowIndex <= Math.max(cellRange.endRow.rowIndex, cellRange.startRow.rowIndex);
            rowIndex++
        ) {
            const row = gridApi.getDisplayedRowAtIndex(rowIndex)
            if (!row) continue
            rowsToFlash.push(row)
            const rowData: Record<string, any>[] = []
            cellRange.columns.forEach(column => {
                const colDef = column.getColDef()
                const valueGetter = colDef.valueGetter as (params: any) => any
                const sourceType =
                    (colDef as Record<string, any>).columnType ?? (colDef as Record<string, any>).resourceName
                const value = valueGetter({
                    api: gridApi,
                    colDef,
                    column,
                    columnApi,
                    context,
                    data: row?.data,
                    node: row,
                })
                rowData.push({ copiedValue: value, sourceType })
            })
            clipboardData.push(rowData)
        }
    })

    // Copy to the system clipboard if available, as well as to our redux
    // "clipboard". The reason we need redux is that JavaScript cannot read
    // from clipboard data programmatically (for security reasons), so we cannot
    // implement a paste button that uses the system clipboard. Also, Safari
    // does not implement the clipboard API.
    //
    // Ref: https://w3c.github.io/clipboard-apis/#privacy-events
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(clipboardData).then(
            () => {
                context.dispatch(copyCell(clipboardData))
            },
            () => {
                context.dispatch(copyCell(clipboardData))
            }
        )
    } else {
        context.dispatch(copyCell(clipboardData))
    }

    if (rowsToFlash.length && colsToFlash.length) {
        gridApi.flashCells({
            rowNodes: rowsToFlash,
            columns: colsToFlash,
        })
        if (rowsToFlash.length && rowsToFlash[0].rowIndex)
            gridApi.setFocusedCell(rowsToFlash[0].rowIndex, colsToFlash[0])
    }
}

// variant of the copyFocusedCell function except it only copies cell content onto the clipboard without
// sending to redux
export const copyFocusedCellToClipboardOnly: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const column = cell.column
    const colDef = cell.column.getColDef()

    if (!colDef || !colDef.valueGetter) {
        return
    }

    const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)

    let copiedData
    // special case: for the API token integration page, instead of using the
    // return value of valueGetter, just get the API token value directly from the
    // row data
    if (colDef.colId === "/company_integration_name") {
        copiedData = row?.data.company_integration_token_value
    } else {
        const valueGetter = colDef.valueGetter as (params: any) => any
        copiedData = valueGetter({
            api: gridApi,
            colDef,
            column,
            columnApi,
            context,
            data: row?.data,
            node: row,
        })
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(copiedData)
    }

    if (row)
        gridApi.flashCells({
            rowNodes: [row],
            columns: [cell.column],
        })

    // Take focus away from Copy button and back to cell to allow keyboard
    // navigation
    gridApi.setFocusedCell(cell.rowIndex, cell.column)
}

export const copyFocusedPivotCell: tButtonClickHandler = (e, { columnApi, context, gridApi, sourceData }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const colDef = cell.column.getColDef()

    if (isPivotColumn(colDef)) {
        const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
        const groupKeyInfo = getGroupKeyInfoFromApis(gridApi, columnApi, context.referenceableData)
        const data = getSourceDataForGroupKeyInfo(sourceData, groupKeyInfo, context)
        const filteredData = filterData(data, context)
        const copiedData = cloneDeep(filteredData)

        context.dispatch(copyPivotCell(copiedData))

        if (row)
            gridApi.flashCells({
                rowNodes: [row],
                columns: [cell.column],
            })
    }
}

export const createNewTimecardCell: tButtonClickHandler = (e, { context, node }) =>
    context.dispatch(openAddEditWorkShiftModal(undefined, context, node))

export const deleteFocusedPivotCell: tButtonClickHandler = (e, { columnApi, context, gridApi, sourceData }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    if (isCellEditableByStatus(gridApi)) {
        context.dispatch(
            customTooltipUpdate({
                tooltipPayload: null,
                tooltipType: null,
                xPosition: 0,
                yPosition: 0,
                customClasses: "",
            })
        )

        const groupKeyInfo = getGroupKeyInfoFromApis(gridApi, columnApi, context.referenceableData)

        const rawSourceDataToDelete = getSourceDataForGroupKeyInfo(sourceData, groupKeyInfo, context)
        const sourceDataToDelete: Record<string, tResourceObject[]> = filterData(rawSourceDataToDelete, context)

        let description = ""
        if (getFlagEnabled("WA-7151-fancy-filter-before-action-buttons")) {
            description += getDescriptionOfSourceData(sourceDataToDelete).description
            description += description ? "<br>This action cannot be undone" : "This action cannot be undone"
        } else {
            description = "This action cannot be undone"
        }

        context.createModalAction({
            title: "Delete the following contents of this cell?",
            description: description,
            action: () => {
                // For the delete case, we want to send the filtered data along to be deleted
                context.dispatch(deletePivotCell(context, groupKeyInfo, sourceDataToDelete))
            },
            buttonClass: "continueButton",
            backgroundCloseEnabled: true,
            close: () => context.createModalAction(null),
        })
    }
}

export const editFocusedCell: tButtonClickHandler = (e, { gridApi }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    gridApi.startEditingCell({ rowIndex: cell.rowIndex, colKey: cell.column })
}

export const editFocusedPivotCell: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    context.dispatch(updateAuditFlag(false))

    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const colDef = cell.column.getColDef()

    if (isPivotColumn(colDef) && isCellEditableByStatus(gridApi)) {
        const groupKeyInfo = getGroupKeyInfo(
            gridApi.getDisplayedRowAtIndex(cell.rowIndex),
            cell.column,
            columnApi.getPivotColumns(),
            context.referenceableData
        )
        context.setSourceTableLockedColumns(groupKeyInfo)
    }
}

export const getCellTimekeepingStatusChangeHandlers: tButtonClickHandlerFactory = params => {
    return getTimekeepingStatusChangeHandlers(params, true)
}

export const openHistoryModalForFocusedPivotCell: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    context.dispatch(updateAuditFlag(true))

    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const colDef = cell.column.getColDef()

    if (isPivotColumn(colDef)) {
        const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
        const column = cell.column
        const pivotColumns = columnApi.getPivotColumns()
        const groupKeyInfo = getGroupKeyInfo(row, column, pivotColumns, context.referenceableData)

        context.setSourceTableLockedColumns(groupKeyInfo)

        const filtersFromGrouping = getFiltersFromGrouping({ colDef, column, columnApi, context, node: row })
        const filters = { ...context.filters, ...filtersFromGrouping }

        context.dispatch(getAuditHistory(context.settings.resources, filters))
    }
}

export const pasteIntoFocusedCell: tButtonClickHandler = (e, params) => {
    const { gridApi, clipboard } = params
    const cell = gridApi.getFocusedCell()
    if (!cell || !clipboard) {
        return
    }

    const column = cell.column
    const colDef = column.getColDef()

    if (!colDef || !colDef.editable) {
        return
    }

    const value =
        colDef.valueParser && getFlagEnabled("WA-7491-paste-fix")
            ? (colDef.valueParser as ValueParserFunc)({
                  newValue: clipboard,
                  oldValue: null,
                  api: gridApi,
                  node: params.node as IRowNode,
                  data: null,
                  column,
                  columnApi: params.columnApi,
                  colDef,
                  context: params.context,
              } as MyNewValueParserParams)
            : clipboard

    const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
    if (row) row.setDataValue(column, value)
}

/**
 * Paste the clipboard data into the selected cell range. Ensure that the cells we're pasting into are
 * for the same field type as the source field in the clipboard.
 * @param e Unused event object
 * @param clipboard Context of the clipboard
 * @param gridApi The Grid API
 * @param context The context of the table
 * @param columnApi The column API
 * @param sourceData The rest of the source data for the table
 */
export const pasteIntoCellRange: tButtonClickHandler = (
    e,
    { clipboard, gridApi, context, columnApi, sourceData }
) => {
    const cellRanges = gridApi.getCellRanges()
    if (!cellRanges) return
    const dataToUpdate: Record<string, any>[] = []
    const allColDefs = columnApi.getColumns()!.map(column => column.getColDef())
    cellRanges.forEach(cellRange => {
        if (!cellRange.startRow || !cellRange.endRow) return
        let index = 0
        for (
            let rowIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
            rowIndex <= Math.max(cellRange.endRow.rowIndex, cellRange.startRow.rowIndex);
            rowIndex++
        ) {
            const rowData = index >= clipboard.length ? clipboard[clipboard.length - 1] : clipboard[index]
            const row = gridApi.getDisplayedRowAtIndex(rowIndex)
            const rowSourceData = row!.data
            cellRange.columns.forEach((column, colIndex) => {
                // This part's a little tricky -- first we try to go by column index to find a match.
                // Hopefully this works pretty well by itself. If it doesn't, we try to find a column
                // that's of the correct type. The reason for this is that the column ordering might
                // not be the same (like timekeeping entries vs absences) and we'd like the paste to be
                // as helpful as possible.
                if (!row || !column.isCellEditable(row)) {
                    return
                }
                const colDef = column.getColDef() as Record<string, any>
                const columnType = colDef.columnType ?? colDef.resourceName
                let colData = colIndex >= rowData.length ? rowData[rowData.length - 1] : rowData[colIndex]
                if (!cellValuesAreCompatible(columnType, colData, colDef, colData.copiedValue)) {
                    colData = rowData.find((data: Record<string, any>) =>
                        cellValuesAreCompatible(columnType, data, colDef, data.copiedValue)
                    )
                }
                if (!colData) return
                const value = colDef.valueParser
                    ? (colDef.valueParser as ValueParserFunc)({
                          newValue: colData.copiedValue,
                      } as ValueParserParams)
                    : colData.copiedValue
                // If we don't have an update callback, do things the old fashioned way, which is a bit slower
                if (context.updateSourceDataCb) {
                    jsonpointer.set(rowSourceData, colDef.field, value, true)
                } else row.setDataValue(column, value)
            })
            dataToUpdate.push(
                validateRow(
                    { ...rowSourceData, errors: {}, alerts: {} },
                    allColDefs,
                    context.settings.otherSettings.rowLevelValidators,
                    context,
                    sourceData[rowSourceData.dataType as tResourceName] as tResourceObject[]
                )
            )
            index += 1
        }
    })
    // If we have an update callback, update the source data in an optimized fashion
    if (context.updateSourceDataCb && dataToUpdate.length)
        context.updateSourceDataCb({ [dataToUpdate[0].dataType]: dataToUpdate })
}

export const pasteIntoFocusedPivotCell: tButtonClickHandler = (
    e,
    { columnApi, context, gridApi, pivotClipboard }
) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const colDef = cell.column.getColDef()
    const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
    const groupKeyInfo = getGroupKeyInfoFromApis(gridApi, columnApi, context.referenceableData)

    const isChildOfTimecardWithoutData =
        getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock") &&
        getIsChildOfTimecardWithoutData(context.selectedCellData, row, groupKeyInfo)

    if (
        !isChildOfTimecardWithoutData &&
        isPivotColumn(colDef) &&
        !["trade", "status"].includes(row?.field || "") &&
        row?.key !== "" &&
        isCellEditableByStatus(gridApi) &&
        !isPivotRowTotalColumn(colDef)
    ) {
        const colDefs = (columnApi.getColumns() as Column[])?.map(column => column.getColDef())
        const sourceDataToCreate = getSourceDataForPivotPaste(pivotClipboard, groupKeyInfo, colDefs)
        context.dispatch(pastePivotCell(context, groupKeyInfo, sourceDataToCreate))
    }
}

/**
 * Launch the Side Rail.
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @prop {Object} context View context.
 * @prop {Object} gridApi AG Grid API.
 */
export const openSideRailTableForFocusedCell: tButtonClickHandler = (e, { context, gridApi }) => {
    if (context.sideRailContext.sideRailEnabled) return
    const cell = gridApi.getFocusedCell()
    const colDef = cell?.column.getColDef() as iRmbxColDef
    // Make sure a cell is selected and has openSideRail prop = true.
    if (!cell || !colDef.openSideRail) return
    const row = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
    if (row?.group) return // Check if it is a row group.

    const cellValue = row ? gridApi.getValue(colDef.field as string, row) : undefined
    const { config, filters, primarySubtitle, resource, secondarySubtitle, title } = cellValue
    context.sideRailContext.enableSideRail({
        flow: "DATA_TABLE",
        config,
        filters,
        resource,
        title,
        primarySubtitle,
        secondarySubtitle,
        gridApi,
        rowData: row?.data,
    })
}

export const duplicateWeeklyViewCell: tButtonClickHandler = (e, { columnApi, context, gridApi, sourceData }) => {
    const cell = gridApi.getFocusedCell()

    if (!cell) {
        return
    }

    const colDef = cell.column.getColDef()

    if (isPivotColumn(colDef)) {
        const groupKeyInfo = getGroupKeyInfoFromApis(gridApi, columnApi, context.referenceableData)
        const data = getSourceDataForGroupKeyInfo(sourceData, groupKeyInfo, context)
        const filteredData = filterData(data, context) as tSourceData
        const destinationData: MutableSourceData = {}
        let sourceDate = ""
        Object.entries(filteredData).map(([resource, records]) => {
            const resourceKey = resource as tResourceName
            const filteredResourceData = records?.map((r: Record<string, any>) => {
                delete r.id
                if (!getFlagEnabled("WA-7996-timecard-projects")) delete r.work_shift_id
                return { ...r, errors: {}, alerts: {} }
            }) as tResourceObject[]
            if (filteredResourceData?.length) {
                destinationData[resourceKey] = filteredResourceData
                sourceDate = filteredResourceData[0].shift_start_time
            }
        })

        if (Object.keys(destinationData).length) context.openDuplicateModal(new Date(sourceDate), destinationData)
    }
}

/**
 * Get the available signature request actions for a selected cell.
 * calls `getSignatureRequestOptions` based on Signing in app requests
 * @param params - ag-grid context with info on selected cells
 * @returns Signature buttons if they meet the proper criteria
 */
export const getSignatureOptions: tButtonClickHandlerFactory = params => {
    return getSignatureButtons(params, "Sign")
}

/**
 * Get the available signature request actions for a selected cell.
 * calls `getSignatureRequestOptions` based on Text requests
 * @param params - ag-grid context with info on selected cells
 * @returns Signature buttons if they meet the proper criteria
 */
export const getSignatureRequestOptions: tButtonClickHandlerFactory = params => {
    return getSignatureButtons(params, "Text")
}

/**
 * Goes through the timekeeping entries to determine what signature periods exist.
 * If the cell has no timekeeping entries or nothing selected requires signatures, we show nothing.
 * If they require signatures and only one signature period exists in the selection, they get a single button.
 * If they have a mix of signature periods they get a drop down of both options.
 * @param params - ag-grid params with information about selection
 * @returns list of buttons to display in the toolbar
 */
export const getSignatureButtons = (
    params: tExtraButtonParams,
    buttonType: "Sign" | "Text"
): tButtonClickHandlerTuple[] => {
    if (!getFlagEnabled("WA-5644-tk-signatures")) return []

    const { context } = params
    const { selectedCellData, referenceableData } = context
    const { timekeepingEntries } = selectedCellData ?? {}
    const { employees } = referenceableData ?? {}
    // if there is nothing to sign - no buttons to show
    if (!timekeepingEntries) return []

    let sigsAreRequired = false
    const signaturePeriods: SignaturePeriod[] = []
    timekeepingEntries.forEach(entry => {
        // we only include signature periods for entries that require signatures
        if (entry.require_signature === true) {
            sigsAreRequired = true
            if (!signaturePeriods.includes(entry.signature_period)) {
                signaturePeriods.push(entry.signature_period)
            }
        }
    })
    // if signatures are not required by any of the selected tk entries - no buttons to show
    if (!sigsAreRequired) return []

    // disable if signing in-app with more than one employee, or texting if no selected employees have texting on
    const signingInApp = buttonType === "Sign"
    const isDisabled =
        (!singleEmployeeIsSelected(context) && signingInApp) ||
        (!signingInApp && getTextToSignEmployeeIds(timekeepingEntries, employees).length === 0)

    const signatureButtons: tButtonClickHandlerTuple[] = []
    signaturePeriods.forEach(signaturePeriod => {
        const formattedPeriod = capitalizeFirstLetters(signaturePeriod)

        // if they don't have daily/weekly selected - don't show any buttons
        if (signaturePeriod === "UNKNOWN" || !signaturePeriod) {
            return
        }
        const label = signingInApp ? `${formattedPeriod} Signature` : `Request ${formattedPeriod} Signature`
        const icon = signingInApp ? "signature" : "share"
        const action: tButtonClickHandler = () => signWeeklyViewCell(context, signaturePeriod, signingInApp)
        signatureButtons.push([label, action, icon, isDisabled])
    })
    return signatureButtons.sort()
}

/**
 * Triggers the request for signature(s)
 * If the user is requesting text signatures, we allow multiple employees to be selected
 * If the user has returnUrl set to true, meaning signing in-app, we only allow a single employee
 * @param context ag-grid context with info on selected cells
 * @param signaturePeriod if signature request is daily or weekly
 * @param returnUrl if signing in-app, this will be true. If false, text is sent
 */
export const signWeeklyViewCell = (
    context: tContext,
    signaturePeriod: SignaturePeriod,
    returnUrl: boolean
): void => {
    const { selectedCellData, referenceableData } = context
    const { timekeepingEntries } = selectedCellData ?? {}
    const { employees } = referenceableData ?? {}
    // if they are signing in-app we require that they have a single employee selected
    const isSingleEmployee = singleEmployeeIsSelected(context)
    if (!isSingleEmployee && returnUrl) return

    // make a list to collect any errors for employees not set up for texting
    const preFlightErrors: string[] = []
    const textableEmployeeIds = !returnUrl
        ? getTextToSignEmployeeIds(timekeepingEntries, employees, preFlightErrors)
        : []

    if (!returnUrl && !textableEmployeeIds.length) {
        const employeeOrEmployees = isSingleEmployee ? "employee does" : "employees do"
        context.dispatch(
            setNotificationMessage(`The selected ${employeeOrEmployees} not have text-to-sign configured`, "error")
        )
    }
    const startDate =
        signaturePeriod === "WEEKLY"
            ? format(getAsDate(context.filters.startDate), STORED_DATE_ONLY_FORMAT)
            : timekeepingEntries[0]?.date
    const endDate =
        signaturePeriod === "WEEKLY"
            ? format(getAsDate(context.filters.endDate), STORED_DATE_ONLY_FORMAT)
            : startDate

    // if we have a single employee, just pull id from first entry, otherwise get all unique ids for bulk texting
    const selectedEmployeeIds = isSingleEmployee
        ? [timekeepingEntries[0].employee]
        : getUniqueEmployeeIds(timekeepingEntries)
    // see if we need to include a re-sign query param
    const entriesWithSignature = timekeepingEntries.filter(entry => !!entry?.signature)

    // we can only do bulk requests if they are sending text requests
    // only send the ids of employees who are eligible for texting
    if (selectedEmployeeIds.length > 1 && textableEmployeeIds?.length && !returnUrl) {
        context.dispatch(
            bulkRequestTimekeepingSignatures(
                {
                    start_date: startDate,
                    end_date: endDate,
                    has_signature: !!entriesWithSignature.length,
                },
                textableEmployeeIds,
                preFlightErrors
            )
        )
        return
    }
    // if it's a single employee - send a non-bulk request
    context.dispatch(
        requestTimekeepingSignatures({
            start_date: startDate,
            end_date: endDate,
            eid: selectedEmployeeIds[0],
            return_url: returnUrl,
            is_mobile: !returnUrl,
            has_signature: !!entriesWithSignature.length,
        })
    )
}
